import Sector from '@models/Sector';
import dayjs from 'dayjs';
import { sessionBloc } from '@bloc/SessionBloc';
import Customer from '@models/Customer';
import Contact from '@models/Contact';
import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";

export default class CustomEmail extends Model {

  public title: string;
  public object: string;
  public content: string;
  public default: boolean;
  public type: string;

  public contact: Contact;
  public customer: Customer
  public sector: Sector
  public dateRdv: Date;

  static modelUrl = "custom_emails";
  public modelName = "custom_email";

  public attributes = new AttributesCollection({
    title: new Attribute("title"),
    content: new Attribute("content"),
    type: new Attribute("type"),
    object: new Attribute("object"),
    default: new Attribute("default"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  withContact(contact) {
    this.contact = contact;
    return this;
  }

  withSector(sector) {
    this.sector = sector;
    return this;
  }

  withCustomer(customer) {
    this.customer = customer;
    return this;
  }

  withDateRdv(dateRdv) {
    this.dateRdv = dateRdv;
    return this;
  }

  getRealObjectWith() {
    return this.convert(this.object)
  }

  getRealContentWith() {
    return this.convert(this.content)
  }

  needRdvDate() {
    return this.content?.includes("{slot}");
  }

  needContact() {
    [ "firstname", "reference", "lastname", "fullname", "civ", "email", "portable", "full_address", "city", "diameter", "accessibility", "my_fullname", "my_phone"].forEach((word) => {
      if (this.content?.includes("{"+word+"}")) return true;
    })
    return false;
  }

  convert(text) {
    if (!text) return "";
    return text
    .replaceAll("{firstname}", this.contact.firstname || "")
    .replaceAll("{reference}", this.contact.reference || "")
    .replaceAll("{lastname}", this.contact.lastname || "")
    .replaceAll("{fullname}", this.contact.getFullName() || "")
    .replaceAll("{civ}", this.contact.civ || "")
    .replaceAll("{email}", this.contact.email || "")
    .replaceAll("{portable}", this.contact.portable || "")
    .replaceAll("{full_address}", this.contact.counter?.connectionAddress?.fullAddress() || "")
    .replaceAll("{city}", this.contact.counter?.connectionAddress?.city || "")
    .replaceAll("{diameter}", this.contact.counter?.diameter || "")
    .replaceAll("{accessibility}", this.contact.counter?.accessibility || "")
    .replaceAll("{my_fullname}", sessionBloc.user?.firstname)
    .replaceAll("{my_phone}", sessionBloc.user?.phone || "")
    .replaceAll("{customer}", this.customer?.name || "")
    .replaceAll("{slot}", dayjs(this.dateRdv).format("D MMMM YYYY à HH:mm") || "")
  }
}