import * as React from 'react';
import FullCalendar, { CustomContentGenerator, DayHeaderContentArg } from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import InterventionSlot from '@models/InterventionSlot';
import Contact from '@models/Contact';
import LightTabs from '@components/common/LightTabs';
import dayjs from 'dayjs';
import EntityManager from "@services/EntityManager";

export interface IContactRdvNextProps {
  contact: Contact
}

interface IContactRdvNextState {
  showAllRdvQuartier: boolean;
}

export default class ContactRdvNext extends React.Component<IContactRdvNextProps, IContactRdvNextState> {
  private calendarRef: React.RefObject<FullCalendar>;

  constructor(props: IContactRdvNextProps) {
    super(props);
    this.state = {
      showAllRdvQuartier: false,
    };
    this.calendarRef = React.createRef();
    this.toggleShowAllRdvQuartier = this.toggleShowAllRdvQuartier.bind(this)
  }



  toggleShowAllRdvQuartier = async () => {
    if (this.calendarRef.current) {
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.removeAllEvents();
      let contactId = this.props.contact.id
      let response = await EntityManager.get(Contact, {path: "allRdvQuartier", params: {contactId: contactId}})


      response.data.rdv.forEach((slot) => {
        calendarApi.addEvent({
          title: "", // Ou une autre logique pour définir le titre
          start: slot.startAt,
          end: slot.endAt,
          color: "#4d9cc1",
        });
      });
      this.setState(prevState => ({
        showAllRdvQuartier: !prevState.showAllRdvQuartier
      }));
    }
  }

  eventDetailInAddress(e, total: InterventionSlot[]) {
    let slot: InterventionSlot = e.event._def?.extendedProps?.slot;
    let slotUserCount = total.filter((ev) => ev.userId === slot.user.id).length
    
    return <div className="">
      {<div className="fw-bold">{slot.user.getFullName() + " (" + slot.slotInDay + " rdv)"}</div>}
      {slot?.contact?.quartier && <div className="">{slot?.contact?.counter?.connectionAddress?.fullAddress() + " (" + slot?.contact.quartier + ")"}</div>}
    </div>;
  }

  public render() {
    const {contact} = this.props
    let headersDayInAddress: CustomContentGenerator<DayHeaderContentArg>
    headersDayInAddress = (_) => {
      return _ != null ? <div className='row-flex'>
      <div>{_.text}</div>
      <div style={{fontWeight: "400"}} className='ms-4'>{contact.rdvInAddress.filter((slot) => {
        return slot.startAt?.getDate() == _?.date?.getDate();
      }).length} rdv</div>
      <div className='ms-auto'>{dayjs(_.date).format("D MMMM YYYY")}</div>
    </div> : null
    }
    let headersDayInQuartier: CustomContentGenerator<DayHeaderContentArg>
    headersDayInQuartier = (_) => {
      return _ != null ? <div className='row-flex'>
      <div>{_.text}</div>
      <div style={{fontWeight: "400"}} className='ms-4'>{contact.rdvInQuartier.filter((slot) => {
        return slot.startAt?.getDate() == _?.date?.getDate();
      }).length} rdv</div>
      <div className='ms-auto'>{dayjs(_.date).format("D MMMM YYYY")}</div>
    </div> : null
    }
    return (
      <div>
            <LightTabs
              tabTitles={["Rdv sur la même adresse", "Rdv dans le même quartier"]}
              tabContents={[
                () => <FullCalendar
                key={"calendar1"}
                  plugins={ [listPlugin] }
                  initialView='listMonth'
                  headerToolbar={ {
                    left: "title",
                    end: "prev,next",
                  } }
                  buttonText={{
                    today: "Aujourd'hui",
                    timeGridDay: "Par jour",
                    timeGridWeek: "Par semaine",
                    listWeek: "Liste"
                  }}
                  // schedulerLicenseKey="0344037874-fcs-1637598111"
                  contentHeight={"500px" || 'auto'}
                  allDaySlot={false}
                  eventContent={(e) => this.eventDetailInAddress(e, contact.rdvInAddress)}
                  locale={"fr"}
                  hiddenDays={[0, 6]}
                  dayHeaderContent={headersDayInAddress}
                  slotDuration={'00:15:00'}
                  slotMinTime="08:00:00"
                  eventClassNames={"pointer"}
                  // selectOverlap={false}
                  slotMaxTime="18:00:00"
                  // displayEventTime={list}
                  events={contact.rdvInAddress.map((slot: InterventionSlot) => ({
                    title: "",
                    start: slot.startAt,
                    slot: slot,
                    end: slot.endAt,
                    color: "#4d9cc1",
                    backgroundColor: "#4d9cc1",
                  }))}
                />,
                () => <><FullCalendar
                key={"calendar1"}
                plugins={ [listPlugin] }
                initialView='listMonth'
                headerToolbar={ {
                  left: "title",
                  end: "prev,next",
                } }
                buttonText={{
                  today: "Aujourd'hui",
                  timeGridDay: "Par jour",
                  timeGridWeek: "Par semaine",
                  listWeek: "Liste"
                }}
                // schedulerLicenseKey="0344037874-fcs-1637598111"
                contentHeight={"500px" || 'auto'}
                allDaySlot={false}
                dayHeaderContent={headersDayInQuartier}
                eventContent={(e) => this.eventDetailInAddress(e, contact.rdvInQuartier)}
                locale={"fr"}
                hiddenDays={[0, 6]}
                slotDuration={'00:15:00'}
                slotMinTime="08:00:00"
                eventClassNames={"pointer"}
                // selectOverlap={false}
                slotMaxTime="18:00:00"
                // displayEventTime={list}
                events={contact.rdvInQuartier.map((slot: InterventionSlot) => ({
                  title: "",
                  start: slot.startAt,
                  slot: slot,
                  end: slot.endAt,
                  color: "#4d9cc1",
                  backgroundColor: "#4d9cc1",
                }))}
                ref={this.calendarRef}
              />{!this.state.showAllRdvQuartier && (
                    <button className={"btn btn-primary position-relative bottom-0"} onClick={this.toggleShowAllRdvQuartier}>Afficher plus</button>
                )}</>
              ]}
            />
      <div>
      
      </div>
      </div>
    );
  }
}
