import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import UserSelect from '@components/modelSelect/UserSelect';
import User from '@models/User';
import Vehicule from '@models/Vehicule';
import DOMService from '@services/DOMService';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import FileList from '@components/vehicule/FileList';
import { IVehiculeFormProps } from '@components/form/VehiculeForm';
import { useState } from 'react';
import VehiculeFile from '@models/VehiculeFile';
import SmartInputText from '@components/input/SmartInputText';
import SelectInput from '@components/form/SelectInput';
import formModel from '@components/logic/FormModel';
import { Button } from 'react-bootstrap/lib/InputGroup';
import VehiculeService from '@services/VehiculeService';
import EntityManager from '@services/EntityManager';

interface propsForm {
  model: Vehicule
}

const VehiculeForm: React.FC<propsForm> = ({ model }) => {
  const [files, setFiles] = useState<VehiculeFile[]>(model.files || []);

  const handleConfirm = async () => {
    try {

     EntityManager.update(model, {only: ["mileage"]})

    } catch (error) {
      console.error('Erreur lors de la soumission :', error);
    }
  };

  return (

    <div className="fadeInDown">
      <div className="row">
        <div className="col-md-6 col-12">
          <SmartInputText
            model={model}
            name="licensePlate"
            label="Immatriculation"
            containerClassName="mb-3"
            disabled={true}
          />
        </div>
        <div className="col-md-6 col-12">
          <SmartInputText
            model={model}
            name="mileage"
            label="Kilométrage"
            containerClassName="mb-3"
            type="number"
          />
        </div>
        <div className="row">
        <div className="col-md-6 col-12">
          <SmartInputText
            model={model}
            name="badge"
            label="Numéro de Badge"
            containerClassName="mb-3"
            disabled={true}
          />
        </div>
        <div className="col-md-6 col-12">
          <SmartInputText
            model={model}
            name="card"
            label="Numéro de carte"
            containerClassName="mb-3"
            disabled={true}
          />
        </div>
        </div>
        <button onClick={() => handleConfirm()} style={{ backgroundColor: "#d17d33", color: "white" }} className='btn mt-2'>Mettre a jour le kilométrage</button>
      </div>
      {files && files.length > 0 && (
        <div className="row mt-4">
          <div className="col-12">
            <FileList
              files={files}
              vehiculeId={model.id}
              onFileDeleted={() => { }}
            />
          </div>
        </div>
      )}
      
    </div>
  );
};
export interface IInterventionSlotsPageProps {
}

export default class WorkerVehiculePage extends React.Component<IInterventionSlotsPageProps, { user: User }> {

  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  calendarRef = React.createRef<ContactSlotCalendar>()

  async selectUser(user: User) {
    this.setState({ user })
  }

  public render() {
    const { user } = this.state;
    return (
      <div className='bg-white p-2 mx-n2'>
        {console.log(user)}
        <UserSelect value={user} onChange={(e) => this.selectUser(e)} name="user" placeholder="Choisir un agent" label="Agent" containerClassName="mb-3" params={{ scope: "onlyEmployee" }} />
        {user &&
         <>
         {console.log(user.vehicules)}
         {user.vehicules.map((vehicule) => {
          return (
          <div>
            <VehiculeForm model={vehicule} ></VehiculeForm>
          </div>)
         })}
        </>
        }
      </div>
    );
  }
}
