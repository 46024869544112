import { sessionBloc } from '@bloc/SessionBloc';
import * as React from 'react';
import { Redirect } from 'react-router';

export interface IRedirectorProps {
}

export default class Redirector extends React.Component<IRedirectorProps> {
  public render() {
    let to
    if (sessionBloc.isAdminOrResponsible()) to = "/management"
    if (sessionBloc.isSecretary() || sessionBloc.isOrdonnanceur()) to = "/crm"
    if (sessionBloc.isWorker()) to = "/worker"
    if (sessionBloc.isCustomer()) to = "/clientarea/yards"
    return (
      <Redirect to={to} />
    );
  }
}
