import { SidebarRoute } from "@components/layout/SidebarRouter";
import User from "@models/User";
import DOMService from "@services/DOMService";
import UserGrid from "./UserGrid";

export default class CRMUserGrid extends UserGrid {

  public formRoute = SidebarRoute.UserForm
  static gridName = "usercrm"

  title() {
    return "Agents"
  }

  onClickNew = null

  onRowClick = this.onEdit

  public rowActions = (model: User) => <>
    {model.email && <div className="small-btn-icon bg-info text-white me-2" onClick={() => DOMService.openSidebar(SidebarRoute.UserSendPage, {type: "sms", worker: model})}>
      <i className="fas fa-send"></i>
    </div>}
  </>

  onDelete = null
}