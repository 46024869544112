
import React from "react";
import Model from "src/models/Model";
import DOMService from "src/services/DOMService";
import ModelsTable from "../common/ModelsTable";
import PaginationComponent from "../common/PaginationComponent";
import PerPageSelect from "../common/PerPageSelect";
import Scoper, { scopeConfig } from "../common/Scoper";
import SearchInput from "../input/SearchInput";
import { SidebarRoute } from "../layout/SidebarRouter";

export interface MultipleAction {
  label: string
  action: () => void
}

class ModelGrid<M extends Model = Model> extends React.Component<any, any> {

  public scopes: scopeConfig[];
  public gridConfig = {}
  public showRoute: SidebarRoute
  public formRoute: SidebarRoute
  static gridName = "model";
  static select: string[];
  public defaultScope

  multipleActions: MultipleAction[] = [];

  public rowActions = (model: M) => null;
  models = () => this.props.models

  public newOptions = () => ({onSubmit: this.submit})

  title() {
    return ""
  }

  submit = (model) => {
    DOMService.closeSidebar();
    this.props.manager.loadModels();
  }

  onEdit = (model: M) => {
    DOMService.openSidebar(
      this.formRoute,
      {id: model.id, onSubmit: (m) => this.submit(m), onPhotoChange: () => this.props.manager.loadModels()},
    )
  }

  onRowClick = (model: M) => {
    DOMService.openSidebar(this.showRoute, {id: model.id, onChange: () => this.props.manager.loadModels()})
  }

  onClickNew = () => {
    DOMService.openSidebar(
      this.formRoute,
      this.newOptions(),
    )
  }

  onDelete = (a) => this.props.manager.delete(a)

  specificActions = () => <></>
  specificFilters = () => <></>
  specificFooter = () => <></>

  onPerPageChange = (perPage) => {
    const gridClass: any = this.constructor
    localStorage.setItem(`gridPerPage${gridClass.gridName}`, perPage);
    this.props.manager.onPerPageChange(perPage);
  }

  multipleActionsRender = () => {
    return <div className="row-flex">
      {this.multipleActions.map(action => <button onClick={action.action} className="btn btn-outline-secondary ms-2">{action.label}</button>)}
    </div>
  }

  firstBar() {
    const { search, manager } = this.props;
    return <div className="mb-3">
      <div className="row-between">
        <div className="me-3">
          <SearchInput value={ search } innerRef={ manager.inputRef } onChanged={ manager.handleSearch } onClear={ manager.clearSearch } onSearch={ manager.onSearch } placeholder={"Rechercher"} />
        </div>
        <div className="row-end">
          {this.specificActions()}
          {this.onClickNew && <button onClick={this.onClickNew} className="btn btn-primary">Nouveau</button>}
        </div>
      </div>
    </div>
  }

  secondBar() {
    const { manager } = this.props;
    return <div className="mb-3">
        <div className="row-flex">
          <div className="row-flex">
            {this.scopes && <Scoper scopes={this.scopes} manager={manager} defaultScope={this.defaultScope}/>}
            {this.specificFilters()}
          </div>
        </div>
      </div>
  }

  tableArea() {
    const { models, lastPage, page, perPage, selected, manager, total } = this.props;
    return <div className="">
      <div className="row-flex">
        <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
        <div className="ms-4">
          <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
        </div>
        <div className="ms-auto">
            {total || 0} résultat(s)
        </div>
      </div>
      <ModelsTable
        config={this.gridConfig}
        models={models}
        onRowClick={this.onRowClick}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        selected={selected}
        onSelectedChange={manager.onSelectedChange}
        rowActions={this.rowActions}
        multipleActions={this.multipleActionsRender}
      />
      {models && <div className="d-flex justify-content-between fadeIn">
        <div className="row-flex">
          <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
          <div className="ms-4">
            <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
          </div>
        </div>
        {this.specificFooter()}
      </div>}
    </div>
  }

  render() {
    return <>
      {this.firstBar()}
      {this.secondBar()}
      {this.tableArea()}
    </>
  }
}

export default ModelGrid