import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import CustomerSelect from '@components/modelSelect/CustomerSelect';
import Contact from '@models/Contact';
import DOMService from '@services/DOMService';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';

export interface IContactFormProps extends IInjectedFormModelProps<Contact> {
}

export interface IContactFormState {
}

class ContactForm extends React.Component<IContactFormProps & IFormModelProps<Contact>, IContactFormState> {
  constructor(props: IContactFormProps & IFormModelProps<Contact>) {
    super(props);

    this.state = {
    }
  }

  onSubmit() {
    if (this.props.model.counter.connectionAddress.isOk()) {
      this.props.submit();
    } else {
      DOMService.setToaster(<div className="col-center">
      <div>
        <i className="fas fa-exclamation-circle fa-2x text-primary"></i>
      </div>
      <div>L'adresse doit être remplis</div>
    </div>)
    }
  }

  public render() {
    const {model, submitting, id, parentModel} = this.props;

    return (
      <SidebarLayout
        title={id ? "Modification d'un contact" : "Création d'un contact"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.onSubmit()} >{id ? "Modifier le contact" : "Créer le contact"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="firstname" label="Prénom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="lastname" label="Nom" containerClassName="mb-3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="email" label="Email" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="payerName" label="Nom du payeur" containerClassName="mb-3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="phone" label="Téléphone fixe" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="portable" label="Téléphone portable" containerClassName="mb-3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="quartier" label="Quartier" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="reference" label="PDD" containerClassName="mb-3" />
            </div>
          </div>
            <div className="fw-bold mt-5 mb-3">Adresse</div>
          <SmartInputText model={model.counter.connectionAddress} name="name" label="Nom adresse" containerClassName="mb-3" />
          <div className="row">
            <div className="col-md-6 col-12">
          <SmartInputText model={model.counter.connectionAddress} name="number" label="Numéro" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
          <SmartInputText model={model.counter.connectionAddress} name="address" label="Adresse" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model.counter.connectionAddress} name="addressComp" label="Complément d'adresse" containerClassName="mb-3" />
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model.counter.connectionAddress} name="cp" label="Code postal" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model.counter.connectionAddress} name="city" label="Ville" containerClassName="mb-3" />
            </div>
          </div>
          {!parentModel && <div>
            <div className="fw-bold mt-5 mb-3">Client</div>
            <CustomerSelect model={model} name="customer" />
          </div>}
          <div className="fw-bold mt-5 mb-3">Contrat</div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model.contract} name="ref" label="Référence" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model.contract} name="type" label="Type" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model.contract} name="status" label="Statut" containerClassName="mb-3" />
          <div className="fw-bold mt-5 mb-3">Compteur</div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model.counter} name="reference" label="Référence" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model.counter} name="diameter" label="Diamètre" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model.counter} name="accessibility" label="Accessibilité" containerClassName="mb-3" />
        </div>
      </SidebarLayout>
    );
  }
  
  
}

export default formModel<IContactFormProps>(ContactForm, {modelClass: Contact})