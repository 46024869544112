import React, { useEffect, useState } from 'react';
import apiService from "@services/ApiService";

export interface FileListProps {
    files: Array<{ id?: number; filePath: string; fileName?: string; fileType?: string }>;
    vehiculeId: number;
    onFileDeleted: (fileId: number) => void;
}

const FileList: React.FC<FileListProps> = ({ files, vehiculeId, onFileDeleted }) => {

    const [filesDownload, setFilesDownload] = useState(null);

    useEffect(() => {
        // Fonction asynchrone pour récupérer les fichiers
        const fetchFilesData = async () => {
            if (files) {
                let filesData = [];
                for (let i = 0; i < files.length; i++) {
                    try {
                        let data = await apiService.get(`vehicules/${vehiculeId}/files/${i}`);
                        filesData.push(data.data);
                    } catch (error) {
                        console.error(`Erreur lors de la récupération du fichier ${i}:`, error);
                    }
                }
                setFilesDownload(filesData);
            }
        };
    
        fetchFilesData(); // Appelle la fonction asynchrone
    }, [files]);
    

    if (!files || files.length === 0) {
        return <div>Aucun fichier disponible</div>;
    }

    const uniqueFiles = files.filter((file, index, self) =>
        index === self.findIndex((f) => (
            f.filePath === file.filePath
        ))
    );




    const handleDelete = async (fileId?: number) => {
        if (!fileId) {
            console.error('ID du fichier non disponible pour la suppression.');
            return;
        }

        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fichier ?')) {
            try {
                await apiService.delete(`vehicules/${vehiculeId}/files/${fileId}`);
                onFileDeleted(fileId);
            } catch (error) {
                console.error('Erreur lors de la suppression du fichier :', error);
            }
        }
    };

    return (
        <div className="file-list">
            {filesDownload && <>
                {files.map((file, index) => {
                    const fileName = file.fileName || (file.filePath ? file.filePath.split('/').pop() : 'Fichier');

                    const fileType = file.fileType || '';
                    return (
                        <div key={file.id || index} className="file-item mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5>{fileName}</h5>
                                {file.id && (
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(file.id)}
                                    >
                                        Supprimer
                                    </button>
                                )}
                            </div>
                            {fileType.startsWith('image/') ? (
                                <img
                                    src={filesDownload[index].file}
                                    alt={fileName}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            ) : fileType === 'application/pdf' ? (
                                <iframe src={filesDownload[index].file} width="100%" height="500px" title={fileName}></iframe>
                            ) : (
                                <a href={filesDownload[index].file} target="_blank" rel="noopener noreferrer">
                                    Ouvrir {fileName}
                                </a>
                            )}
                        </div>
                    );
                })}
            </>}

        </div>
    );
};

export default FileList;

function componentDidMount() {
    throw new Error('Function not implemented.');
}
