import { sessionBloc } from '@bloc/SessionBloc';
import GodinLibStat from '@components/charts/GodinLibStat';
import AdminModelsList from '@components/common/AdminModelsList';
import GodinLibGrid from '@components/grid/GodinLibGrid';
import SectorGrid from '@components/grid/SectorGrid';
import MainLayout from '@components/layout/MainLayout';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import Sector from '@models/Sector';
import User from '@models/User';
import DOMService from '@services/DOMService';
import { filter } from 'lodash';
import * as React from 'react';

export interface IGodinLibStatPageProps {
}

export default class GodinLibStatPage extends React.Component<IGodinLibStatPageProps> {

  public render() {
    return (
      <MainLayout title="Godin lib">
        <div className="app-content">
            <GodinLibStat date={new Date()}></GodinLibStat>
        </div>
      </MainLayout>
    );
  }
}