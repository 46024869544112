import User from "@models/User";
import React from "react";
import ModelSelect, { IModelSelectProps } from "./ModelSelect";
import Sector from "@models/Sector";

export default class SectorSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: Sector) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.name}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<Sector> {...this.props} formatOptionLabel={this.formatOptionLabel} attributeRef="id" modelClass={Sector} />
    );
  }

}
