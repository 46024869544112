import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Sector from "@models/Sector";
import DOMService from "@services/DOMService";
import ModelGrid from "./ModelGrid";

export default class CustomerSectorsGrid extends ModelGrid {

    public formRoute = SidebarRoute.SectorForm
    static gridName = "customercrm"

    title() {
        return "Secteurs"
    }

    gridConfig = {
        headers: ["Secteur", "Client", "Nombre d'abonné"],
        body: [
            (sector: Sector) => <b>{sector.name}</b>,
            (sector: Sector) => <div>{sector.customer.name}</div>,
            (sector: Sector) => <div>{sector.contactsCount}</div>,
            //   (sector: Sector) => sector.phone,
            //   (sector: Sector) => <div>
            //   <div>{sector.address}</div>
            //   <div>{sector.cpAndCity()}</div>
            // </div>,

        ]
    }

    public rowActions = (model: Sector) => <>

    </>

    onClickNew = null

    onRowClick = (model) => {
        sessionBloc.push("/clientarea/sectors/" + model.id + "/stats")
    };

    onDelete = null
}