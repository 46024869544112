import UserSelect from '@components/modelSelect/UserSelect';
import User from '@models/User';
import * as React from 'react';
import ApiService from "@services/ApiService";
import MonthPicker from "@components/common/MonthPicker";

export interface ICrmInterventionSlotPageProps {
    date: Date
    refreshDate(date): any;
}

export default class AgendaWorkerStats extends React.Component<ICrmInterventionSlotPageProps, { user: User, date: Date, stats: any[] }> {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            date: props.date,
            stats: null
        }
        this.loadStats = this.loadStats.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
    }

    selectUser(user: User) {
        this.setState({user})
        this.loadStats(user)
    }

    async loadStats(user) {
        if (user){
            let data = await ApiService.get("agenda_worker_stats", {
                month: this.state.date.getMonth() + 1,
                year: this.state.date.getFullYear(),
                user_id: user.id
            });
            this.setState({stats: data.data})
        }
    }

    handleMonthChange(newDate: Date) {
        this.setState({date: newDate}, () => this.loadStats(this.state.user))
        this.props.refreshDate(newDate)
    }

    public render() {
        const {user} = this.state;
        let globalStats = {}
        if (this.state.stats) {
            this.state.stats.forEach((stat) => {
                if (globalStats.hasOwnProperty(stat.state_id)) {
                    globalStats[stat.state_id]["day"] += stat.day;
                } else {
                    globalStats[stat.state_id] = {name: stat.state_name, day: stat.day, dates: stat.dates.replaceAll(",", ", ")};
                }
                //Ajout des nuitée dans le compte des journée
                if(stat.state_name === "Nuitée" ){
                    if (globalStats.hasOwnProperty(1)) {
                        globalStats[1]["day"] += stat.day;
                    } else {
                        globalStats[1] = {name: "Journée", day: stat.day, dates: stat.dates.replaceAll(",", ", ")};
                    }
                }
            })
        }
        return (
            <div className="">
                <div className="">
                    <div className="app-content">
                        <h4 className='fw-bold mb-3'>Calendrier</h4>
                    </div>
                    <div>
                        <MonthPicker initialMonth={this.state.date} onMonthChange={this.handleMonthChange}/>
                    </div>

                    <UserSelect value={user} onChange={(e) => this.selectUser(e)} name="user"
                                placeholder="Choisir un agent" label="Agent" containerClassName="mb-3" params={{
                        scopes: [
                            JSON.stringify({name: "onlyEmployee", param: true}),
                        ]
                    }}/>
                    {Object.keys(globalStats).map((stat_index) => {
                        if (stat_index === "8"){
                            return<>
                                <div key={"demie"}>Demie journée: {globalStats[stat_index].day}</div>
                                <div key={stat_index}>{globalStats[stat_index].name} {"(" + globalStats[stat_index].day + ")"}: {globalStats[stat_index].dates.split(",").map((date) =>
                                    <>
                                        <span key={date}>{(new Date(date)).toLocaleDateString('fr-FR', { day: 'numeric', month: 'numeric' })} </span>
                                    </>)}</div>
                            </>
                        }else{
                            return <div key={stat_index}>{globalStats[stat_index].name} {stat_index === "1" || stat_index === "2" ? globalStats[stat_index].day : "(" + globalStats[stat_index].day + ") "}: {(stat_index !== "1" && stat_index !== "2") && globalStats[stat_index].dates.split(",").map((date) =>
                                <span key={date}>{(new Date(date)).toLocaleDateString('fr-FR', { day: 'numeric', month: 'numeric' })} </span>
                            )}</div>
                        }
                    })}
                </div>
            </div>
        );
    }
}
