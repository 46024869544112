import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    vehicule: any;
    initialVehicule: any;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, handleClose, handleConfirm, vehicule, initialVehicule }) => {
    const mileageDifference = vehicule.mileage - initialVehicule.mileage;
    const hasMileageChanged = vehicule.mileage !== initialVehicule.mileage;
    const hasLicenseChanged = vehicule.licensePlate !== initialVehicule.licensePlate;
    const hasUserChanged = JSON.stringify(initialVehicule.users.map((u: any) => u.id)) !== JSON.stringify(vehicule.users.map((u: any) => u.id));
    const hasStatusChanged = vehicule.status !== initialVehicule.status;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation des modifications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hasLicenseChanged && (
                    <>
                        <p><strong>Immatriculation :</strong></p>
                        <p>Avant : {initialVehicule.licensePlate}</p>
                        <p className="text-success">Après : {vehicule.licensePlate}</p>
                    </>
                )}

                {hasMileageChanged && (
                    <>
                        <p><strong>Kilométrage :</strong></p>
                        <p>Avant : {initialVehicule.mileage} km</p>
                        <p className="text-success">Après : {vehicule.mileage} km</p>
                        <p><strong>Différence :</strong> {mileageDifference} km</p>
                    </>
                )}

                {hasUserChanged && (
                    <>
                        <p><strong>Utilisateurs :</strong></p>
                        <p>Avant : {initialVehicule.users.map((user: any) => user.getFullName()).join(", ")}</p>
                        <p className="text-success">Après : {vehicule.users.map((user: any) => user.getFullName()).join(", ")}</p>
                    </>
                )}

                {hasStatusChanged && (
                    <>
                        <p><strong>Statut :</strong></p>
                        <p>Avant : {initialVehicule.status}</p>
                        <p className="text-success">Après : {vehicule.status}</p>
                    </>
                )}

                {!hasLicenseChanged && !hasMileageChanged && !hasUserChanged && !hasStatusChanged && (
                    <p>Aucune modification détectée.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirmer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;