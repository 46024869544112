import ContactShow from '@components/crm/ContactShow';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Call from '@models/Call';
import dayjs from 'dayjs';
import * as React from 'react';

export interface ICallsPageProps {
}

export default class CallsPage extends React.Component<ICallsPageProps, {contactId?: number}> {

  constructor(props) {
    super(props);
    this.state = {
      contactId: null
    }
  }
  async loadContact(contactId) {
    this.setState({contactId})
  }

  buildList(calls: Call[]) {
    let elements = [];
    let dateRef: Date;
    calls?.forEach((c) => {
      if (!dateRef || !dayjs(c.createdAt).isSame(dateRef, 'day')) {
        elements.push(<div className='mb-2 mt-4 text-secondary fw-bold'>
          {dayjs(c.createdAt).isSame(dayjs(), "day") ? "Aujourd'hui" : dayjs(c.createdAt).format("D MMMM YYYY")}
        </div>)
        dateRef = c.createdAt
      }
      elements.push(this.renderCall(c));
    })
    return elements;
  }

  renderCall(call: Call) {
    return <div className='mb-3'>
      <div className="row-flex">
        <div className="me-2 row-center bg-light rounded-2 height-6 width-9 row-flex">
          <i className=' text-danger fas fa-phone-missed'></i>
          <div className='text-secondary text-s ms-2'>
            {dayjs(call.createdAt).format("HH:mm")}
          </div>
        </div>
        <div>
          {call.contact && <div className='fw-bold link' onClick={() => this.loadContact(call.contactId)}>
            {call.contact.getFullName()}
          </div>}
          <div>
            {call.formattedNumber()}
          </div>
        </div>
        <div className="ms-auto row-end">
          {/* <div onClick={() => {}} className="small-btn-icon bg-success me-2">
            <i className='fas fa-check text-white'></i>
          </div> */}
          <div onClick={() => call.contact.startCall()} className="small-btn-icon bg-primary">
            <i className='fas fa-phone text-white'></i>
          </div>
        </div>
      </div>
    </div>
  }

  public render() {
    return (
      <div className="home">
        <div className="px-0 pt-4 px-md-5">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-12 mb-3">
              <div className="app-content" style={{maxHeight: "calc(100vh - 50px)", overflow: "auto"}}>
                <h5 className='mb-4 fw-bold'>Appels manqués</h5>
                <RecordManagerV2<Call> options={{modelClass: Call, loadOnReady: true, paginate: true}}>
                  {(manager, props) => <div className="">
                    {this.buildList(props.models).map(e => e)}
                  </div>}
                </RecordManagerV2>
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-12 mb-3">
              <div className="app-content">
                {!this.state.contactId ? <div>
                  Sélectionnez un appel pour afficher la fiche du contact ici
                </div> : <ContactShow oneCol id={this.state.contactId} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
