import CrmCalendar from '@components/calendar/CrmCalendar';
import CrmActivities from '@components/crm/CrmActivities';
import * as React from 'react';

export interface ICRMPageProps {
}

export interface ICRMPageState {
}

export default class CRMPage extends React.Component<ICRMPageProps, ICRMPageState> {
  constructor(props: ICRMPageProps) {
    super(props);
    

    this.state = {
    }
  }

  public render() {
    return (
      <div className="home">
        <div className="px-0 pt-4 px-md-5">
          <div className="row">
            <div className="col-xl-7 col-12 mb-3">
              <div className="app-content fadeInDown">
                <CrmCalendar />
              </div>
            </div>
            <div className="col-xl-5 col-12 mb-3">
              <div className="app-content fadeInDown">
                <h5 className="mb-5 fw-bold">
                  Historique des échanges
                </h5>
                <div  style={{maxHeight: "calc(100vh - 260px)", overflow: "auto"}}>
                  <CrmActivities />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
