import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Contact from '@models/Contact';
import CustomEmail from '@models/CustomEmail';
import Customer from '@models/Customer';
import HttpResponse from '@services/apiResponse/HttpResponse';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import * as React from 'react';

export interface ISendPageProps {
  contact?: Contact
  customer?: Customer
  dateRdv?: Date
  type: string
  to?: string
}

export default class SendPage extends React.Component<ISendPageProps, {submitting: boolean, to: string, edit: boolean, subject: string, text: string, credit}> {

  public hasTyped = false;

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      edit: false,
      subject: "",
      text: "",
      credit: null,
      to: props.to || (props.type === "sms" ? props.contact?.portable : props.contact?.email),
    }
  }

  onPickEmail(email: CustomEmail) {
    this.setData(email)
    this.setState({
      edit: true,
      subject: email.getRealObjectWith(),
      text: email.getRealContentWith()
    })
  }

  async loadCredit() {
    let res = await EntityManager.get(CustomEmail, {path: "plan"});
    this.setState({credit: res.data});
  }

  componentDidMount(): void {
    if (this.props.type === "email") this.loadCredit();
  }

  setData(email: CustomEmail) {
    const {contact, customer, dateRdv} = this.props
    email.withContact(contact).withCustomer(customer).withDateRdv(dateRdv);
  }

  async send() {
    const {subject, text, to} = this.state
    const {type, contact} = this.props
    this.setState({submitting: true});
    let response: HttpResponse;
    let successMessage: string
    if (type === "sms") {
      successMessage = "Le sms a bien été envoyé !"
      let params = {
        text, to, contactId: contact?.id
      }
      response = await EntityManager.postDirect(Contact, {path: "sms", params})
    } else {
      successMessage = "L'email a bien été envoyé !"
      let params = {
        subject, text, to, contactId: contact?.id
      }
      response = await EntityManager.postDirect(Contact, {path: "email", params})
    }

    if (response.ok) {
      DOMService.closeSidebar();
      if (contact && contact.manager) contact.manager.load();
      setTimeout(() => DOMService.setToaster(successMessage), 500);
      GetX.get("contactExchanges")?.loadModels()
    } else {
      DOMService.setToaster(response.data.result);
    }

  }

  renderItem(template) {
    return <div  onClick={() => this.onPickEmail(template)} className='mb-3 border rounded-2 pointer py-3 px-4'>
      <div className="row-flex">
        <i className='fas fa-envelope me-4 fa-2x text-secondary'></i>
        <div>
          <div>
            {template.title}
          </div>
          <div className='text-secondary'>
            {template.object}
          </div>
          {(template.needRdvDate() && !this.props.dateRdv) && <div className='text-danger text-s'>
            Il est conseillé de sélectionner un rendez-vous afin d'utiliser ce template.
          </div>}
          {/* {template.needContact() && <div className='text-danger text-s'>
            Il est conseillé de sélectionner un contact afin d'utiliser ce template.
          </div>} */}
        </div>
      </div>
    </div>
  }

  public render() {
    const {submitting, subject, text, to, edit, credit} = this.state
    const {type} = this.props
    return (
      <SidebarLayout
        title={"Envoyer un "+ (type)} 
        action={<div>{(credit ? " (" + credit + " crédits restant)" : "")}</div>}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.send()} >Envoyer</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText value={to} onChange={(to) => this.setState({to})} label="Destinataire" containerClassName='mb-3' />
          {edit && <div className="mb-4">
            <div>
              {type === "email" && <SmartInputText value={subject} onChange={(subject) => this.setState({subject})} label="Objet" containerClassName='mb-3' />}
              <SmartInputTextarea value={text} onChange={(text) => this.setState({text})} label="Corps" containerClassName='mb-3' rows={12} />
            </div>
          </div>}
          <div className='mb-3 fw-bold'>
            Vos modèles
          </div>
          {this.renderItem(new CustomEmail({type, title: "Saisie libre"}))}
          <RecordManagerV2<CustomEmail> options={{modelClass: CustomEmail, loadOnReady: true, paginate: true, params: {type}}}>
            {(manager, props) => <div className="">
              {props.models?.map((m) => {
                return this.renderItem(m);
              })}
            </div>}
          </RecordManagerV2>
        </div>
      </SidebarLayout>
    );
  }
}
