import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Customer from '@models/Customer';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import SectorCustomerForm from './SectorCustomerForm';

export interface ICustomerFormProps extends IInjectedFormModelProps<Customer> {
}

export interface ICustomerFormState {
  img_url_to_show: string
}

class CustomerForm extends React.Component<ICustomerFormProps & IFormModelProps<Customer>, ICustomerFormState> {
  constructor(props: ICustomerFormProps & IFormModelProps<Customer>) {
    super(props);

    this.state = {
      img_url_to_show: props.model.imgUrl
    }
    this.change_url = this.change_url.bind(this);
  }

  public change_url(e){
    this.setState({img_url_to_show: e})
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    
    return (
      <SidebarLayout
        title={id ? "Modification d'un client" : "Création d'un client"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier le client" : "Créer le client"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="name" label="Nom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="email" label="Email" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model} name="address" label="Adresse" containerClassName="mb-3" />
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="cp" label="Code postal" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="city" label="Ville" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model} name="phone" label="Téléphone" containerClassName="mb-3" />
          <SmartInputText model={model} name="imgUrl" label="Url du logo" containerClassName="mb-3" onChange={this.change_url} />
          <img src={model.imgUrl} width={161}/>
          <SmartInputTextarea model={model} name="comment" label="Commentaire" containerClassName="mb-3" />
          <SectorCustomerForm  customer={model} />
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<ICustomerFormProps>(CustomerForm, {modelClass: Customer})