import React, { useState, useEffect } from 'react';
import FileList from '@components/vehicule/FileList';
import MileageTab from '@components/vehicule/MileageTab';
import MaintenanceTab from '@components/vehicule/MaintenanceTab';
import VehiculeService from '@services/VehiculeService';
import Vehicule from '@models/Vehicule';

const VehiculeDetails: React.FC<{ vehiculeId: number; onClose: () => void }> = ({ vehiculeId, onClose }) => {
    const [vehicule, setVehicule] = useState<Vehicule | null>(null);
    const [activeTab, setActiveTab] = useState('general');
    const [files, setFiles] = useState([]);

    useEffect(() => {
        VehiculeService.loadVehiculeDetails(vehiculeId).then((vehiculeData) => {
            setVehicule(vehiculeData);
            setFiles(vehiculeData.files || []);
        });
    }, [vehiculeId]);

    if (!vehicule) return <div className="text-center"><i className="fa fa-spinner fa-spin"></i> Chargement...</div>;

    const handleFileDeleted = (fileId: number) => {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'mileage':
                return <MileageTab vehicule={vehicule} />;
            case 'maintenance':
                return <MaintenanceTab vehicule={vehicule} onClose={onClose} />;
            case 'general':
            default:
                return (
                    <div className="card shadow-sm mt-3 p-3">
                        <h4 className="vehicule-title text-primary">{vehicule.licensePlate}</h4>
                        <p><strong>Kilométrage :</strong> {vehicule.mileage.toLocaleString()} km</p>
                        <p><strong>Statut :</strong> {vehicule.status}</p>
                        <p><strong>Utilisateurs :</strong> {vehicule.users.length > 0 ? vehicule.users.map(user => `${user.firstname} ${user.lastname}`).join(', ') : 'Non assigné'}</p>
                        <p><strong>Numéro de Badge :</strong> {vehicule.badge}</p>
                        <p><strong>Numéro de carte :</strong> {vehicule.card}</p>
                        <p><strong>Contrat d'entretien :</strong> {vehicule.cleaningContract ? "Oui": "Non"}</p>
                        <p><strong>Propriétaire :</strong> {vehicule.owner}</p>
                        <FileList
                            files={vehicule.files}
                            vehiculeId={vehicule.id}
                            onFileDeleted={handleFileDeleted}
                        />
                    </div>
                );
        }
    };

    return (
        <div className="vehicule-details container my-4">
            <ul className="nav nav-tabs justify-content-center">
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'general' ? 'active' : ''}`} onClick={() => setActiveTab('general')}>
                        Général
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'mileage' ? 'active' : ''}`} onClick={() => setActiveTab('mileage')}>
                        Suivi Kilométrique
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${activeTab === 'maintenance' ? 'active' : ''}`} onClick={() => setActiveTab('maintenance')}>
                        Suivi Maintenance
                    </button>
                </li>
            </ul>
            <div className="tab-content mt-4">
                {renderTabContent()}
            </div>
            <button className="btn btn-danger mt-4" onClick={onClose}>Fermer</button>
        </div>
    );
};

export default VehiculeDetails;