import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import * as React from 'react';
import Alert from '@models/Alert';
import DOMService from '@services/DOMService';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import { sessionBloc } from '@bloc/SessionBloc';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';

export interface ICrmCalendarProps {
}

export default class CrmCalendar extends React.Component<ICrmCalendarProps, {alerts: Alert[]}> {

  constructor(props) {
    super(props);
    this.state = {
      alerts: null
    }
  }

  componentDidMount() {
    this.loadAlerts();
  }

  async loadAlerts() {
    let response = await EntityManager.all<Alert>(Alert);
    this.setState({alerts: response.models});
  }

  select =(e) => {
    let alert: Alert = new Alert({
      startAt: e.date || e.start,
      to: sessionBloc.user
    });
    
    DOMService.openSidebar(SidebarRoute.AlertForm, {newModel: alert, onSubmit: (newAlert) => {
      DOMService.closeSidebar();
      this.loadAlerts();
    }})
  }

  getEvents = () => {
    if (!this.state.alerts) return [];
    return this.state.alerts.map((alert: Alert) => ({
      title: alert.contact?.getFullName() ?? alert.message ?? "alerte",
      start: alert.startAt,
      alert: alert,
      end: dayjs(alert.startAt).add(30, "minute").toDate(),
      color: alert.toId ===sessionBloc.user.id ? "#4d9cc1" : "#4dc1b1",
      backgroundColor: alert.toId ===sessionBloc.user.id ? "#4d9cc1" : "#4dc1b1",
    }))
  }

  onPickAlert(alert) {
    DOMService.openSidebar(SidebarRoute.AlertForm, {id: alert.id, onSubmit:(alert) => {
      DOMService.closeSidebar();
      this.loadAlerts();
    }})
  }

  eventContent = ({event}) => {
    let alert: Alert = event._def.extendedProps.alert
    let tooltip = ""
    if (alert.contact) tooltip += `${alert.contact.getFullName()} \n`
    if (alert.message) tooltip += `${alert.message}\n`;
    tooltip += "Alerte pour " + alert.users.map(u => u.getFullName()).join(", ")
    return <div onClick={(e) => this.onPickAlert(alert)} className="" aria-label={tooltip} data-balloon-break data-balloon-length="large" data-balloon-pos={event.start.getHours() > 14 ? "up" : "down"}>
      <div className="fw-bold">{event.title}</div>
    </div>;
  };

  public render() {
    // const {user} = this.props;
    return (
      <FullCalendar
        plugins={ [timeGridPlugin, interactionPlugin] }
        initialView='timeGridWeek'
        headerToolbar={ {
          left: "title",
          end: "prev,next",
        } }
        slotMinTime="08:00:00"
        slotMaxTime="18:00:00"
        allDaySlot={false}
        schedulerLicenseKey="0344037874-fcs-1637598111"
        contentHeight={"485px"}
        eventClassNames="pointer"
        locale={"fr"}
        eventContent={this.eventContent}
        hiddenDays={[0, 6]}
        dateClick={this.select}
        select={this.select}
        selectable={true}
        events={this.getEvents()}
      />
    );
  }
}
