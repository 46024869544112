import { sessionBloc } from '@bloc/SessionBloc';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import SidebarRouter, { SidebarRoute } from '@components/layout/SidebarRouter';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import WorkerContactShow from './WorkerContactShow';

export interface IWorkerSlotProps {
  onDelete?
  onUpdate?
  slot: InterventionSlot
}

export default class WorkerSlot extends React.Component<IWorkerSlotProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      displayComment: false,
      instruction: ""
    }
  }

  async changeStatus(status) {
    const {slot} = this.props
    slot.status = status;
    await EntityManager.update(slot, {only: ["status"]});
  }

  async toDone() {
    const { onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toDone"})
    onUpdate()
  }

  async toCancel() {
    const { onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toCanceled"})
    onUpdate()
  }

  async toToDo() {
    const {onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toTodo"})
    onUpdate()
  }

  async toAbsent() {
    const {onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toAbsent"})
    onUpdate()
  }

  async toImp() {
    const {onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toImp", params: {instruction: this.state.instruction}})
    onUpdate()
  }

  async toNeedRecall() {
    const {onUpdate, slot} = this.props
    await EntityManager.post(InterventionSlot, {path: slot.id + "/toNeedRecall", params: {instruction: this.state.instruction}})
    onUpdate()
  }

  armNeedRecall() {
    this.setState({displayComment: true});
  }


  public render() {
    const {onDelete, onUpdate, slot} = this.props
    return (
      <SidebarLayout
        title={"Détail Rendez-vous"}
        >
      <div>
        { !sessionBloc.isWorker() && <><div className='row-flex mb-3'>
            <div onClick={() => SidebarRouter.push(SidebarRoute.FullContactShow, {id: slot.contactId})} className='btn btn-primary'>Fiche contact</div>
            {onUpdate && <div onClick={() => DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, onSubmit: () => onUpdate()})} className="btn btn-info ms-2">
              Modifier RDV
            </div>}
            {onDelete && <div onClick={() => onDelete()} className="btn btn-danger ms-2">
              Supprimer RDV
            </div>}
          </div>
          <hr />
          </>
        }
          <div>
            <div className='row-between mb-4'>
              <div>
                <span>Statut : </span>
                <span className='fw-bold' style={{color: slot.getColor()}}>{slot.getHumanStatus()}</span>
              </div>
              <div>
                <span>Agent : </span>
                <span className='fw-bold text-capitalize'>{slot.user.getFullName()}</span>
              </div>
            </div>
            <div className="text-secondary mb-2">Changer l'état du rendez-vous</div>
            {this.state.displayComment ? <div className=''>
              <SmartInputTextarea onChange={(e) => this.setState({instruction: e})} value={this.state.instruction} placeholder='Votre commentaire...' />
              <div className="row-flex">
                <button onClick={() => this.setState({displayComment: false})} className='btn btn-secondary text-white mt-2 me-auto'>RETOUR</button>
                <button onClick={() => this.toImp()} style={{backgroundColor: "#c833d1", color: "white"}} className='btn mt-2 me-3'>IMPOSSIBLE</button>
                <button onClick={() => this.toNeedRecall()} style={{backgroundColor: "#d17d33", color: "white"}} className='btn mt-2'>A RAPPELER</button>
              </div>
            </div> : <div className='row-flex flex-wrap'>
              {slot.status !== "done" && <div>
                <button onClick={() => this.toDone()} className='btn mb-3 btn-success me-3'>FAIT</button>
              </div>}
              {slot.status !== "canceled" && <div>
                <button onClick={() => this.toCancel()} className='btn mb-3 btn-warning text-white me-3'>ANNULER</button>
              </div>}
              {slot.status !== "absent" && <div>
                <button onClick={() => this.toAbsent()} className='btn mb-3 btn-info
                 text-white me-3'>ABSENT</button>
              </div>}
              {slot.status !== "todo" && <div>
                <button onClick={() => this.toToDo()} className='btn mb-3 btn-primary me-3'>A FAIRE</button>
              </div>}
              {slot.status !== "unsuccessful" && <div>
                <button onClick={() => this.armNeedRecall()} className='btn btn-danger mb-3 me-3'>SANS SUCCES</button>
              </div>}
            </div>}
          </div>
          <WorkerContactShow id={slot.contactId} />
      </div>
      </SidebarLayout>
    );
  }
}
