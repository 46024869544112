import * as React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import logo from "../assets/images/favicon.png";
import SectorStatAdmin from './SectorStatAdmin';
import SectorStatGround from './SectorStatGround';
import SectorStatProgression from './SectorStatProgression';
import ClientSectorPage from './ClientSectorPage';

export interface ISectorStatProps {
    id
}

export default class ClientSectorStat extends React.Component<ISectorStatProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    statusKey = {
      "MODULE": "interventions à faire en module",
      "FAIT": "interventions réalisées avec succès",
      "A CHARGER": "interventions restantes à programmer",
      "RDV": "interventions planifiées avec l'abonné",
      "1 APPEL": "1ère relance",
      "A RAPPELER": "Abonné à contacter suite à un avis de passage",
      "2 APPEL": "2ème relance",
      "3 APPEL": "3ème relance",
      "CHARGEE": "Interventions chargées sur nos agents",
      "IMP": "A valider avec le chef de secteur pour les exclure",
      "INJ": "Abonné injoignable (pas de téléphone ni de mail)",
      "REFUS": "Refus de l'intervention par l'abonné",
      "MANQ": "Interventions inexistantes dans le logiciel client mais présentes dans le CRM",
      "EXCLUS": "interventions ne pouvant pas être réalisées par le sous-traitant",
      "NE PAS RAPPELER": " Interventions dont l'abonné a résilié son abonnement - Passage de terrain effectué",
      "IMP VALIDE": "IMP validée par le chef de secteur",
      "ANNULE": "Annulé",
    }

    colorProg = {
        "Général": "#1bc76f",
        "Compteurs": "#3dbddb",
        "Modules": "#483ddb",
    }

    async componentDidMount() {
        // let res: any = await EntityManager.getDirect(Sector, {path: this.props.id +  "/stats"});

        // let data: any = res.data

        // this.setState({data});
    }


    public render() {
        const {id} = this.props;
        // if (!data) return <></>

        // let generalProg  = Object.values(data.data["Général"].progression).filter((e: any) => e.date);
        // generalProg.forEach((prog) => {

        // })

        return (
            <div className='bg-white'>
                <div className="client-navbar border-bottom">
                    <div className='px-0 px-md-4 w-100'>
                        <div className="row-between">
                            <div className="row-center">
                                <div className="rounded row-flex sidebar-link">
                                    <img src={logo} alt="" width="30px" />
                                </div>
                                <div className="fw-bold ms-1 godin-logo mb-0" style={{fontSize: "1.5rem"}}>GODIN STATISTIQUES</div>
                                <div className="row-end">
                                  <NavLink to={"/clientarea/sectors/" + id+ "/stats/progression"} className="link ms-5 fw-bold">
                                    Progression
                                  </NavLink>
                                  <NavLink to={"/clientarea/sectors/" + id+ "/stats/ground" } className="link ms-5 fw-bold">
                                    Avancement terrain
                                  </NavLink>
                                  <NavLink to={"/clientarea/sectors/" + id+ "/stats/admin"} className="link ms-5 fw-bold">
                                    Avancement administratif
                                  </NavLink>
                                  <NavLink to={"/clientarea/sectors/" + id+ "/stats/contacts"} className="link ms-5 fw-bold">
                                    Liste contacts
                                  </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='p-4'>
                    <Switch>
                        <Route path="/clientarea/sectors/:id/stats/progression"
                               render={() => <SectorStatProgression id={id}/>}/>
                        <Route path="/clientarea/sectors/:id/stats/ground" render={() => <SectorStatGround id={id}/>}/>
                        <Route path="/clientarea/sectors/:id/stats/admin" render={() => <SectorStatAdmin id={id}/>}/>
                        <Route path="/clientarea/sectors/:id/stats/contacts" render={() => <ClientSectorPage id={id} />}/>
                    </Switch>
                </div>
                {/* <div className="app-content">
          <h2 className="fw-bold mb-5">Statut des interventions</h2>
          <h4 className="fw-bold my-5">PROGRESSION GLOBALE A LA SEMAINE</h4>
          <div className='mb-6'>
            <Line
              style={{height: "450px"}}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
              data={{
                labels: generalProg.map((e: any) => "Semaine " + e.date).reverse(),
                datasets: Object.keys(data.data).map(key => ({
                  data: Object.values(data.data["Général"].progression).filter((e: any) => e.date).map((e: any) => {
                    return data.data[key]?.progression.find(p => p.date===e.date)?.total || 0
                  }).reverse(),
                  label: key,
                  fill: true,
                  borderColor: this.colorProg[key],
                  backgroundColor: this.colorProg[key] + "00",
                  // borderColor: this.yearBorderColor,
                  borderWidth: 1,
                  tension: 0.5
                }))
              }}
              />
          </div>
          <div>
            <table className='table border'>
              <thead>
                <tr>
                  <th>#</th>
                  {generalProg.map((e: any) => (
                    <th>{"Semaine " + e.date}</th>
                  )).reverse()}
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(data.data).map(key => (
                    <tr>
                      <td>{key}</td>
                      {generalProg.map((e: any) => (
                        <td>{data.data[key]?.progression.find(p => p.date===e.date)?.total || 0}</td>
                      )).reverse()}
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className='mb-3 fw-bold text-secondary'>Complété à {(data.complete * 100).toFixed(0)} %</div>
          <ProgressBar className="w-100 mb-5" now={data.complete * 100}/>
          <div className='mb-3 fw-bold text-secondary'>Légende</div>
          <div className="mb-6">
            {OptionService.get("interventions_status").map(status => (
              <div>
                <div className="">
                  <div className="d-inline-block size-3 me-2 rounded-pill" style={{backgroundColor: status.color}}></div>
                  <span className='fw-bold me-2'>{status.label} :</span>
                  <span>
                    {this.statusKey[status.label]}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <LightTabs
            headerStyle={{width: "500px", margin: "auto"}}
            tabTitles={Object.keys(data.data)}
            tabContents={Object.keys(data.data).map(k => () => <SectorStatTab quartiers={data.quartiers} data={data.data[k]} />)}
          />
        </div> */}
            </div>
        );
    }
}
