import SmartInputText from '@components/input/SmartInputText';
import * as React from 'react';

export interface IOwnPhoneFormProps {
}

export default class OwnPhoneForm extends React.Component<IOwnPhoneFormProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      phone: localStorage.getItem("KeyyoNumber")
    }
  }
  public render() {
    return (
      <div className="mb-3">
        <SmartInputText label={"Numéro de votre poste"} value={this.state.phone} onChange={(phone) => {
          this.setState({phone});
          localStorage.setItem("KeyyoNumber", phone);
        }} />
      </div>
    );
  }
}
