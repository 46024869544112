import Model from './Model';
import User from './User';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';

export default class VehiculeMileageHistory extends Model {
    public id: number | null;
    public vehicule_id: number;
    public mileage: number;
    public date: string;
    public user: User;

    static modelUrl = 'vehicule_mileage_histories';
    public modelName = 'vehicule_mileage_history';

    
    constructor(json: any) {
        super(json);
        this.id = json.id || null;
        this.vehicule_id = json.vehicule_id;
        this.mileage = json.mileage;
        this.date = json.date;
        
        this.user = json.user && new User(json.user)
    }
}