import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import DOMService from "@services/DOMService";
import React from "react";
import User from "src/models/User";
import ModelGrid from './ModelGrid';

export default class GodinLibGrid extends ModelGrid<User> {

  public formRoute = SidebarRoute.UserForm
  static gridName = "user"

  openSideBar(model: User){
    DOMService.openSidebar(SidebarRoute.SetUserGodinLib, {sectorId: model.sectorId, worker: model})
  }


  onRowClick = (model: User) => {
    this.openSideBar(model);
  }
  
  gridConfig = {
    headers: ["Nom", "Secteur", "Quartier", "Date"],
    body: [
      (user: User) => <b>{user.getFullName()}</b>,
      (user: User) => <div>{user.sector.name}</div>,
      (user: User) => user.quartier,
      (user: User) => <div>{(new Date(user.startRdv)).toLocaleDateString()} - {(new Date(user.endRdv)).toLocaleDateString()}</div>,
    ]
  }

  showCurrentYard = (e, user) => {
    e.stopPropagation();
    DOMService.openSidebar(SidebarRoute.YardShow, {id: user.currentYard.id})
  }

  multipleActions = [
      ];
  
  onEdit = null;
  onDelete = null;
  onClickNew = () => DOMService.openSidebar(SidebarRoute.SetUserGodinLib, {sectorId: null, worker: null});
}