import * as React from 'react';
import SidebarRouter from './SidebarRouter';
import DOMService from '@services/DOMService';

export default class SidebarLayout extends React.Component<{children, title, bottomArea?, onLeadingClick?, action?}, any> {

  constructor(props) {
    super(props)
    this.state = {
      error: null
    }
    DOMService.sidebarLayoutRef = this
  }

  render() {
    const {children, title, bottomArea, action} = this.props
    const {error} = this.state
    return (
      <>
        <div className="d-flex flex-column position-relative">
          <div className="min-vh-100 px-4 pt-3" style={{paddingBottom: "110px"}}>
            <div className="row-flex">
              {SidebarRouter.popable() && <i onClick={() => SidebarRouter.pop()} className="fas fa-chevron-left pe-3 pointer"></i>}
              <div className="fw-bold text-xl">{title}</div>
              <div className="ms-auto">{action}</div>
              <div onClick={() => DOMService.closeSidebar()} className="pointer py-3 px-4 my-n3 me-n4 row-center text-secondary" >
                <i className="far fa-times text-xl"></i>
              </div>
            </div>
            <div className="sidebar-divider"></div>
            {children}
          </div>
          {error && <div onClick={() => this.setState({error: null})} className="error-sidebar-container">
            <div className="error-sidebar">{error}</div>
          </div>}
        </div>
        {bottomArea && <div style={DOMService.sidebarRef.state.style} className={`bg-white bottom-0 mt-auto position-fixed w-100`}>
          {bottomArea}
        </div>}
      </>
    );
  }
}
