import { sessionBloc } from '@bloc/SessionBloc';
import SidebarLayout from '@components/layout/SidebarLayout';
import * as React from 'react';
import dayjs from 'dayjs';
import User from '@models/User';
import UserSelect from '@components/modelSelect/UserSelect';
import EntityManager from '@services/EntityManager';
import ApiService from '@services/ApiService';

export interface IUserParamsPageProps {
  workerFrom: User; // This should be the user parameters you want to display,
  date,
  onSubmit
}

export default class TransferWorkerSlot extends React.Component<IUserParamsPageProps, { selectedWorker: User| null }> {

  constructor(props) {
    super(props);
    this.state = {
      selectedWorker: null, // This stores the selected value from the select input
    }
  }

  handleSelectChange = (event) => {
    this.setState({ selectedWorker: event.target.value });
  }

  handleSubmit = async () => {
    if(this.state.selectedWorker){
        if(window.confirm("Confirmer le transfert des rendez-vous a un autre agent ?")){
            const workerToId = this.state.selectedWorker.id
            const workerFromId = this.props.workerFrom.id
            const date = dayjs(this.props.date);
            const day = date.format('DD');   // Day of the month (e.g., "10")
            const month = date.format('MM'); // Month as a two-digit number (e.g., "10" for October)
            const year = date.format('YYYY');  // Full year (e.g., "2024")
            // You can add your validation or submit logic here
            await ApiService.post("intervention_slots_transfer/" + workerFromId, { workerToId, day, month, year})
            this.props.onSubmit()
        }
    }
  }

  formatDateString(dateString: string): string {
    // Replace non-ASCII characters and French-specific text with an English timezone equivalent
    const normalizedDateString = dateString.replace(/\(.*?\)/, '').trim();  // Remove anything inside parentheses

    // Use JavaScript Date to parse this specific format
    const parsedDate = new Date(normalizedDateString);  // Parse the cleaned-up string with JavaScript's Date

    // Check if the date is valid
    if (isNaN(parsedDate.getTime())) {
      return "Invalid Date"; // Handle invalid dates
    }

    // Return the formatted date string in "DD MMMM YYYY" format using dayjs
    return dayjs(parsedDate).format("DD MMMM YYYY");
  }

  public render() {
    const { workerFrom, date } = this.props;
    console.log(this.props)
    let parsedDate = dayjs(date).format("DD MMMM YYYY")

    return (
      <SidebarLayout title={"Transfert de rendez-vous"}>
        <div>
          {/* Displaying user parameters */}
          <div className='mb-4'>
            <h3>Information de l'utilisateur</h3>
            {workerFrom.getFullName()}
          </div>

          {/* Displaying the current date */}
          <div className='mb-4'>
            <h4>Date actuelle :</h4>
            <div className='fw-bold'>{parsedDate}</div>
          </div>

          {/* Select input and submit button */}
          <div className='mb-4'>
            <UserSelect
                                        value={this.state.selectedWorker}
                                        label="Sélectionner un agent"
                                        placeholder={this.state.selectedWorker ? (this.state.selectedWorker.firstname + " " + this.state.selectedWorker.lastname) : "Choisir un agent"}
                                        containerClassName="mb-3"
                                        params={{ scope: "onlyWorkers" }}
                                        onChange={(selected) => {
                                            this.setState(
                                                {
                                                    selectedWorker: selected || null
                                                });
                                        }}
                                    />
            <button 
              className='btn btn-primary'
              onClick={this.handleSubmit}
            >
              Valider
            </button>
          </div>
        </div>
      </SidebarLayout>
    );
  }
}
