import React, { Component } from 'react';
import AsyncButton from '@components/button/AsyncButton';
import SmartSelect from '@components/input/SmartSelect';
import UserSelect from '@components/modelSelect/UserSelect';
import SidebarLayout from '../layout/SidebarLayout';
import OptionService from "@services/OptionService";
import User from "@models/User";
import EntityManager from "@services/EntityManager";
import DOMService from "@services/DOMService";
import SmartDatePicker from "@components/input/SmartDatePicker";
import SmartInputText from '@components/input/SmartInputText';
import Sector from '@models/Sector';
import SectorSelect from '@components/modelSelect/SectorSelect';
import { worker } from 'cluster';

export interface SetUserGodinLibProps {
    sectorId: number | null,
    worker: User | null
}

export interface SetUserGodinLibState {
    selectedWorker: User | null;
    selectedQuartier: string | null;
    quartiersOption: { label: string; id: string }[];
    startSelectedDate: Date,
    endSelectedDate: Date,
    creneau1: number,
    creneau2: number,
    creneau3: number,
    creneau4: number,
    listSector: Sector[],
    selectedSector: Sector | null
}

class SetUserGodinLib extends Component<SetUserGodinLibProps, SetUserGodinLibState> {
    constructor(props: SetUserGodinLibProps) {
        super(props);
        let quartierOptions = []

        //OptionService.quartiers[props.sectorId].forEach((quartier) => { quartierOptions.push({ label: quartier, id: quartier }) })

        this.state = {
            listSector: [],
            selectedWorker: props.worker || null, // Default to an empty object if worker is undefined or null
            selectedQuartier: props.worker?.quartier || '', // Safely access quartier
            quartiersOption: quartierOptions,
            endSelectedDate: props.worker?.endRdv ? new Date(props.worker.endRdv) : new Date(), // Default to current date if endRdv is not available
            startSelectedDate: props.worker?.startRdv ? new Date(props.worker.startRdv) : new Date(), // Default to current date if startRdv is not available
            creneau1: props.worker?.creneau1 || 4, // Default to empty string if creneau1 is not available
            creneau2: props.worker?.creneau2 || 4,
            creneau3: props.worker?.creneau3 || 4,
            creneau4: props.worker?.creneau4 || 4,
            selectedSector: props.worker?.sector || null, // Default to null if sector is not available
        };
        
        // Load quartier only if quartier exists
        if (props.worker?.sectorId) {
            this.loadQuartier(props.worker?.sectorId);
        }
        

    }

    onSelectSector = async => {

    }

    loadQuartier = (id) => {
        OptionService.loadQuartier(id).then(() => { 
            let quartierOptions = [];
            OptionService.quartiers[id].forEach((quartier) => { 
                 quartierOptions.push({ label: quartier, id: quartier });  
            })
            this.setState({ quartiersOption: quartierOptions }) 
        })
    }

    onSubmit = async () => {
        const { selectedWorker, selectedQuartier, endSelectedDate, startSelectedDate, creneau1, creneau2, creneau3, creneau4, selectedSector } = this.state;
        selectedWorker.update({ quartier: selectedQuartier, sectorId: selectedSector.id, endRdv: endSelectedDate.toISOString().split('T')[0], startRdv: startSelectedDate.toISOString().split('T')[0], creneau1, creneau2, creneau3, creneau4 })
        await EntityManager.update(selectedWorker);
        DOMService.closeSidebar()
        window.location.reload()
    };

    render() {
        const { selectedWorker, selectedQuartier, quartiersOption, startSelectedDate, endSelectedDate, creneau1, creneau2, creneau3, creneau4, selectedSector } = this.state;
        return (
            <SidebarLayout
                title={"Assignation d'un utilisateur à un quartier"}
                bottomArea={<AsyncButton className="btn btn-primary w-100" loading={false} onClick={this.onSubmit}>Assigner</AsyncButton>}
            >
                {this.state.listSector &&
                    <>
                        {<>

                            <SectorSelect
                                label="Sélectionner un secteur"
                                placeholder={selectedSector ? selectedSector.name : "Sélectionner un secteur"}
                                value={selectedSector}
                                onChange={(selected) => { this.setState({ selectedSector: selected }); OptionService.loadQuartier(selected.id).then(() => { this.loadQuartier(selected.id)})}}
                            />
                        </>}

                        {selectedSector != null && 

                            <>
                                <div className="fadeInDown">
                                    <UserSelect
                                        value={selectedWorker}
                                        label="Sélectionner un agent"
                                        placeholder={selectedWorker ? (selectedWorker.firstname + " " + selectedWorker.lastname) : "Choisir un agent"}
                                        containerClassName="mb-3"
                                        params={{ scope: "onlyWorkers" }}
                                        onChange={(selected) => {
                                            this.setState(
                                                {
                                                    selectedWorker: selected || null, selectedQuartier: selectedSector.id === selected.sectorId && selected.quartier,
                                                    startSelectedDate: selectedSector.id === selected.sectorId ? new Date(selected.endRdv) : new Date(),
                                                    endSelectedDate: selectedSector.id === selected.sectorId ? new Date(selected.endRdv) : new Date(),
                                                    creneau1: selected.creneau1 ? selected.creneau1 : 4,
                                                    creneau2: selected.creneau2 ? selected.creneau2 : 4,
                                                    creneau3: selected.creneau3 ? selected.creneau3 : 4,
                                                    creneau4: selected.creneau4 ? selected.creneau4 : 4
                                                });
                                        }}
                                    />
                                    <SmartSelect
                                        label="Sélectionner un quartier"
                                        placeholder="Choisir un quartier"
                                        options={quartiersOption}
                                        value={selectedQuartier ? quartiersOption.find(o => o.id === selectedQuartier) : null}
                                        onChange={(selected) => this.setState({ selectedQuartier: selected?.id || null })}
                                    />
                                    <SmartDatePicker label="Du ?" containerClassName='mb-3' value={startSelectedDate} onChange={(selected) => this.setState({ startSelectedDate: selected })} />
                                    <SmartDatePicker label="Jusqu'au ?" containerClassName='mb-3' value={endSelectedDate} onChange={(selected) => this.setState({ endSelectedDate: selected })} />
                                    {
                                        selectedWorker && selectedWorker.sectorId && selectedWorker.sectorId !== selectedSector.id &&
                                        <>
                                            <br />
                                            <span>Attention cet employé est déjà assigné a un autre secteur.</span>
                                        </>

                                    }
                                    <div>Créneau 8-10h</div>
                                    <SmartInputText value={creneau1} onChange={(value) => this.setState({ creneau1: value || null })} placeholder={"Créneau 8-10h"} />
                                    <br />
                                    <div>Créneau 10-12h</div>
                                    <SmartInputText value={creneau2} onChange={(value) => this.setState({ creneau2: value || null })} placeholder={"Créneau 10-12h"} />
                                    <br />
                                    <div>Créneau 13-15h</div>
                                    <SmartInputText value={creneau3} onChange={(value) => this.setState({ creneau3: value || null })} placeholder={"Créneau 13-15h"} />
                                    <br />
                                    <div>Créneau 15-17h</div>
                                    <SmartInputText value={creneau4} onChange={(value) => this.setState({ creneau4: value || null })} placeholder={"Créneau 15-17h"} />
                                </div>
                            </>}
                    </>}



            </SidebarLayout>
        );
    }
}

export default SetUserGodinLib;
