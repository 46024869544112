import AdminModelsList from '@components/common/AdminModelsList';
import CustomerGrid from '@components/grid/CustomerGrid';
import * as React from 'react';
import Customer from '@models/Customer';
import MainLayout from '@components/layout/MainLayout';

export interface ICustomerPageProps {
}

export default class CustomerPage extends React.Component<ICustomerPageProps> {
  public render() {
    return (
      <MainLayout title="Clients">
        <div className="app-content">
          <AdminModelsList
            gridClass={CustomerGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: Customer}}
          />
        </div>
      </MainLayout>
    );
  }
}
