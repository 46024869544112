import React, { useState } from 'react';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IInjectedFormModelProps } from '@components/logic/FormModel';
import SidebarLayout from '@components/layout/SidebarLayout';
import Vehicule from '@models/Vehicule';
import User from '@models/User';
import AsyncButton from '@components/button/AsyncButton';
import MultiUserSelect from "@components/form/MultiUserSelect";
import SelectInput from './SelectInput';
import ConfirmationModal from '../modal/ConfirmationModal';
import FileUpload from '@components/form/FileUpload';
import FileList from '@components/vehicule/FileList';
import VehiculeFile from '@models/VehiculeFile';
import apiService from "@services/ApiService";
import EntityManager from '@services/EntityManager';
import SmartSwitch from '@components/input/SmartSwitch';

export interface IVehiculeFormProps extends IInjectedFormModelProps<Vehicule> {
    onClose: () => void;
}

const VehiculeForm: React.FC<IVehiculeFormProps> = ({ model, submitting, onClose, submit }) => {
    const [showModal, setShowModal] = useState(false);
    const [initialVehicule] = useState({ ...model });
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<VehiculeFile[]>(model.files || []);

    const ensureUsersAreInstancesOfUser = () => {
        model.users = model.users.map(user => (user instanceof User ? user : new User(user)));
    };

    const handleConfirm = async () => {
        
        try {
            if (model.users && model.users.length > 0) {
                model.users = model.users.map(user => user);
            }
            console.log(model)
            model.mileageHistories = []

            let modelId = null
            if(model.id){
                let data = await EntityManager.update(model)
                modelId = data.model.id
            }else{
                let data = await EntityManager.create(model)
                modelId = data.model.id
            }

            if (modelId) {
                await uploadFiles(modelId);
            }

            setShowModal(false);
            window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la soumission :', error);
        }
    };

    const uploadFiles = async (vehiculeId: number) => {
        if (uploadedFiles.length === 0) return;

        const formData = new FormData();
        uploadedFiles.forEach(file => {
            formData.append('files[]', file);
        });

        try {
            const response = await apiService.post(`vehicules/${vehiculeId}/files`, formData);

            const newFiles = response.data.files;
            if (Array.isArray(newFiles)) {
                setFiles(prevFiles => [...prevFiles, ...newFiles]);
            } else {
                console.error('Les fichiers uploadés ne sont pas disponibles dans la réponse.');
            }

            setUploadedFiles([]);
        } catch (error) {
            console.error('Erreur lors de l\'upload des fichiers :', error);
        }
    };

    const handleFileUploadSuccess = (files: File[]) => {
        setUploadedFiles(files);
    };

    const handleFileDeleted = (fileId: number) => {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
    };

    const handleSubmit = () => {
        setShowModal(true);
    };

    ensureUsersAreInstancesOfUser();

    return (
        <SidebarLayout
            title={model.id ? "Modification d'un véhicule" : "Création d'un véhicule"}
            bottomArea={
                <AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={handleSubmit}>
                    {model.id ? "Modifier le véhicule" : "Créer le véhicule"}
                </AsyncButton>
            }
        >
            <div className="fadeInDown">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <SmartInputText
                            model={model}
                            name="licensePlate"
                            label="Immatriculation"
                            containerClassName="mb-3"
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <SmartInputText
                            model={model}
                            name="mileage"
                            label="Kilométrage"
                            containerClassName="mb-3"
                            type="number"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <SmartInputText
                            model={model}
                            name="badge"
                            label="Numéro de badge"
                            containerClassName="mb-3"
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <SmartInputText
                            model={model}
                            name="card"
                            label="Numéro de carte"
                            containerClassName="mb-3"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-12">
                        <MultiUserSelect model={model} placeholder="Choisir des utilisateurs" />
                    </div>
                </div>
                
                <div className="row">
                <div className="col-md-6 col-12">
                        <SmartInputText
                            model={model}
                            name="owner"
                            label="Propriétaire"
                            containerClassName="mb-3"
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <SelectInput
                            model={model}
                            name="status"
                            label="Statut"
                            containerClassName="mb-3"
                            options={[
                                { value: 'Disponible', label: 'Disponible' },
                                { value: 'en utilisation', label: 'En utilisation' },
                                { value: 'en maintenance', label: 'En maintenance' },
                                { value: 'réservé', label: 'Réservé' },
                                { value: 'hors service', label: 'Hors service' }
                            ]}
                            onChange={(value) => { model.status = value; }}
                        />
                    </div>
                </div>
                <SmartSwitch id="cleaningContract" model={model} name="cleaningContract" label="Contrat d'entretien" containerClassName="mb-3" />

                <div className="row">
                    <div className="col-12">
                        <FileUpload
                            model={model}
                            fieldName="files"
                            label="Télécharger les fichiers"
                            onUploadSuccess={handleFileUploadSuccess}
                        />
                    </div>
                </div>
                {files && files.length > 0 && (
                    <div className="row mt-4">
                        <div className="col-12">
                            <FileList
                                files={files}
                                vehiculeId={model.id}
                                onFileDeleted={handleFileDeleted}
                            />
                        </div>
                    </div>
                )}
            </div>

            <ConfirmationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleConfirm={handleConfirm}
                vehicule={model}
                initialVehicule={initialVehicule}
            />
        </SidebarLayout>
    );
};

export default formModel<IVehiculeFormProps>(VehiculeForm, { modelClass: Vehicule });