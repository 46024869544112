import dayjs from 'dayjs';
import * as React from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import SmartInput2, { ISmartInputProps } from './SmartInput2';

export interface ISmartDatePickerProps extends ISmartInputProps {
}

function SmartDatePicker (props: ISmartDatePickerProps) {

  const {
    containerClassName,
    manager,
    className,
    autoFocus
  } = props;
  return (
    <div className={containerClassName}>
      { manager.renderLabel()}
      {
        manager.wrapWithIcon(
          <DayPickerInput
            value={props.value}
            inputProps={{
              id: props.id,
              required:props.required,
              autoFocus: autoFocus,
              className: className
            }}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            style={props.style}
            onDayChange={(day, i, u) => {
              // let arr = u.state.value?.split(" ")?.pop()?.split(":");
              // if (arr) {
              //   let hour = arr[0]
              //   let min = arr[1]
              //   if (!day) day = props.value
              //   if (day) {
              //     day.setHours(hour, min);
              //   }
              // }
              manager.updateState(day);
            }}
            formatDate={(date) => date ? dayjs(date).format("DD/MM/YYYY") : null}
          />
        )
      }
      {props.children}
    </div>
  );
}

export default SmartInput2<ISmartDatePickerProps, any>(SmartDatePicker)