import * as React from 'react';
import classNames from 'classnames';

export interface ILightTabsProps {
  tabTitles: string[];
  tabContents:  Array<() => JSX.Element>;
  className?: string;
  headerStyle?
}

export interface ILightTabsState {
  activeTab: number
}

export default class LightTabs extends React.Component<ILightTabsProps, ILightTabsState> {

  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0
    }
  }

  public toggle = (tab) => {
    if (this.state.activeTab !== tab) this.setState({activeTab: tab});
  }

  public indicatorStyle = () => {
    let tabAmount = this.props.tabTitles.length;
    let style: any = {left: "0"};
    style.left = `${(100 / tabAmount * this.state.activeTab).toFixed(2)}%`;
    style.width = `${(100 / tabAmount).toFixed(2)}%`;
    return style;
  }

  public render() {
    const { activeTab } = this.state;
    const { tabTitles, tabContents, className, headerStyle } = this.props;
    return (
      <div className={"light-tabs " + (className || "")}>
        <div className='border-bottom border-light'>
        <div className="tabs" style={headerStyle}>
          { tabTitles.map((name, index) => (
            <div
              key={name}
              className={classNames({"tab-link active": activeTab === index, "tab-link": true})}
              onClick={() => { this.toggle(index); }}
            >{name}</div>
          ))}
          <div className="tab-indicator" style={this.indicatorStyle()}>
            <div className="i-content"></div>
          </div>
        </div>

        </div>
        <div className="tab-content">
          {tabContents[activeTab]()}
        </div>
      </div>
    );
  }
}
