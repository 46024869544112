import InterventionSlot from '@models/InterventionSlot';
import Contact from '@models/Contact';
import Option from '@models/Option';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import { OptionAttribute } from './core/OptionAttribute';
import Model from "./Model";
import User from './User';

export default class Exchange extends Model {

  public comment: string
  public type: Option
  public startAt: string
  public endAt: string
  public callref
  public data: any
  public contact: Contact
  public interventionSlot: InterventionSlot
  public createdBy: User

  static modelUrl = "exchanges";
  public modelName = "exchange";

  public attributes = new AttributesCollection({
    comment: new Attribute("comment"),
    callref: new Attribute("callref"),
    type: new OptionAttribute("type", {optionType: "exchange_type"}),
    startAt: new DateAttribute("startAt"),
    endAt: new DateAttribute("endAt"),
    data: new Attribute("data"),
    interventionSlot: new ModelAttribute("interventionSlot", InterventionSlot, {submitObject: true}),
    contact: new ModelAttribute("contact", Contact),
    createdBy: new ModelAttribute("createdBy", User),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  getIcon() {
    if (!this.type) return;
    if (this.type.key === "exchange_type_call") return "fa-phone-arrow-up-right"
    if (this.type.key === "exchange_type_received") return "fa-phone-arrow-down-left"
    if (this.type.key === "exchange_type_wrong") return "fa-phone-xmark"
    if (this.type.key === "exchange_type_email_sent") return "fa-paper-plane"
    if (this.type.key === "exchange_type_sms_sent") return "fa-paper-plane"
    if (this.type.key === "exchange_type_messaging") return "fa-voicemail"
    return ""
  }

  getTitle() {
    if (this.type.key === "exchange_type_email_sent") return "Email envoyé par " + this.createdBy.getFullName()
    if (this.type.key === "exchange_type_sms_sent") return "SMS envoyé par " + this.createdBy.getFullName()
    return "Echange"
  }


}