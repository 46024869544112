import Model from "./Model";
import AttributesCollection from "./core/AttributeCollection";
import Attribute from './core/Attribute';

export default class VehiculeMaintenance extends Model {
    public vehicule_id: number;
    public nextMaintenanceAt: string | null;
    public lastMaintenanceAt: string | null;
    public mileage: number;

    static modelUrl = "vehicule_maintenances";
    public modelName = "vehiculeMaintenance";

    public attributes = new AttributesCollection({
        vehicule_id: new Attribute("vehicule_id"),
        nextMaintenanceAt: new Attribute("nextMaintenanceAt"),
        lastMaintenanceAt: new Attribute("lastMaintenanceAt"),
        mileage: new Attribute("mileage"),
    });

    constructor(json: any) {
        super(json);
        this.nextMaintenanceAt = json.nextMaintenanceAt || null;
        this.lastMaintenanceAt = json.lastMaintenanceAt || null;
        this.fillAttributes(json);
    }
}