import React, { Component } from 'react';
import AsyncButton from '@components/button/AsyncButton';
import SmartSelect from '@components/input/SmartSelect';
import UserSelect from '@components/modelSelect/UserSelect';
import SidebarLayout from '../layout/SidebarLayout';
import OptionService from "@services/OptionService";
import User from "@models/User";
import EntityManager from "@services/EntityManager";
import DOMService from "@services/DOMService";
import SmartDatePicker from "@components/input/SmartDatePicker";
import SmartInputText from '@components/input/SmartInputText';

export interface UserToQuartierProps {
    sectorId,
    worker
}

export interface UserToQuartierState {
    selectedWorker: User | null;
    selectedQuartier: string | null;
    quartiersOption: { label: string; id: string }[];
    sectorId: number,
    startSelectedDate: Date,
    endSelectedDate: Date,
    creneau1: number,
    creneau2: number,
    creneau3: number,
    creneau4: number,
}

class UserToQuartier extends Component<UserToQuartierProps, UserToQuartierState> {
    constructor(props: UserToQuartierProps) {
        super(props);
        let quartierOptions = []
        OptionService.quartiers[props.sectorId].forEach((quartier) => { quartierOptions.push({ label: quartier, id: quartier }) })

        this.state = {
            sectorId: props.sectorId,
            selectedWorker: null,
            selectedQuartier: null,
            quartiersOption: quartierOptions,
            endSelectedDate: new Date(),
            startSelectedDate: new Date(),
            creneau1: 4,
            creneau2: 4,
            creneau3: 4,
            creneau4: 4,
        };
    }

    onSubmit = async () => {
        const { selectedWorker, selectedQuartier, sectorId, endSelectedDate, startSelectedDate, creneau1, creneau2, creneau3, creneau4 } = this.state;
        selectedWorker.update({ quartier: selectedQuartier, sectorId: sectorId, endRdv: endSelectedDate.toISOString().split('T')[0], startRdv: startSelectedDate.toISOString().split('T')[0], creneau1, creneau2, creneau3, creneau4 })
        await EntityManager.update(selectedWorker);
        DOMService.closeSidebar()
    };

    render() {
        const { selectedWorker, selectedQuartier, quartiersOption, sectorId, startSelectedDate, endSelectedDate, creneau1, creneau2, creneau3, creneau4 } = this.state;
        return (
            <SidebarLayout
                title={"Assignation d'un utilisateur à un quartier"}
                bottomArea={<AsyncButton className="btn btn-primary w-100" loading={false} onClick={this.onSubmit}>Assigner</AsyncButton>}
            >
                <div className="fadeInDown">
                    <UserSelect
                        value={selectedWorker}
                        label="Sélectionner un agent"
                        placeholder={selectedWorker ? (selectedWorker.firstname + " " + selectedWorker.lastname) : "Choisir un agent"}
                        containerClassName="mb-3"
                        params={{ scope: "onlyWorkers" }}
                        onChange={(selected) => {
                            this.setState(
                                { 
                                    selectedWorker: selected || null, selectedQuartier: sectorId === selected.sectorId && selected.quartier,
                                    startSelectedDate: sectorId === selected.sectorId ? new Date(selected.endRdv) : new Date(),
                                    endSelectedDate: sectorId === selected.sectorId ? new Date(selected.endRdv) : new Date(), 
                                    creneau1: selected.creneau1 ? selected.creneau1: 4,
                                    creneau2: selected.creneau2 ? selected.creneau2: 4,
                                    creneau3: selected.creneau3 ? selected.creneau3: 4,
                                    creneau4: selected.creneau4 ? selected.creneau4: 4 
                                });
                        }}
                    />
                    <SmartSelect
                        label="Sélectionner un quartier"
                        placeholder="Choisir un quartier"
                        options={quartiersOption}
                        value={selectedQuartier ? quartiersOption.find(o => o.id === selectedQuartier) : null}
                        onChange={(selected) => this.setState({ selectedQuartier: selected?.id || null })}
                    />
                    <SmartDatePicker label="Du ?" containerClassName='mb-3' value={startSelectedDate} onChange={(selected) => this.setState({ startSelectedDate: selected })} />
                    <SmartDatePicker label="Jusqu'au ?" containerClassName='mb-3' value={endSelectedDate} onChange={(selected) => this.setState({ endSelectedDate: selected })} />
                    {
                        selectedWorker && selectedWorker.sectorId && selectedWorker.sectorId !== sectorId &&
                        <>
                            <br />
                            <span>Attention cet employé est déjà assigné a un autre secteur.</span>
                        </>

                    }
                    <div>Créneau 8-10h</div>
                    <SmartInputText value={ creneau1 } onChange={ (value) => this.setState({ creneau1: value || null }) }  placeholder={"Créneau 8-10h"} />
                    <br/>
                    <div>Créneau 10-12h</div>
                    <SmartInputText value={ creneau2 } onChange={ (value) => this.setState({ creneau2: value || null }) }  placeholder={"Créneau 10-12h"} />
                    <br/>
                    <div>Créneau 13-15h</div>
                    <SmartInputText value={ creneau3 } onChange={ (value) => this.setState({ creneau3: value || null }) }  placeholder={"Créneau 13-15h"} />
                    <br/>
                    <div>Créneau 15-17h</div>
                    <SmartInputText value={ creneau4 } onChange={ (value) => this.setState({ creneau4: value || null }) }  placeholder={"Créneau 15-17h"} />
                </div>
            </SidebarLayout>
        );
    }
}

export default UserToQuartier;
