import * as React from 'react';
import MainLayout from '@components/layout/MainLayout';
import ApiService from "@services/ApiService";
import AgendaWorkerStats from '@components/management/AgendaWorkerStats';
import WorkerAgenda from "@components/calendar/WorkerAgenda";
import ExchangeStats from "@components/management/ExchangeStats";
import EntityManager from "@services/EntityManager";
import List from "@models/List";
import User from "@models/User";
import UserState from "@models/UserState";
import Model from "@models/Model";
import Yard from "@models/Yard";

export interface ICalendarTestPageProps {
}

interface State {
    calendar: any[];
    initialMode: number;
    currentDate: Date;
    subMenu: number;
    workerYards: any;
    mode: number;
    states: any;
    employees: any;
    modification: Object,
    yards: any[],
    selectedYard: any,
    yardInputValue: any,
    yardInputMenuIsOpen: boolean,
    searchUser: string,
    selectedYardFilter: any,
    yardFilterInputValue: any,
    yardFilterInputMenuIsOpen: boolean,

    userList: any
    selectedList: any
    listInputMenuIsOpen: boolean,
    listInputValue: string
}

export default class RhPage extends React.Component<ICalendarTestPageProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            calendar: null,
            initialMode: null,
            currentDate: new Date(),
            subMenu: 1,
            workerYards: null,
            userList: [],
            employees: null,
            states: null,
            mode: 1,
            yards: [],
            modification: {},
            selectedYard: {"name": "Sélectionner un chantier", "id": -1},
            yardInputValue: "",
            yardInputMenuIsOpen: false,
            searchUser: "",
            selectedYardFilter: {"name": "Tous", id: "Tous"},
            yardFilterInputValue: "",
            yardFilterInputMenuIsOpen: false,

            selectedList: {"name": "Tous", id: "Tous"},
            listInputMenuIsOpen: false,
            listInputValue: ""
        }
        this.refreshCalendar = this.refreshCalendar.bind(this)
        this.loadCalendar = this.loadCalendar.bind(this)
    }

    refreshCalendar(date) {
        this.loadCalendar(date)
    }

    componentDidMount() {
        this.loadCalendar(new Date());
        this.loadAgenda()
    }

    async loadCalendar(date: Date) {
        let result = await ApiService.get("user_agenda_state", {month: date.getMonth() + 1, year: date.getFullYear()});
        let calendar = result.data[0]
        let workerYards = result.data[1]
        let array_calendar = [];
        let workerYardsJson = {}

        for (let i in calendar) {
            array_calendar.push(calendar[i]);
        }

        for (let i in workerYards) {
            workerYardsJson[workerYards[i].yard_id] = workerYards[i].user_ids.split(",")
        }
        this.setState({calendar: array_calendar, currentDate: date, "workerYards": workerYardsJson}, () => {
            this.forceUpdate();
        });
    }

    loadAgenda(){
        this.loadStateOption();
        this.loadEmployees();
        this.loadYard();
        this.loadList();
    }

    async loadList() {
        let data =
            await EntityManager.get(List, {
                path: 'getListWithUserId',
            })
        this.setState({userList: data.data})
    }

    async loadEmployees() {
        let result = await EntityManager.allWithPaginate(User, {params: {scope: "onlyEmployee"}})
        this.setState({employees: result.models})
    }

    async loadStateOption() {
        let result = await EntityManager.all(UserState, {})
        let states = {}
        result.data.forEach((state) => {
            states[state.id] = state
        })
        this.setState({states: states, mode: result.data[0].id})
    }

    async loadYard() {
        let yards: Model[] = (await EntityManager.all(Yard, {})).models;
        this.setState({yards: yards})
        //Afficher name
        //id
        //closed = false
    }

    updateState(updatedState: Partial<State>) {
        this.setState(prevState => ({ ...prevState, ...updatedState }));
    }



    public render() {
        return (
            <MainLayout title="Gestion">
                <div className="app-content">
                    <div style={{display: "flex", justifyContent: "center", gap: "10px", height: "5%"}}>
                        <button onClick={() => this.setState({subMenu: 1})}
                                className={"btn btn-primary"}>Agenda
                        </button>
                        <button onClick={() => this.setState({subMenu: 2})}
                                className={"btn btn-primary"}>Statistique travailleur
                        </button>
                        <button onClick={() => this.setState({subMenu: 3})}
                                className={"btn btn-primary"}>Statistique prise de rendez vous
                        </button>
                    </div>
                    {this.state.calendar &&
                        <>
                            {this.state.subMenu === 1 &&
                                <WorkerAgenda
                                    workerYards={this.state.workerYards}
                                    date={this.state.currentDate}
                                    currentDate={this.state.currentDate}
                                    mode={this.state.mode}
                                    states={this.state.states}
                                    employees={this.state.employees}
                                    yards={this.state.yards}
                                    selectedYard={this.state.selectedYard}
                                    yardInputValue={this.state.yardInputValue}
                                    yardInputMenuIsOpen={this.state.yardInputMenuIsOpen}
                                    searchUser={this.state.searchUser}
                                    selectedYardFilter={this.state.selectedYardFilter}
                                    yardFilterInputValue={this.state.yardFilterInputValue}
                                    yardFilterInputMenuIsOpen={this.state.yardFilterInputMenuIsOpen}
                                    userList={this.state.userList}
                                    selectedList={this.state.selectedList}
                                    listInputMenuIsOpen={this.state.listInputMenuIsOpen}
                                    listInputValue={this.state.listInputValue}
                                    updateState={this.updateState.bind(this)}
                                    calendar={this.state.calendar} refreshCalendar={this.refreshCalendar}/>
                            }
                            {this.state.subMenu === 2 && <AgendaWorkerStats date={this.state.currentDate} refreshDate={this.refreshCalendar}/>}
                            {this.state.subMenu === 3 && <ExchangeStats date={this.state.currentDate}/>}
                        </>

                    }
                </div>
            </MainLayout>
        );
    }
}
