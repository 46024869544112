import Model from 'src/models/Model';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Customer from './Customer';
import Interlocutor from './Interlocutor';
import SectorReservationInfo from './SectorReservationInfo';

export default class Sector extends Model {

  public name: string
  public contactsCount: string
  public senderEmail: string
  public customer: Customer
  public interlocutors: Interlocutor[]
  public sectorReservationInfo: SectorReservationInfo
  public visible: boolean

  static modelUrl = "sectors";
  public modelName = "sector";

  public attributes = new AttributesCollection({
    name: new Attribute("name"),
    senderEmail: new Attribute("senderEmail"),
    contactsCount: new Attribute("contactsCount"),
    customer: new ModelAttribute("customer", Customer),
    interlocutors: new ArrayModelAttribute("interlocutors", Interlocutor),
    sectorReservationInfo: new ModelAttribute("sectorReservationInfo", SectorReservationInfo, {submitObject: false}),
    visible: new Attribute("visible"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}