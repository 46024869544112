import { sessionBloc } from '@bloc/SessionBloc';
import KeyyoService from '@services/KeyyoService';
import classNames from 'classnames';
import * as React from 'react';
import CrmRouter from 'src/CrmRouter';
import Page403 from 'src/pages/Page403';
import Navbar from './Navbar';

export interface ICRMLayoutProps {
}

export default class CRMLayout extends React.Component<ICRMLayoutProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      minimize: false
    }
  }

  componentDidMount(): void {
    KeyyoService.start()
  }

  public render() {
    if (!sessionBloc.isAdmin() && !sessionBloc.isSecretary() && !sessionBloc.isOrdonnanceur() && !sessionBloc.isNationalResponsible()) return <Page403/>
    return (
      <div className={classNames({"base-app": true, "minimize": this.state.minimize})}>
        <Navbar router={CrmRouter()} minimize={this.state.minimize} onMinimize={() => this.setState({minimize: !this.state.minimize}, () => setTimeout(() => this.setState({}), 500))} />
        {this.props.children}
      </div>
    )
  }
}
