import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import SidebarLayout from '@components/layout/SidebarLayout';
import SidebarRouter, { SidebarRoute } from '@components/layout/SidebarRouter';
import ContactSelect from '@components/modelSelect/ContactSelect';
import UserSelect from '@components/modelSelect/UserSelect';
import InterventionSlot from '@models/InterventionSlot';
import * as React from 'react';

export interface IUserSendPageProps {
  worker
}

export default class UserSendPage extends React.Component<IUserSendPageProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      slot: null
    }
  }

  onSlotClick(slot: InterventionSlot) {
    this.setState({slot})
  }

  render() {
    const {worker} = this.props
    const { contact, slot} = this.state
    return (
      <SidebarLayout
        title={"Envoyer un sms à un agent"}
        bottomArea={<button className="btn btn-primary w-100 btn-lg" onClick={() => SidebarRouter.push(SidebarRoute.SendPage, {type: "sms", contact, to: worker.portable, dateRdv: slot?.startAt})} >Suivant</button>}
      >
        <div className="fadeInDown">
          <UserSelect value={worker} onChange={(worker) => this.setState({worker})} label="Destinataire" placeholder="Choisir un agent" containerClassName="mb-3" params={{scope: "onlyWorkers"}}/>
          <ContactSelect value={contact} onChange={(contact) => this.setState({contact})} name="contact" placeholder="Choisir un contact" label="Concerne un contact" containerClassName="mb-3" />
          {contact && <ContactSlotCalendar
            add={slot}
            parentModel={contact}
            params={{"user_id": worker.id}}
            onSlotClick={(_) => this.onSlotClick(_)}
          />}
        </div>
      </SidebarLayout>
    );
  }
}
