import ApiService from '@services/ApiService';
import axios from 'axios';
import dayjs from 'dayjs';
import * as React from 'react';
import env from 'src/config/env';

export interface IJobSectionProps {
}

export default class JobSection extends React.Component<IJobSectionProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: null
    }
  }


  componentDidMount(): void {
    this.load();
  }

  async load() {
    let res = await ApiService.get("dashboard/jobs");
    this.setState({data: res.data})
  }

  renderStatus(status) {
    if (status == "processed") return <div className='bg-success text-white py-2 px-3 rounded-2'>Terminé</div>
    if (status == "pending") return <div className='bg-warning text-white py-2 px-3 rounded-2'>En cours</div>
    if (status == "failed") return <div className='bg-danger text-white py-2 px-3 rounded-2'>En échec</div>
    return <></>
  }

  renderName(name) {
    if (name == "App\\Jobs\\ImportJob") return <div className='fw-bold'>Import d'intervention</div>
    if (name == "App\\Jobs\\ContactImportJob") return <div className='fw-bold'>Import de contact</div>
    return <></>
  }

  public render() {
    const {data} = this.state;
    if (!data) return <></>
    return (
      <div>
        <div>Activité des imports</div>
        {data.jobs.map(j => (
          <div className='mb-3'>
            <div className="row-flex">
              {this.renderName(j.content.name)}
              <div className='ms-auto me-4'>
                {this.renderStatus(j.content.status)}
              </div>
              <div>
                {dayjs(j.created_at).format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
            {j.content.status == "failed" && <div className='py-2 text-danger'>
              {j.content.exception.message}
            </div>}
          </div>
        ))}
        {/* <div>Import terminé</div>
        {data.job.map(j => (
          <div>

          </div>
        ))}
        <div>Import en échec</div>
        {data.job.map(j => (
          <div>

          </div>
        ))} */}
      </div>
    );
  }
}
