import AsyncButton from '@components/button/AsyncButton';
import AdvancedContactSelect from '@components/crm/AdvancedContactSelect';
import SmartDatePicker from '@components/input/SmartDatePicker';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import formModel, { IInjectedFormModelProps } from '@components/logic/FormModel';
import Alert from '@models/Alert';
import Contact from '@models/Contact';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';
import MultiUserSelect from './MultiUserSelect';

export interface IAlertYardModalProps {
}

class AlertForm extends React.Component<IAlertYardModalProps & IInjectedFormModelProps<Alert>> {

  // constructor(props) {
  //   super(props);
    
  // }

  async inactivate() {
    this.props.model.active = false;
    await EntityManager.update(this.props.model, {only: ["active"]});
    this.props.onSubmit(this.props.model);
  }
  
  onContactSelected(contact: Contact) {
    this.props.model.contact = contact;
    this.setState({});
  }

  delete = async () => {
    DOMService.setToaster(<div className="p-3">
      <div>Etes vous sur  de vouloir supprimer cette alerte ?</div>
      <div className="row-center mt-3">
        <div className="btn btn-light mx-2">Annuler</div>
        <div onClick={() => {
      EntityManager.delete(this.props.model).then(() => {
        DOMService.closeModal();
        this.props.onSubmit(this.props.model);
      });
    }} className="btn btn-danger mx-2">Supprimer</div>
      </div>
    </div>);
    
  }

  public render() {
    const {model, submitting, submit} = this.props;
    return (
      <SidebarLayout
        title={model.id ? "Modification d'une alerte" : "Création d'une alerte"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{model.id ? "Modifier l'alerte" : "Créer l'alerte"}</AsyncButton>}
        action={<div>
          {model.id && <div onClick={this.delete} className="my-n2 small-btn-icon">
            <i className="fas fa-trash"></i>
          </div>}
        </div>}
      >
        <div className="fadeInDown">
          { model.id && <button onClick={() => this.inactivate()} className='btn btn-danger mb-4'>Désactiver l'alerte</button>}
          {/* <SmartDatePicker model={model} name={"startAt"} label="M'alerter quand ?" containerClassName="mb-3"/> */}
          {model.startAt ? <div className="fw-bold mb-3">
            {dayjs(model.startAt).format("DD/MM/YYYY HH:mm")}
          </div> : 
          <SmartDatePicker model={model} label="Quand ?" name="startAt" containerClassName='mb-3' />
          }
          <SmartInputTextarea model={model} name="message" label="Message" containerClassName="mb-3" rows={6} />
          <AdvancedContactSelect contact={model.contact} onSelect={(contact) => this.onContactSelected(contact)} className="mb-3" />
          <MultiUserSelect model={model} placeholder="Qui alerter ?" />
          {/* <UserSelect model={model} name="to" placeholder="Choisir un utilisateur" label="Qui alerter ?" containerClassName="mb-3" /> */}
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IInjectedFormModelProps<Alert>>(AlertForm, {modelClass: Alert})