import User from './User';
import VehiculeMileageHistory from "@models/VehiculeMileageHistory";
import VehiculeMaintenance from "@models/VehiculeMaintenance";
import VehiculeFile from '@models/VehiculeFile';
import Model from "./Model";
import AttributesCollection from "./core/AttributeCollection";
import Attribute from './core/Attribute';
import ArrayModelAttribute from './core/ArrayModelAttribute';

export default class Vehicule extends Model {
    public licensePlate: string;
    public mileage: number;
    public users: User[];
    public mileageHistories: VehiculeMileageHistory[];
    public maintenances: VehiculeMaintenance[] = [];
    public files: VehiculeFile[] = [];
    public status: string;
    public card: string;
    public badge: string;
    public cleaningContract: boolean;
    public owner: string

    static modelUrl = "vehicules";
    public modelName = "vehicule";

    public attributes = new AttributesCollection({
        licensePlate: new Attribute("licensePlate"),
        mileage: new Attribute("mileage"),
        users: new ArrayModelAttribute("users", User),
        mileageHistories: new Attribute("mileageHistories"),
        maintenances: new Attribute("maintenances"),
        files: new Attribute("files"),
        status: new Attribute("status"),
        card: new Attribute("card"),
        badge: new Attribute("badge"),
        cleaningContract: new Attribute("cleaningContract"),
        owner: new Attribute("owner"),
    });

    constructor(json) {
        super(json);
        this.fillAttributes(json);
        this.users = (json.users || []).map(user => new User(user));
        this.maintenances = (json.maintenances || []).map(maintenance => new VehiculeMaintenance(maintenance));
        this.files = (json.files || []).map(file => new VehiculeFile(file));
    }
}