import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import CustomerYardGrid from '@components/grid/CustomerYardGrid';
import MainLayout from '@components/layout/MainLayout';
import Yard from '@models/Yard';
import * as React from 'react';
import CustomerSectorsGrid from "@components/grid/CustomerSectorsGrid";
import Sector from "@models/Sector";

export interface IClientAreaPageProps {
}

export default class ClientSectorsPage extends React.Component<IClientAreaPageProps> {
    public render() {
        return (
            <MainLayout title="Secteurs">
                <div className="app-content">
                    <AdminModelsList
                        gridClass={CustomerSectorsGrid}
                        options={{ loadOnReady: true, paginate: true, modelClass: Sector, params: {
                                scopes: [
                                    JSON.stringify({name: "shared", param: sessionBloc.getUserId()})
                                ]
                            }}}
                    />
                </div>
            </MainLayout>
        );
    }
}
