import React, { useEffect, useState } from 'react';
import VehiculeMileageHistory from '@models/VehiculeMileageHistory';
import EntityManager from '@services/EntityManager';

interface MileageTabProps {
    vehicule: {
        mileageHistories: VehiculeMileageHistory[];
    };
}

const MileageTab: React.FC<MileageTabProps> = ({ vehicule }) => {
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [mileageHistoryLoaded, setMileageHistoryLoaded] = useState<null | VehiculeMileageHistory[]>(null);

    useEffect(() => {
        const loadVehiculeHistories = async () => {
            let vehiculeHistory: VehiculeMileageHistory[] = [];

            // Utilisez for...of pour utiliser await correctement
            console.log(vehicule.mileageHistories)
            for (const history of vehicule.mileageHistories) {
                try {
                    let result = await EntityManager.get(VehiculeMileageHistory, { path: history.id.toString(), with: ["user"] });
                    vehiculeHistory.push(result.data.model);
                } catch (error) {
                    console.error("Erreur lors du chargement des données : ", error);
                }
            }

            // Mettre à jour l'état après avoir chargé les données
            setMileageHistoryLoaded(vehiculeHistory);
        };
        console.log(vehicule)
        if (vehicule.mileageHistories && vehicule.mileageHistories.length > 0) {
            loadVehiculeHistories();
        }
    }, [vehicule.mileageHistories]);

    if (!vehicule.mileageHistories || vehicule.mileageHistories.length === 0) {
        return <div className="alert alert-warning">Aucun historique de kilométrage disponible.</div>;
    }

    const sortedHistories = mileageHistoryLoaded ? mileageHistoryLoaded.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    }) : [];

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };



    return (
        <div className="table-responsive mt-4">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th onClick={toggleSortOrder} style={{ cursor: 'pointer' }} className="text-primary">
                            Date {sortOrder === 'asc' ? '▲' : '▼'} <span className="text-muted">(Cliquez pour trier)</span>
                        </th>
                        <th>Kilométrage</th>
                        <th>Utilisateur</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedHistories.map((entry, index) => (
                        <tr key={index} className="align-middle">
                            {console.log(entry.user)}
                            <td>{new Date(entry.createdAt).toLocaleDateString()}</td>
                            <td>{entry.mileage.toLocaleString()} km</td>
                            <td>{entry.user ? entry.user.firstname + " " + entry.user.lastname : ""}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MileageTab;