import {sessionBloc} from '@bloc/SessionBloc';
import {SidebarRoute} from '@components/layout/SidebarRouter';
import Contact from '@models/Contact';
import DOMService from '@services/DOMService';
import * as React from 'react';
import ApiService from "@services/ApiService";

export interface IContactInfoProps {
    contact: Contact
    oneCol?: boolean
}

export default class ContactInfo extends React.Component<IContactInfoProps> {


    renderInfo(title, value, className = "mb-3") {
        return <div className={className}>
            <div className='text-s text-secondary'>
                {title}
            </div>
            <div className="row-flex">
                <div>{value}</div>
            </div>
        </div>
    }

    renderAddress(address) {
        return <div className='row mb-3'>
            <div className="col-md-6">
                <div className=''>
                    <div className='text-s text-secondary'>Adresse</div>
                    <div className="address">{[address?.number, address?.address].filter((p) => p).join(" ")}</div>
                    {address?.addressComp && <div className="address">{address?.addressComp}</div>}
                    <div className="city">{address?.cpAndCity()}</div>
                </div>
            </div>
            {this.props.contact.sector && <div className="col-md-6">
                <div className='text-s text-secondary'>Secteur</div>
                <div>{this.props.contact.quartier}</div>
            </div>}
        </div>
    }

    public render() {
        const {contact, oneCol} = this.props
        return (
            <>
                <div className='mb-3 bg-light p-3'>
                    <div className='fw-bold mb-3'>
                        Informations
                    </div>
                    <div className={"mb-3"}>
                        <div className='text-s text-secondary'>
                            Email
                        </div>
                        <div className="row-flex">
                            <div>{contact.email}</div>
                            {(!sessionBloc.isWorker() && contact.email) &&
                                <i onClick={() => DOMService.openSidebar(SidebarRoute.SendPage, {
                                    type: "email",
                                    contact: this.props.contact,
                                    customer: this.props.contact.customer
                                })} className='fas fa-envelope px-3 text-primary pointer'></i>}
                        </div>
                    </div>
                    <div className="row">
                        <div className={(oneCol ? "col-md-12" : "col-md-6") + " col-12"}>
                            <div className={"mb-3"}>
                                <div className='text-s text-secondary'>
                                    Téléphone
                                </div>
                                <div className="row-flex">
                                    <div>{contact.phone}</div>
                                    {(!sessionBloc.isWorker() && contact.phone) &&
                                        <i onClick={() => contact.callOnFixe()}
                                           className='fas fa-phone px-3 text-primary pointer'></i>}
                                </div>
                            </div>
                        </div>
                        <div className={(oneCol ? "col-md-12" : "col-md-6") + " col-12"}>
                            <div className={"mb-3"}>
                                <div className='text-s text-secondary'>
                                    Portable
                                </div>
                                <div className="row-flex">
                                    <div>{contact.portable}</div>
                                    {(!sessionBloc.isWorker() && contact.portable) &&
                                        <i onClick={() => contact.callOnPortable()}
                                           className='fas fa-phone px-3 text-primary pointer'></i>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderInfo("Référence", contact.reference)}
                    {this.renderAddress(contact.counter?.connectionAddress || contact.address)}
                    <div className={"mb-3"}>
                        <div className='text-s text-secondary'>
                            Code rendez-vous
                        </div>
                        <div className="row-flex">
                            <div>{contact.codeRdv}</div>
                            {(!sessionBloc.isWorker())
                                && <i onClick={async () => {
                                    let result = await ApiService.get("contact/generateCodeRDV/" + contact.id, {});
                                    contact.update({codeRdv: result.data.result});
                                    this.forceUpdate()
                                }} className='fas fa-arrows-rotate px-3 text-primary pointer'/>
                            }
                        </div>
                    </div>
                    {contact.customer && this.renderInfo("Client", contact.customer.name)}
                </div>
                <div className='mb-3 bg-light p-3'>
                    <div className='fw-bold mb-3'>
                        Compteur
                    </div>
                    <div className="row">
                        <div
                            className={(oneCol ? "col-md-12" : "col-md-6") + " col-12"}>{this.renderInfo("Référence", contact.counter?.reference)}</div>
                        <div
                            className={(oneCol ? "col-md-12" : "col-md-6") + " col-12"}>{this.renderInfo("Diamètre", contact.counter?.diameter)}</div>
                    </div>
                    {this.renderInfo("Accessibilité", contact.counter?.accessibility)}
                </div>
                <div className='mb-3 bg-light p-3'>
                    <div className='fw-bold mb-3'>
                        Contrat
                    </div>
                    <div className="row">
                        <div
                            className={(oneCol ? "col-md-12" : "col-md-6") + "col-12"}>{this.renderInfo("Référence", contact.contract?.ref)}</div>
                        <div
                            className={(oneCol ? "col-md-12" : "col-md-6") + "col-12"}>{this.renderInfo("Type", contact.contract?.type)}</div>
                    </div>
                    {this.renderInfo("Statut", contact.contract?.status)}
                </div>
            </>
        );
    }
}
