import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import DOMService from "@services/DOMService";
import React from "react";
import User from "src/models/User";
import ModelGrid from './ModelGrid';

export default class UserGrid extends ModelGrid<User> {

  public formRoute = SidebarRoute.UserForm
  static gridName = "user"

  onRowClick = sessionBloc.isManager() || sessionBloc.isNationalResponsible()? (model: User) => {
    sessionBloc.push("/management/users/" + model.id)
  } : null;
  
  gridConfig = {
    headers: ["Nom", "Email", "Téléphone", "Role", "Chantier actuel"],
    body: [
      (user: User) => <b>{user.getFullName()}</b>,
      (user: User) => <div>{user.email}</div>,
      (user: User) => user.phone,
      (user: User) => <div className="badge" style={user.getStyle()}>{user.role.label}</div>,
      (user: User) => <div className="link my-n2 p-2" onClick={(e) => this.showCurrentYard(e, user)}>{user.currentYard?.name}</div>,
    ]
  }

  showCurrentYard = (e, user) => {
    e.stopPropagation();
    DOMService.openSidebar(SidebarRoute.YardShow, {id: user.currentYard.id})
  }

  multipleActions = [
  ];

  onEdit = (sessionBloc.isAdmin() || sessionBloc.isSecretary() || sessionBloc.isNationalResponsible() || sessionBloc.isOrdonnanceur()) ? this.onEdit : null;
  onDelete = sessionBloc.isAdmin() || sessionBloc.isNationalResponsible()? this.onDelete : null;
  onClickNew = sessionBloc.isAdmin() || sessionBloc.isNationalResponsible()? this.onClickNew : null;
}