import { sessionBloc } from '@bloc/SessionBloc';
import * as React from 'react';
import Page403 from 'src/pages/Page403';
import classNames from 'classnames'; // Assurez-vous d'importer classNames

export interface IWorkerLayoutProps {
}

const NavigationBar: React.FC = () => {
  // Pour simuler une page active, tu pourrais avoir un état ou une logique pour gérer les pages actives
  const activePage = window.location.pathname; 
  const backgroundColor = ''; // Simuler une logique pour le fond, ou tu peux la remplacer par ton besoin

  return (
    <nav className='bg-gray-800 p-4'>
      <div className='flex space-x-4'>
        <button
          className={classNames({
            "text-white": activePage === '/worker',
            "btn btn-light text-secondary text-s rounded-2": true,
            "bg-primary": !backgroundColor && activePage === '/worker',
          })}
          onClick={() => window.location.href = '/worker'}
        >
          Interventions
        </button>

        <button
          className={classNames({
            "text-white": activePage === '/worker/vehicule',
            "btn btn-light text-secondary text-s rounded-2": true,
            "bg-primary": !backgroundColor && activePage === '/worker/vehicule',
          })}
          onClick={() => window.location.href = '/worker/vehicule'}
        >
          Véhicule
        </button>
      </div>
    </nav>
  );
};

export default class WorkerLayout extends React.Component<IWorkerLayoutProps> {
  public render() {
    if (!sessionBloc.isWorker() && !sessionBloc.isAdmin()) return <Page403/>

    return (
      <div className='bg-white'>
        <NavigationBar />
        <div className='p-3'>
          {this.props.children}
        </div>
      </div>
    );
  }
}
