import RecordManagerV2 from '@components/logic/RecordManagerV2';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Contact';
import Exchange from '@models/Exchange';
import * as React from 'react';
import ContactInfo from './ContactInfo';
import InterventionsList from './InterventionsList';

export interface IWorkerContactShowProps extends IInjectedShowModelProps<Contact> {
}

class WorkerContactShow extends React.Component<IWorkerContactShowProps> {


  public render() {
    const { model } = this.props;
    return (
        <div>
          <div className='mt-3 mb-5'>
            <div className='mb-2 fw-bold'>Commentaires</div>
            <RecordManagerV2<Exchange> getRef="contactExchanges" options={{modelClass: Exchange, loadOnReady: true, paginate: true, parentModel: model}}>
              {(manager, props) => <>
                {props.models?.map((exchange) =>
                  <div>{exchange.comment}</div>
                )}
              </>
              }
            </RecordManagerV2>
          </div>
          <ContactInfo contact={model} />
          <InterventionsList contact={model}/>
        </div>
    );
  }
}

export default showModel(WorkerContactShow, {modelClass: Contact}) as any