import { SidebarRoute } from '@components/layout/SidebarRouter';
import Exchange from '@models/Exchange';
import DOMService from '@services/DOMService';
import dayjs from 'dayjs';
import * as React from 'react';

export interface IExchangeItemProps {
  exchange: Exchange
  subtitle?: any
  onDelete?
  onChange?
}

export default class ExchangeItem extends React.Component<IExchangeItemProps> {

  showDetail() {
    DOMService.openSidebar(SidebarRoute.SentDetail, {exchange: this.props.exchange});
  }

  public render() {
    const { exchange, subtitle, onChange, onDelete } = this.props;
    return (
      <div className='mb-3 pe-2'>
        <div className="row-flex mb-2">
          <div style={{backgroundColor: exchange.type?.color}} className='width-5 height-5 row-center text-white rounded-pill'>
            <i className={'text-s fa-solid ' + exchange.getIcon()}></i>
          </div>
          <div className='ms-3' >
            <div className='text-s' style={{color: exchange.type?.color}}>{exchange.type?.label}</div>
            {subtitle}
          </div>
          <div className="ms-auto row-end">
            <div className="text-secondary">{dayjs(exchange.createdAt).format("D MMMM YYYY HH:mm")}</div>
            <div onClick={() => DOMService.openSidebar(SidebarRoute.ExchangeForm, {id: exchange.id, onSubmit: () => {
              DOMService.closeSidebar()
              onChange && onChange()
            }}, "static")} className="height-5 width-5 rounded-2 row-end pointer">
              <i className='fas fa-pencil text-secondary'></i>
            </div>
            {onDelete && <div onClick={() => onDelete()} className="height-5 width-5 rounded-2 row-end pointer">
              <i className='fas fa-trash text-secondary'></i>
            </div>}
          </div>
        </div>
        {exchange.interventionSlot && <div className='row-flex text-primary fw-bold mb-2'>
          <i className='fas fa-calendar-alt me-2'></i>
          <div>Rendez-vous avec {exchange.interventionSlot.user.getFullName()}</div>
        </div>}
        <div>{exchange.comment}</div>
        {exchange.data && <span onClick={() => this.showDetail()} className='pointer text-primary'>Détail</span>}
      </div>
    );
  }
}
