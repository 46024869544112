import AdminModelsList from '@components/common/AdminModelsList';
import CRMUserGrid from '@components/grid/CRMUserGrid';
import MainLayout from '@components/layout/MainLayout';
import * as React from 'react';
import User from 'src/models/User';

export interface ICRMUsersPageProps {
}

export default class CRMUsersPage extends React.Component<ICRMUsersPageProps> {
  public render() {
    return (
      <MainLayout title="Agents">
        <div className="app-content">
          <AdminModelsList
            gridClass={CRMUserGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: User}}
          />
        </div>
      </MainLayout>
    );
  }
}
