import Vehicule from '@models/Vehicule';
import EntityManager from '@services/EntityManager';

class VehiculeService {
    async loadVehicules(searchTerm: string = '', userId: number | null = null): Promise<Vehicule[]> {
        try {
            const params: any = { with: ['users'] };
            if (searchTerm) {
                params.search = searchTerm;
            }
            if (userId) {
                params.user_id = userId;
            }

            const response = await EntityManager.getCollection<Vehicule>(Vehicule, { params });
            if (response.ok && response.models) {
                return response.models;
            }

            console.error('Erreur: Aucune donnée trouvée lors de la récupération des véhicules.');
            return [];
        } catch (error) {
            console.error("Erreur lors du chargement des véhicules:", error);
            return [];
        }
    }

    async loadVehiculeDetails(vehiculeId: number): Promise<Vehicule | null> {
        try {
            const response = await EntityManager.show(Vehicule, vehiculeId, { params: { with: ['users', 'mileageHistories', 'maintenances'] } });
            if (response.ok && response.model) {
                return new Vehicule(response.model);
            }
            console.error(`Erreur: Véhicule avec ID ${vehiculeId} non trouvé.`);
            return null;
        } catch (error) {
            console.error("Erreur lors du chargement des détails du véhicule:", error);
            return null;
        }
    }
}

export default new VehiculeService();