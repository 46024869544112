import SectorStatHeader from '@components/charts/SectorStatHeader';
import LightTabs from '@components/common/LightTabs';
import Sector from '@models/Sector';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
export interface ISectorStatProgressionProps {
  id
}

export default class SectorStatProgression extends React.Component<ISectorStatProgressionProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      quartier: null
    }
  }

  colorProg = {
    "Général": "#1bc76f",
    "Compteurs": "#3dbddb",
    "Modules": "#483ddb",
  }

  filterQuartier(quartier) {
    this.setState({quartier}, () => this.load())
  }

  async load() {
    const {quartier} = this.state;
    let params: any = {};
    if (quartier) params.quartier = quartier;
    this.setState({loading: true})
    let res: any = await EntityManager.getDirect(Sector, {path: this.props.id +  "/progression", params});
    
    this.setState({data: res.data, loading: false});

  }

  async componentDidMount() {
    this.load();
  }

  public render() {
    const {data, quartier, loading} = this.state;
    const {id} = this.props;

    let weekProg  = data?.data["Général"].week_progression.reverse()
    let monthProg  = data?.data["Général"].month_progression.reverse()


    return (
      <div>
        <SectorStatHeader loading={loading} sectorId={id} title="PROGRESSION GLOBALE" filterQuartier={(e) => this.filterQuartier(e)} quartier={quartier} />
        {data && <LightTabs
          headerStyle={{width: "500px", margin: "auto"}}
          tabTitles={["A la semaine", "Au mois"]}
          tabContents={[
            () => {
              let yearRef
              return <>
                <div className='mb-6'>
                  <Line
                    key={1524}
                    style={{height: "450px"}}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true
                        }
                      }
                    }}
                    data={{
                      labels: weekProg.map((e: any) => "Sem" + e.date + " " + e.year),
                      datasets: Object.keys(data.data).map(key => ({
                        data: Object.values(data.data["Général"].week_progression).filter((e: any) => e.date).map((e: any) => {
                          return data.data[key]?.week_progression.find(p => p.date===e.date)?.total || 0
                        }),
                        label: key,
                        fill: true,
                        borderColor: this.colorProg[key],
                        backgroundColor: this.colorProg[key] + "00",
                        // borderColor: this.yearBorderColor,
                        borderWidth: 1,
                        tension: 0.5
                      }))
                    }}
                    />
                </div>
                <div className='mb-6'>
                  <table className='table border'>
                    <thead>
                      <tr>
                        <th></th>
                        {weekProg.map((e: any) => {
                          if (e.year == yearRef) return <th></th>
                          yearRef = e.year
                          return <th>{e.year}</th>
                        })}
                      </tr>
                      <tr>
                        <th></th>
                        {weekProg.map((e: any) => (
                          <th>{"Semaine " + e.date}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(data.data).map(key => (
                          <tr>
                            <td>{key}</td>
                            {weekProg.map((e: any) => (
                              <td>{data.data[key]?.week_progression.find(p => p.date===e.date)?.total || 0}</td>
                            ))}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <h5 className="fw-bold">PROGRESSION PAR AGENT</h5>
                <div className='mb-6'>
                <Line
                    key={1352}
                    style={{height: "450px"}}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true
                        }
                      }
                    }}
                    data={{
                      labels: weekProg.map((e: any) => "Sem" + e.date + " " + e.year),
                      datasets: Object.keys(data.data["Général"].by_agents_week_progression).map((agent, i) => ({
                        data: data.data["Général"].by_agents_week_progression[agent].map((e: any) => e.total),
                        label: agent,
                        fill: true,
                        borderColor: data.data["Général"].by_agents_week_progression[agent].map((e: any) => '#' + Math.floor(Math.random()*16777215).toString(16)),
                        backgroundColor: "transparent",
                        borderWidth: 1,
                        tension: 0.5
                      }))
                    }}
                    />
                </div>
                <div className='mb-6'>
                  <table className='table border'>
                    <thead>
                      <tr>
                        <th></th>
                        {weekProg.map((e: any) => {
                          if (e.year == yearRef) return <th></th>
                          yearRef = e.year
                          return <th>{e.year}</th>
                        })}
                      </tr>
                      <tr>
                        <th></th>
                        {weekProg.map((e: any) => (
                          <th>{"Semaine " + e.date}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(data.data["Général"].by_agents_week_progression).map((agent, i) => (
                          <tr>
                            <td>{agent}</td>
                            {weekProg.map((e: any) => (
                              <td>{data.data["Général"].by_agents_week_progression[agent]?.find(p => p.date===e.date)?.total || 0}</td>
                            ))}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>},
              () => {
                let yearRef
                return <><div className='mb-6'>
                <Line
                  key={1525}
                  style={{height: "450px"}}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }}
                  data={{
                    labels: monthProg.map((e: any) => dayjs().set("month", e.date - 1).set("year", e.year).format("MMM YYYY")),
                    datasets: Object.keys(data.data).map(key => ({
                      data: Object.values(data.data["Général"].month_progression).filter((e: any) => e.date).map((e: any) => {
                        return data.data[key]?.month_progression.find(p => p.date===e.date)?.total || 0
                      }),
                      label: key,
                      fill: true,
                      borderColor: this.colorProg[key],
                      backgroundColor: this.colorProg[key] + "00",
                      // borderColor: this.yearBorderColor,
                      borderWidth: 1,
                      tension: 0.5
                    }))
                  }}
                  />
              </div>
              <div className='mb-6'>
                <table className='table border'>
                  <thead>
                    <tr>
                      <th>#</th>
                      {monthProg.map((e: any) => (
                        <th className='text-capitalize'>{dayjs().set("month", e.date - 1).set("year", e.year).format("MMMM YYYY")}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(data.data).map(key => (
                        <tr>
                          <td>{key}</td>
                          {monthProg.map((e: any) => (
                            <td>{data.data[key]?.month_progression.find(p => p.date===e.date)?.total || 0}</td>
                          ))}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <h5 className="fw-bold">PROGRESSION PAR AGENT</h5>
              <div className='mb-6'>
              <Line
                  key={1685}
                  style={{height: "450px"}}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }}
                  data={{
                    labels: monthProg.map((e: any) => dayjs().set("month", e.date - 1).format("MMMM")),
                    datasets: Object.keys(data.data["Général"].by_agents_month_progression).map((agent, i) => ({
                      data: data.data["Général"].by_agents_month_progression[agent].map((e: any) => e.total),
                      label: agent,
                      fill: true,
                      borderColor: data.data["Général"].by_agents_month_progression[agent].map((e: any) => '#' + Math.floor(Math.random()*16777215).toString(16)) ,
                      backgroundColor: "transparent",
                      borderWidth: 1,
                      tension: 0.5
                    }))
                  }}
                  />
                </div>
                <div className='mb-6'>
                  <table className='table border'>
                    <thead>
                      <tr>
                        <th></th>
                        {monthProg.map((e: any) => {
                          if (e.year == yearRef) return <th></th>
                          yearRef = e.year
                          return <th>{e.year}</th>
                        })}
                      </tr>
                      <tr>
                        <th></th>
                        {monthProg.map((e: any) => (
                          <th>{dayjs().set("month", e.date - 1).set("year", e.year).format("MMMM YYYY")}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(data.data["Général"].by_agents_month_progression).map((agent, i) => (
                          <tr>
                            <td>{agent}</td>
                            {monthProg.map((e: any) => (
                              <td>{data.data["Général"].by_agents_month_progression[agent]?.find(p => p.date===e.date)?.total || 0}</td>
                            ))}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
            </>
              }
          ]}
        />}
          
          {/* <div className='mb-3 fw-bold text-secondary'>Complété à {(data.complete * 100).toFixed(0)} %</div>
          <ProgressBar className="w-100 mb-5" now={data.complete * 100}/> */}
      </div>
    );
  }
}
