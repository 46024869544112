import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Contact from "@models/Contact";
import DOMService from "@services/DOMService";
import ContactGrid from "./ContactGrid";

export default class FailedInterventionGrid extends ContactGrid {

  gridConfig = {
    headers: ["Abonné", "Compteur", "Instruction", "Statut"],
    body: [
      (contact: Contact) => <div className="pointer link" onClick={() => sessionBloc.push("/crm/contacts/" + contact.id)}>
        <div className="fw-bold">{contact.getFullName()}</div>
        <div className="text-secondary">{contact.counter?.connectionAddress?.shortAddress()}</div>
      </div>,
      (contact: Contact) => contact.counter.reference,
      (contact: Contact) => contact.interventions[0].instruction,
      // (contact: Contact) => <div style={{width: "300px"}}>

      //   <SmartSelect noSearch options={OptionService.get("interventions_status").map(is => ({label: is.label, id: is.label}))} value={contact.status ? {label: contact.status, id: contact.status} : null} name="status" onChange={(e) => {
      //     contact.status = e.value;
      //     // EntityManager.update(contact, {only: ["status"]})
      //     this.setState({})
      //   }} containerClassName="mb-3" />,
      // </div>

      (contact: Contact) => <div>
        <button onClick={() => DOMService.openSidebar(SidebarRoute.StatusDialog, {contact, onChange: () => this.props.manager.loadModels()}) } className="btn btn-primary text-nowrap">Statut</button>
      </div>
    ]
  }

  firstBar() {
    return <></>
  }

  onRowClick = null

}