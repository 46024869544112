import * as React from 'react';
import EntityManager from "@services/EntityManager";
import Exchange from "@models/Exchange";
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import MonthPicker from '@components/common/MonthPicker';
import UserSelect from "@components/modelSelect/UserSelect";
import User from "@models/User";
import {getDaysInMonth} from "date-fns";
import InterventionSlot from "@models/InterventionSlot";

export interface ISettingPageProps {
    date: Date
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface ISettingPageState {
    exchange_data: [];
    intervention_data: [];
    date: Date;
    user: null | User;
}

function transformDataForBarChart(exchanges, interventions, date) {
    const formattedExchanges = [];
    const formattedIntervention = []
    let label: number[] = []
    const dayInMonth = getDaysInMonth(date)
    // Parcourir les données d'origine
    for (let day = 1; day <= dayInMonth; day++) {
        let indexExchanges = exchanges.find((data) => {
            return data['dAY(createdAt)'] === day
        })
        let indexIterventions = interventions.find((data) => {
            return data.day === day
        })
        label.push(day)

        formattedIntervention.push(indexIterventions ? indexIterventions.count: 0)
        formattedExchanges.push(indexExchanges ? indexExchanges["cOUNT(id)"] : 0)
    }

    return {
        datasets: [
            {
                label: 'Nombre d\'échanges',
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                data: formattedExchanges,
            },
            {
                label: 'Nombre de rendez-vous pris',
                backgroundColor: 'rgba(175,164,206,0.4)',
                borderColor: 'rgba(175,164,206,1)',
                borderWidth: 1,
                data: formattedIntervention,
            }
        ],
        labels: label,
    };
}

export default class ExchangeStats extends React.PureComponent<ISettingPageProps, ISettingPageState> {
    constructor(props) {
        super(props);
        this.state = {
            exchange_data: [],
            intervention_data: [],
            date: props.date,
            user: null,
        };

        this.handleMonthChange = this.handleMonthChange.bind(this);
    }

    componentDidMount() {
        this.getExchange(this.state.date.getMonth() + 1, this.state.date.getFullYear(), null);
    }

    async getExchange(month, year, user) {
        let path = user ? "stats/" + year + "/" + month + "/" + user.id : "stats/" + year + "/" + month;
        let exchanges = await EntityManager.getCollection<Exchange>(Exchange, {path: path});
        let interventions = await EntityManager.getCollection<InterventionSlot>(InterventionSlot, {path: "intervention_slots_count/" + year + "/" + month, params: {year, month, user_id: user? user.id: ""}})
        this.setState({exchange_data: exchanges.response.data, intervention_data: interventions.data});
    }

    handleMonthChange(newDate: Date) {
        this.getExchange(newDate.getMonth() + 1, newDate.getFullYear(), this.state.user);
        this.setState({date: newDate});
    }

    selectUser(e) {
        this.setState({user: e});
        this.getExchange(this.state.date.getMonth() + 1, this.state.date.getFullYear(), e);
    }

    public render() {
        return (
            <div className="app-content" style={{overflow: 'hidden', height: '100vh'}}>
                <UserSelect
                    value={this.state.user}
                    onChange={e => this.selectUser(e)}
                    name="user"
                    placeholder="Choisir un secrétaire"
                    label="Secretaire"
                    containerClassName="mb-3"
                    params={{scope: 'onlySecretary'}}
                    clearable={true}
                />
                <MonthPicker initialMonth={this.state.date} onMonthChange={this.handleMonthChange}/>
                {this.state.date &&
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div style={{flex: '1 0 50%', padding: '10px'}}>
                            <Bar data={transformDataForBarChart(this.state.exchange_data, this.state.intervention_data, this.state.date)}/>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
