import { sessionBloc } from "@bloc/SessionBloc";
import SmallLoading from "@components/common/SmallLoading";
import CustomMarker from "@components/crm/CustomMarker";
import SmartInputText from "@components/input/SmartInputText";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Contact from "@models/Contact";
import DOMService from "@services/DOMService";
import EntityManager from "@services/EntityManager";
import OptionService from "@services/OptionService";
import GoogleMapReact from "google-map-react";
import { debounce } from "lodash";
import React from "react";
import { apiKey } from "src/config/keys";

export interface IMapPageProps {
  sectorId?
}

export default class MapPage extends React.Component<IMapPageProps, any> {

  mapEl
  legends = [
    {label: "FAIT", color: "#2d98c1", status: ["FAIT"]},
    {label: "EXCLUS", color: "#33d16c", status: ["MANQ", "EXCLUS", "2 APPEL", "INJ", "REFUS", "NE PAS RAPPELER", "IMP VALIDE"]},
    {label: "PLANIFIEE", color: "#d1c533", status: ["RDV", "CHARGEE"]},
    {label: "RESTANTE", color: "#d13333", status: ["A RAPPELER", "1 APPEL", "A CHARGER", "IMP"]},
  ]

  disabled = []

  getStatusDisabled() {
    return this.legends.filter(l => this.disabled.includes(l.label)).map(e => e.status).flat();
  }

  statusToColor = {
    "FAIT": "#2d98c1",
    "MANQ": "#33d16c",
    "EXCLUS": "#33d16c",
    "2 APPEL": "#33d16c",
    "INJ": "#33d16c",
    "REFUS": "#33d16c",
    "IMP": "#33d16c",
    "NE PAS RAPPELER": "#33d16c",
    "IMP VALIDE": "#33d16c",
    "RDV": "#d1c533",
    "CHARGEE": "#d1c533",
    "A RAPPELER": "#d13333",
    "1 APPEL": "#d13333",
    "A CHARGER": "#d13333"
  }

  constructor(props: any) {
    super(props);
    this.state = {
      models: [],
      center: this.getCenter(),
      loading: false,
      address: null
    }
  }

  onDragEnd = debounce(() => {
    if (this.state.address) return;
    this.loadContacts()
  }, 600);

  async loadContacts() {
    this.setState({loading: true})
    let bounds = this.mapEl.getBounds();
    let southWest = bounds.getSouthWest();
    let northEast = bounds.getNorthEast();
    
    let params: any = {
      latmin: southWest.lat(),
      latmax: northEast.lat(),
      lngmin: southWest.lng(),
      lngmax: northEast.lng(),
    }

    if (this.state.address) params.address = this.state.address;
    if (this.props.sectorId) params.sectorId = this.props.sectorId;
    
    let response = await EntityManager.getDirect(Contact, {path: "map", params});
    this.setState({models: response.data.models, loading: false});
  }

  getCenter() {
    return {
      lat: 43.6006786,
      lng: 1.3628012
    }
  }

  centerOn(shop) {
    this.setState({center: {
      lat: shop.latitude,
      lng: shop.longitude
    }, selectedShop: shop})
  }

  onLoad(mapEl) {
    this.mapEl = mapEl.map;
    this.loadContacts();
    
    // eventBloc.getEvents();
    // navigator.geolocation.getCurrentPosition((pos) => {
    //   locationService.setLocation(pos);
    //   this.setState({center: {lat: pos.coords.latitude, lng: pos.coords.longitude}})
    // });
  }

  onAddressChange(address) {
    this.setState({address});
    this.onAddressFilter();
  }

  onAddressFilter = debounce(() => {
    this.loadContacts()
  }, 600);

  resetAddress() {
    this.setState({address: null}, () => this.loadContacts());
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  scrollToAnchor(id){
    var aTag = window.document.getElementById(id)
    window.scrollTo({ top: aTag.offsetTop });
  }

  render() {
    const { center, loading, address } = this.state;

    let models = this.state.models.filter((m) => !this.getStatusDisabled().includes(OptionService.find(m.realStatusId)?.label) );
    
    return (
      <div className="home">
        <div className="position-relative">
          <div className="map" style={{height: "100vh"}}>
            <GoogleMapReact
              center={center}
              onDragEnd={() => this.onDragEnd()}
              onZoomAnimationEnd={() => this.onDragEnd()}
              // onChange={(_) => mapBloc.onChange(_)}
              bootstrapURLKeys={{key: apiKey, language: 'fr'}}
              options={ {
                // styles: mapstyle,
                fullscreenControl: false,
                zoomControl: false,
              }}
              defaultZoom={14}
              onGoogleApiLoaded={(_) => this.onLoad(_)}
            >
              { models.map((model) => (
                <CustomMarker
                  key={model.id}
                  contactId={model.id}
                  onClick={() => DOMService.openSidebar(SidebarRoute.ContactShow, {id: model.id})}
                  // contact={contact}
                  color={this.statusToColor[OptionService.find(model.realStatusId).label]}
                  // selected={selectedShop?.id === shop.id}
                  lat={model.latitude}
                  lng={model.longitude}
                />
                
              ))}
            </GoogleMapReact>
          </div>
          <div style={{top: "1rem", left: "50%"}} className="position-absolute translate-middle-x">
            <div className="bg-white p-4" style={{width: "400px"}}>
              <div className="row-flex">
              {this.props.sectorId && <div onClick={() => sessionBloc.pop() } >
                <i className="fas fa-chevron-circle-left fa-2x me-3"></i>
              </div>}
                <SmartInputText containerClassName="flex-grow-1" placeholder="Rechercher une adresse" onChange={(e) => this.onAddressChange(e)} value={address} />
                <div onClick={() => this.resetAddress()} className="link ms-3">Reset</div>
              </div>
            </div>
          </div>
          <div style={{bottom: "1rem", right: "1rem"}} className="position-absolute">
            <div className="bg-white p-4" style={{width: "250px"}}>
              <div className="mb-4">
                {loading ? <div className="row-flex">
                  <SmallLoading className="primary" />
                  <div className="ms-3">Chargement en cours</div>
                </div> : <div>
                  {models.length + " interventions affichée(s)"}
                </div>}
              </div>
              <div>
                {this.legends.map(status => (
                <div>
                  <div onClick={() => {
                    if (this.disabled.includes(status.label)) this.disabled = this.disabled.filter((d) => d !== status.label)
                    else this.disabled.push(status.label);
                    this.setState({})
                  }} className="pointer" style={this.disabled.includes(status.label) ? {textDecoration: "line-through", color: "gray"} : {}}>
                    <div className="d-inline-block size-3 me-2 rounded-pill" style={{backgroundColor: status.color}}></div>
                    <span className='fw-bold me-2'>{status.label}</span>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
