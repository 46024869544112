import { sessionBloc } from '@bloc/SessionBloc';
import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import SmartSwitch from '@components/input/SmartSwitch';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import * as React from 'react';
import User from 'src/models/User';
import SidebarLayout from '../layout/SidebarLayout';
import EntityManager from "@services/EntityManager";
import Sector from "@models/Sector";
import CustomerSelect from "@components/modelSelect/CustomerSelect";

export interface IUserFormProps extends IInjectedFormModelProps<User> {
}

export interface IUserFormState {
  sectors: Sector[]; // Assurez-vous que cette ligne est présente
  // ... autres propriétés de l'état ...
}

class UserForm extends React.Component<IUserFormProps & IFormModelProps<User>, IUserFormState> {
  constructor(props: IUserFormProps & IFormModelProps<User>) {
    super(props);
    this.state = {
      sectors: [] // Initialiser l'état des secteurs ici
      // ... autres initialisations d'état ...
    };
  }

  componentDidMount() {
    this.loadSectors();
  }

  loadSectors = async () => {
    const response = await EntityManager.get(Sector);
    if (response && response.data && Array.isArray(response.data)) {
      this.setState({ sectors: response.data });
    } else {
      console.error('Format de réponse inattendu:', response);
    }
  };


  submit = async () => {
    if(this.props.model.password && sessionBloc.isAdminOrResponsibleNational()){
      await this.changePassword();
    }
    this.props.model.password = undefined;
    await this.props.submit()
  }

  changePassword = async () => {
    await EntityManager.post(User, {path: "passwordUpdateAdmin", params: {new_password: this.props.model.password, id: this.props.model.id}, multipart: true});
  }

  public render() {
    const {model, submitting, id} = this.props;
    const { sectors } = this.state;
    return (
      <SidebarLayout
        title={id ? "Modification d'un utilisateur" : "Création d'un utilisateur"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={this.submit} >{id ? "Modifier l'utilisateur" : "Créer l'utilisateur"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="firstname" label="Prénom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="lastname" label="Nom" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model} name="phone" label="Téléphone" containerClassName="mb-3" />
          {sessionBloc.isAdminOrResponsibleNational() && <SmartOptionSelect model={model} name="role" label="Role" containerClassName="mb-3" />}
          {sessionBloc.isAdminOrResponsibleNational() &&
              <CustomerSelect label="Secteur" model={model} name="sector" placeholder="Choisir un secteur/client" containerClassName="mb-3" />
          }
          {(sessionBloc.isAdmin() || sessionBloc.isNationalResponsible() || sessionBloc.isOrdonnanceur()) && <SmartInputText model={model} name="email" label="Adresse Email" containerClassName="mb-3" />}
          {sessionBloc.isAdminOrResponsibleNational() && id && <SmartInputText model={model} name="password" label="Mot de passe" containerClassName="mb-3" />}
          {(model.id && sessionBloc.isAdminOrResponsibleNational()) && <SmartSwitch id="accountactive" model={model} name="active" label="Compte activé" containerClassName="mb-3" />}
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IUserFormProps>(UserForm, {modelClass: User})