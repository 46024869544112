import { sessionBloc } from '@bloc/SessionBloc';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import CustomEmail from '@models/CustomEmail';
import * as React from 'react';
import PreviewTemplate from './PreviewTemplate';

export interface ITemplateWithOverviewProps {
  model: CustomEmail
}

export default class TemplateWithOverview extends React.Component<ITemplateWithOverviewProps> {

  focused = "content"
  object = React.createRef<HTMLInputElement>();
  content = React.createRef<HTMLTextAreaElement>();

  texts = [
    {label: "Prénom", text: "{firstname}", disabled: !this.props.model.contact},
    {label: "Nom de famille", text: "{lastname}", disabled: !this.props.model.contact},
    {label: "Nom entier", text: "{fullname}", disabled: !this.props.model.contact},
    {label: "Civilité", text: "{civ}", disabled: !this.props.model.contact?.civ},
    {label: "Référence", text: "{reference}", disabled: !this.props.model.contact?.reference},
    {label: "Email", text: "{email}", disabled: !this.props.model.contact?.email},
    {label: "Téléphone portable", text: "{portable}", disabled: !this.props.model.contact?.portable},
    {label: "Adresse complète", text: "{full_address}", disabled: !this.props.model.contact?.address},
    {label: "Ville", text: "{city}", disabled: !this.props.model.contact?.address?.city},
    {label: "Diamètre", text: "{diameter}", disabled: !this.props.model.contact?.counter?.diameter},
    {label: "Accessibilité", text: "{accessibility}", disabled: !this.props.model.contact?.counter?.accessibility},
    {label: "Mon nom", text: "{my_fullname}"},
    {label: "Mon numéro", text: "{my_phone}", disabled: !sessionBloc.user.phone},
    {label: "Fournisseur", text: "{customer}", disabled: !this.props.model.sector},
    {label: "Rendez-vous", text: "{slot}", disabled: !this.props.model.dateRdv},
  ]

  focusField(field) {
    this.focused = field;
    if (this.props.model[this.focused] === undefined) this.props.model[this.focused] = ""
  }

  appendText(text) {
    if (this.props.model[this.focused] === undefined) return;
    const caretPos = this[this.focused].current.selectionStart;
    this.props.model[this.focused] = this.props.model[this.focused].substring(0, caretPos) + text + this.props.model[this.focused].substring(caretPos);
    this[this.focused].current.focus();
    setTimeout(() => {
      this[this.focused].current.selectionStart = caretPos + text.length
      this[this.focused].current.selectionEnd = caretPos + text.length
    })
  }

  public render() {
    const {model} = this.props;
    return (
      <>
      <div className='bg-primary text-white py-3 px-4 rounded-2 mb-3'>
        <div className='mb-2'>Ajouter un texte dynamiques</div>
        {this.texts.map((t) => (
          <button onClick={() => this.appendText(t.text)} className='btn btn-light me-2 mb-2' disabled={t.disabled}>{t.label}</button>
        ))}
      </div>
        { model.type === "email" && <SmartInputText innerRef={this.object} onFocus={() => {this.focusField("object")}} model={model} name="object" label="Objet" containerClassName="mb-3" />}
        <SmartInputTextarea innerRef={this.content} onFocus={() => {this.focusField("content")}} model={model} name="content" label="Contenu" containerClassName="mb-3" rows={10} />
        <div className='mb-3'>
          <button onClick={() => this.setState({})} className="btn btn-primary btn-sm">Rafraichir l'aperçu</button>
        </div>
        <PreviewTemplate template={model} />
      </>
    );
  }
}
