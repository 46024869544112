import {sessionBloc} from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import TextIcon from '@components/common/TextIcon';
import ClientAreaContactGrid from '@components/grid/ClientAreaContactGrid';
import ContactGrid from '@components/grid/ContactGrid';
import {SidebarRoute} from '@components/layout/SidebarRouter';
import showModel, {IInjectedShowModelProps} from '@components/logic/ShowModel';
import Address from '@models/Address';
import Contact from '@models/Contact';
import Contract from '@models/Contract';
import Counter from '@models/Counter';
import Sector from '@models/Sector';
import ApiService from '@services/ApiService';
import DOMService from '@services/DOMService';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import * as React from 'react';
import {Dropdown} from 'react-bootstrap';

export interface SectorPageProps extends IInjectedShowModelProps<Sector> {
    id
}

export interface SectorPageState {
}

class ClientSectorPage extends React.Component<SectorPageProps, SectorPageState> {

    onClick = () => {

    }

    public render() {
        const {model} = this.props;
        const onClick = this.onClick;
        return (
            <div className="home">
                <div className="px-0 pt-3 px-md-5">
                    <div className="app-content">
                        <div className="row-flex mb-3">
                            <h2 className='mb-0 text-capitalize'>
                                <span className='me-3 fw-bold'>{model.customer.name}</span>
                                <span>{model.name}</span>
                            </h2>
                            <div className="ms-auto">
                            </div>
                        </div>
                        <AdminModelsList
                            gridClass={ClientAreaContactGrid}
                            key="ClientAreaContactGrid"
                            getKey="ClientAreaContactGrid"
                            options={{
                                modelClass: Contact,
                                loadOnReady: true,
                                paginate: true,
                                parentModel: model,
                                onRowClick:{onClick},
                                onclick: {onClick},

                                //defaultState: {filter: JSON.parse(localStorage.getItem("contactGridFilters") || "{}")}
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default showModel(ClientSectorPage, {modelClass: Sector}) as any
