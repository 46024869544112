import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import UserSelect from '@components/modelSelect/UserSelect';
import InterventionSlot from '@models/InterventionSlot';
import User from '@models/User';
import DOMService from '@services/DOMService';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IInterventionSlotsPageProps {
}

export default class InterventionSlotsPage extends React.Component<IInterventionSlotsPageProps, {user: User}> {

  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  calendarRef = React.createRef<ContactSlotCalendar>()

  onSlotClick(slot: InterventionSlot) {
    DOMService.openSidebar(SidebarRoute.WorkerContactShow, {slot: slot, onUpdate: () => {
      DOMService.closeSidebar();
      this.calendarRef.current.loadInterventionSlots()
    }})
  }

  eventDetail(e) {
    let slot: InterventionSlot = e.event._def?.extendedProps?.slot;
    let text = slot?.contact?.counter?.connectionAddress?.fullAddress() || "intervention";
    return <div title={text} className="p-1 text-multi-2">
    {slot?.contact?.quartier && <span className="fw-bold bg-dark py-1 px-2 text-white rounded-2 me-2">{slot?.contact.quartier}</span>}
    <span className="">{text}</span>
  </div>;
  }

  selectUser(user: User) {
    this.setState({user})
  }

  public render() {
    const {user} = this.state;
    return (
      <div className='bg-white p-2 mx-n2'>
        <UserSelect value={user} onChange={(e) => this.selectUser(e)} name="user" placeholder="Choisir un agent" label="Agent" containerClassName="mb-3"  params={{scope: "onlyEmployee"}}/>
        <div className=' '>
          {user && <ContactSlotCalendar
            ref={this.calendarRef}
            onSlotClick={(_) => this.onSlotClick(_)}
            parentModel={user}
            list
            withCount
            with={["contact.counter.connectionAddress"]}
            eventContent={this.eventDetail}
          />}
        </div>
        <div className="mt-5">
          <NavLink className="btn btn-light" to="/logout">Se déconnecter</NavLink>
        </div>
      </div>
    );
  }
}
