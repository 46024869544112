import React, { useEffect, useState } from 'react';

interface SelectInputProps {
    model: any;
    name: string;
    label: string;
    options: { value: string; label: string }[];
    containerClassName?: string;
    onChange?: (value: string) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({ model, name, label, options, containerClassName, onChange }) => {
    const [value, setValue] = useState(model[name]);

    useEffect(() => {
        setValue(model[name]);
    }, [model, name]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value;
        setValue(newValue);
        model[name] = newValue;
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className={containerClassName}>
            <label>{label}</label>
            <select className="form-control" value={value} onChange={handleChange}>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectInput;