import Model from 'src/models/Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Sector from './Sector';

export default class SectorReservationInfo extends Model {

  public name: string
  public text: string
  public image: string
  public active: boolean
  public sector: Sector
  public sectorId: number

  static modelUrl = "sector_reservation_infos";
  public modelName = "sector_reservation_infos";

  public attributes = new AttributesCollection({
    name: new Attribute("name"),
    text: new Attribute("text"),
    image: new Attribute("image"),
    active: new Attribute("active"),
    sector: new ModelAttribute("sector", Sector, {submittable: false}),
    sectorId: new Attribute("sectorId")
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}