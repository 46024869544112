import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import LightTabs from '@components/common/LightTabs';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Contact';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import ContactActions from './ContactActions';
import ContactComments from './ContactComments';
import ContactInfo from './ContactInfo';
import ContactRdvNext from './ContactRdvNext';
import ExchangesList from './ExchangesList';
import InterventionsList from './InterventionsList';

export interface IContactShowProps extends IInjectedShowModelProps<Contact> {
  id
  oneCol?: boolean
}

class ContactShow extends React.Component<IContactShowProps> {

  contactCalendar = React.createRef<ContactSlotCalendar>()

  edit() {
    DOMService.openSidebar(SidebarRoute.ContactForm, {id: this.props.id, onSubmit: () => {
      this.reload()
    }})
  }

  // componentDidUpdate(prevProps, prevState: Readonly<any>, snapshot?: any): void {
  //   if (prevProps.id !== this.props.id) this.props.load();
  // }

  reload() {
    DOMService.closeSidebar();
    this.props.load();
  }

  eventDetail = ({event}) => {
    let slot: InterventionSlot = event._def.extendedProps.slot;
    return <div className="p-1" >
      <div className="fw-bold">{slot?.user?.getFullName()}</div>
    </div>;
  };

  async setStatusToNull() {
    this.props.model.status = null;
    await EntityManager.update( this.props.model, {only: ["status"]});
    this.props.load();
  }

  public render() {
    const { model, oneCol } = this.props;
    
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3 row-flex">
            <h3 className='mb-0'>
              {model.getFullName()}
            </h3>
            <div className="badge ms-3" style={{backgroundColor: model.realStatus.color }}>{model.realStatus.label}</div>
            {model.status && <div className="ms-3 row-flex">
              <i className='fas fa-exclamation-triangle me-2'></i>
              <div>Statut définis manuellement</div>
              
              <div>
                <button onClick={() => this.setStatusToNull()} className='btn btn-link'>Passer en auto</button>
              </div>
              </div>}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <div className="row-end">
              <ContactActions contact={model} onSlotCreated={() => this.contactCalendar?.current?.loadInterventionSlots()} />
              <button onClick={() => this.edit()} className="btn btn-primary ms-3">Modifier</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={(oneCol ? "col-xl-5" : "col-md-6") + " col-12 mb-3"}>
            <ContactInfo oneCol={oneCol} contact={model} />
          </div>
          <div className={(oneCol ? "col-xl-7" : "col-md-6") + " col-12 mb-3 border-start"}>
            <LightTabs
              tabTitles={["Echanges", "Rendez-vous", "Interventions", "Notes" + (model.comments.length ? "(" + model.comments.length + ")" : "")]}
              tabContents={[
                () => <ExchangesList parentModel={model}  />,
                () => <ContactSlotCalendar hideAmountByDay ref={this.contactCalendar} canEdit parentModel={model} onSlotClick={(slot) => DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, noCalendar: true, onSubmit: (_) => this.reload()})} eventContent={this.eventDetail} params={{_with: ["user"]}}/>,
                () => <InterventionsList contact={model}/>,
                () => <ContactComments contact={model}/>
              ]}
            />
            <div className="border-top">
              <ContactRdvNext contact={model} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default showModel(ContactShow, {modelClass: Contact, getRef: "contactShow"}) as any