import React from 'react';
import Vehicule from "@models/Vehicule";
import DOMService from "@services/DOMService";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import ModelsTable from "../common/ModelsTable";
import PaginationComponent from "../common/PaginationComponent";
import PerPageSelect from "../common/PerPageSelect";

interface VehiculeGridProps {
    vehicules: Vehicule[];
    reloadVehicules: () => void;
}

interface VehiculeGridState {
    filter: {
        search: string;
        mileage: string;
        badge: string;
        card: string;
        owner: string;
    };
    page: number;
    perPage: number;
}

export default class VehiculeGrid extends React.Component<VehiculeGridProps, VehiculeGridState> {
    constructor(props: VehiculeGridProps) {
        super(props);
        this.state = {
            filter: {
                search: "",
                mileage: "",
                badge: '',
                card: '',
                owner: ''
            },
            page: 1,
            perPage: 10,
        };
    }

    onPageChange = (page: number) => {
        this.setState({ page });
    };

    onPerPageChange = (perPage: number) => {
        this.setState({ perPage, page: 1 });
    };

    filter = (value: string, key: string) => {
        let filter = { ...this.state.filter };
        filter[key] = value;
        this.setState({ filter, page: 1 });
    };

    render() {
        const { vehicules } = this.props;
        const { filter, page, perPage } = this.state;

        // Filtrage des véhicules
        let filteredVehicules = vehicules;

        if (filter.search) {
            filteredVehicules = filteredVehicules.filter(vehicule =>
                vehicule.licensePlate.toLowerCase().includes(filter.search.toLowerCase())
            );
        }

        if (filter.mileage) {
            filteredVehicules = filteredVehicules.filter(vehicule =>
                vehicule.mileage.toString().includes(filter.mileage)
            );
        }

        if (filter.card) {
            filteredVehicules = filteredVehicules.filter(vehicule =>
                vehicule.card && typeof vehicule.card === 'string' &&
                vehicule.card.toLowerCase().includes(filter.card.toLowerCase())
            );
        }

        if (filter.badge) {
            filteredVehicules = filteredVehicules.filter(vehicule =>
                vehicule.badge && typeof vehicule.badge === 'string' &&
                vehicule.badge.toLowerCase().includes(filter.badge.toLowerCase())
            );
        }

        if (filter.owner) {
            filteredVehicules = filteredVehicules.filter(vehicule =>
                vehicule.owner && typeof vehicule.owner === 'string' &&
                vehicule.owner.toLowerCase().includes(filter.owner.toLowerCase())
            );
        }


        const totalVehicules = filteredVehicules.length;
        const totalPages = Math.ceil(totalVehicules / perPage);
        const startIndex = (page - 1) * perPage;
        const paginatedVehicules = filteredVehicules.slice(startIndex, startIndex + perPage);

        const gridConfig: any = {
            headers: ["Immatriculation", "Kilométrage", "Carte", "Badge", "Utilisateur", "Propriétaire", "Statut", "Actions"],
            body: [
                (vehicule: Vehicule) => <div>{vehicule.licensePlate}</div>,
                (vehicule: Vehicule) => <div>{vehicule.mileage.toLocaleString()} km</div>,
                (vehicule: Vehicule) => <div>{vehicule.card}</div>,
                (vehicule: Vehicule) => <div>{vehicule.badge}</div>,
                (vehicule: Vehicule) => <div>{vehicule.users.map(user => `${user.firstname} ${user.lastname}`).join(", ")}</div>,
                (vehicule: Vehicule) => <div>{vehicule.owner}</div>,
                (vehicule: Vehicule) => <div>{vehicule.status}</div>,
                (vehicule: Vehicule) => (
                    <div>
                        <button className="btn btn-sm btn-primary me-2 fas fa-pencil rounded" onClick={() => this.onEditVehicule(vehicule)}>
                        </button>
                        <button className="btn btn-sm btn-info fas fa-info rounded" onClick={() => this.onViewVehiculeDetails(vehicule)}>
                        </button>
                    </div>
                ),
            ]
        };

        return (
            <div className="vehicule-grid">
                <div className="row">
                    <div className="col-md col-12 mb-3">
                        <input
                            type="text"
                            value={filter.search}
                            onChange={(e) => this.filter(e.target.value, "search")}
                            placeholder={"Par immatriculation"}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md col-12 mb-3">
                        <input
                            type="text"
                            value={filter.mileage}
                            onChange={(e) => this.filter(e.target.value, "mileage")}
                            placeholder={"Par kilométrage"}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="col-md col-12 mb-3">
                        <input
                            type="text"
                            value={filter.card}
                            onChange={(e) => this.filter(e.target.value, "card")}
                            placeholder={"Numéro de carte"}
                            className="form-control"
                        />
                    </div>

                    <div className="col-md col-12 mb-3">
                        <input
                            type="text"
                            value={filter.badge}
                            onChange={(e) => this.filter(e.target.value, "badge")}
                            placeholder={"Numéro de badge"}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-md col-12 mb-3">
                        <input
                            type="text"
                            value={filter.owner}
                            onChange={(e) => this.filter(e.target.value, "owner")}
                            placeholder={"Propriétaire"}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row-flex mb-4">
                    <PaginationComponent lastPage={totalPages} currentPage={page} onPageChange={this.onPageChange} />
                    <div className="ms-4">
                        <PerPageSelect
                            value={perPage}
                            onChange={this.onPerPageChange}
                        />
                    </div>
                </div>

                <ModelsTable config={gridConfig} models={paginatedVehicules} />

                <div className="row-flex mt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <PaginationComponent lastPage={totalPages} currentPage={page} onPageChange={this.onPageChange} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{totalVehicules} véhicules</div>
                </div>


            </div>
        );
    }

    onViewVehiculeDetails = (vehicule: Vehicule) => {
        DOMService.openSidebar(SidebarRoute.VehiculeDetails, {
            vehiculeId: vehicule.id,
            onClose: () => DOMService.closeSidebar(),
        });
    };

    onEditVehicule = (vehicule: Vehicule) => {
        DOMService.openSidebar(SidebarRoute.VehiculeForm, {
            model: vehicule,
            id: vehicule.id,
            onSubmit: () => {
                this.props.reloadVehicules();
                DOMService.closeSidebar();
            },
            onClose: () => DOMService.closeSidebar(),
        });
    };
}