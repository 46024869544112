import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Sector from "@models/Sector";
import DOMService from "@services/DOMService";
import ModelGrid from "./ModelGrid";
import SectorReservationInfo from "@models/SectorReservationInfo";

export default class SectorGridGodinLib extends ModelGrid {

  public formRoute = SidebarRoute.SectorReservationInfoForm

  title() {
    return "Secteurs"
  }

  gridConfig = {
    headers: ["Secteur", "Nom godin lib", "Disponible"],
    body: [
      (sector: Sector) => <b>{sector.name}</b>,
      (sector: Sector) => <div>{sector.sectorReservationInfo ? sector.sectorReservationInfo.name : ""}</div>,
      (sector: Sector) => <div>{sector.sectorReservationInfo && sector.sectorReservationInfo.active ? "Affiché": "Non Affiché"}</div>,
    //   (sector: Sector) => sector.phone,
    //   (sector: Sector) => <div>
    //   <div>{sector.address}</div>
    //   <div>{sector.cpAndCity()}</div>
    // </div>,
      
    ]
  }

  public rowActions = (model: Sector) => <>
  </>

  onClickNew = null

  onRowClick = (model) => {
    DOMService.openSidebar(
    this.formRoute,
    {id: model.sectorReservationInfo ? model.sectorReservationInfo.id: null, sectorId: model.id, onSubmit: (m: SectorReservationInfo) => this.submit(m)},
  )};

  onEdit = (model: Sector) =>  {
    DOMService.openSidebar(
    this.formRoute,
    {id: model.sectorReservationInfo ? model.sectorReservationInfo.id: null, sectorId: model.id, onSubmit: (m: SectorReservationInfo) => this.submit(m)},
  )}
  onDelete = null
}