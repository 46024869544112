import { sessionBloc } from "@bloc/SessionBloc";
import { scopeConfig } from "@components/common/Scoper";
import SearchInput from "@components/input/SearchInput";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import UserSelect from "@components/modelSelect/UserSelect";
import EntityManager from "@services/EntityManager";
import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import Yard from "src/models/Yard";
import DOMService from 'src/services/DOMService';
import ModelGrid from './ModelGrid';

export default class YardGrid extends ModelGrid<Yard> {

  public formRoute = SidebarRoute.YardForm
  static gridName = "yard"

  public scopes: scopeConfig[] = [
    {label: "Relève", value: "onlyReleve", name: "scope"},
    {label: "Renouvellement", value: "onlyRenew", name: "scope"},
  ];

  responsible

  mergeSavedFilter(data) {
    let f = localStorage.getItem("yardGridFilters");
    if (!f) {
      localStorage.setItem("yardGridFilters", JSON.stringify(data));
    } else {

      localStorage.setItem("yardGridFilters", JSON.stringify({data}));
    }
  }

  onSelectResponsible = (option) => {
    this.responsible = option

    this.props.manager.mergeFilter({scopes: [
      JSON.stringify({name: "hasResponsible", param: option.id})
    ]}).then(() => {this.mergeSavedFilter(this.props.manager.state.filter)});
  }

  specificFilters = () => <div className="ms-3" style={{width: "300px"}}>
    <UserSelect onClear={() => {
      this.responsible = null;
      this.props.manager.mergeFilter({scopes: null});
      localStorage.setItem("yardGridFilters", JSON.stringify({}))
    }} value={this.responsible} onChange={this.onSelectResponsible} placeholder="par responsable" params={{scope: "onlyResponsibles"}}/>
  </div>

  title() {
    return "Chantiers"
  }

  gridConfig = {
    headers: ["Client", "Secteur", "Début", "Fin", "Etat"],
    rowClassName: (model: Yard) => model.deletedAt ? "bg-light-warning" : null,
    body: [
      (yard: Yard) => <b className="text-capitalize">{yard.sector?.customer.name}</b>,
      (yard: Yard) => <div className="text-capitalize">{yard.city}</div>,
      // (yard: Yard) => <div className="text-capitalize">{yard.sector?.name}</div>,
      (yard: Yard) => dayjs(yard.startAt).format("D MMMM YYYY"),
      (yard: Yard) => dayjs(yard.endAt).format("D MMMM YYYY"),
      (yard: Yard) => this.renderStatus(yard),
    ]
  }

  onRowClick = (model: Yard) => {
    localStorage.setItem("yardGridFilters", JSON.stringify(this.props.manager.state.filter))
    sessionBloc.push(model.url(), {ids: this.models().map(m => m.id), from: "/management/yards"})
    //sessionBloc.push(model.url(), sessionBloc.routeState())
  };

  public rowActions = (model: Yard) => <>
    {model.deletedAt && <div className="small-btn-icon bg-danger text-white me-2" onClick={() => this.forceDelete(model)}>
      <i className="fas fa-trash"></i>
    </div>}
    {!model.deletedAt && <div className="small-btn-icon me-2" onClick={() => this.onArchive(model)}>
      <i className="fas fa-archive"></i>
    </div>}
  </>

  forceDelete = async (model: Yard) => {
    DOMService.alert({
      title: "Suppression",
      message: "Voulez vous vraiment supprimer définitivement ce chantier ?",
      onConfirm: async () => {
        let response = await EntityManager.post<Yard>(Yard, {path: "force_delete/"+model.id})
        if (response.ok) {
          DOMService.closeModal();
          this.props.manager.loadModels();
        } else {
          DOMService.modal(<div className="p-4 text-center text-danger">
            <h4 className="fw-bold">Erreur</h4>
            <div>{response.errorMessage}</div>
          </div>)
        }
      }
    });
  }

  onArchive = (model) => {
    DOMService.alert({
      title: "Archiver",
      message: "Voulez vous vraiment archiver ce chantier ?",
      onConfirm: async () => {
        let response = await EntityManager.delete(model);
        if (response.ok) {
          DOMService.closeModal();
          this.props.manager.loadModels();
        } else {
          DOMService.modal(<div className="p-4 text-center text-danger">
            <h4 className="fw-bold">Erreur</h4>
            <div>{response.errorMessage}</div>
          </div>)
        }
      }
    });
  }

  multipleActions = [
    {
      action: () => this.props.manager.deleteMany(this.props.selected),
      label: "Supprimer les chantiers"
    }
  ];

  renderStatus = (yard) => {
    return !yard.closed ? <div className="row-flex">
    <ProgressBar className="min-width-8" now={yard.progress * 100}/>
    <div className="text-s ms-2">{yard.getProgressText()}</div>
  </div> : <div className={"badge bg-info"}>Cloturé</div>
  }

  onDelete = null

  firstBar() {
    const { search, manager } = this.props;
    const active = manager.state.filter?.hasOwnProperty("scope") && manager.state.filter["scope"] === "onlyTrashed"
    return <div className="mb-3">
      <div className="row-between">
        <div className="row-flex">
          <div className="me-3">
            <SearchInput value={ search } innerRef={ manager.inputRef } onChanged={ manager.handleSearch } onClear={ manager.clearSearch } onSearch={ manager.onSearch } placeholder={"Rechercher"} />
          </div>
          <div>
            <div key={"onlyTrashed"} className={classNames({"text-white": active, "btn w-100 btn-light text-secondary text-s rounded-2": true, "bg-warning": active})} onClick={() => active ? manager.mergeFilter({scope: "null"}) : manager.mergeFilter({scope: "onlyTrashed"})} >Archivé</div>
          </div>
        </div>
        <div className="row-end">
          {this.specificActions()}
          {this.onClickNew && <button onClick={this.onClickNew} className="btn btn-primary">Nouveau</button>}
        </div>
      </div>
    </div>
  }
  
}