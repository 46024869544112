import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Intervention from '@models/Intervention';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';

export interface IInterventionFormProps extends IInjectedFormModelProps<Intervention> {
}

export interface IInterventionFormState {
}

class InterventionForm extends React.Component<IInterventionFormProps & IFormModelProps<Intervention>, IInterventionFormState> {
  constructor(props: IInterventionFormProps & IFormModelProps<Intervention>) {
    super(props);

    this.state = {
    }
  }

  onSubmit() {
    // if (this.props.model.counter.connectionAddress.isOk()) {
      this.props.submit();
    // } else {
    //   DOMService.setToaster(<div className="col-center">
    //   <div>
    //     <i className="fas fa-exclamation-circle fa-2x text-primary"></i>
    //   </div>
    //   <div>L'adresse doit être remplis</div>
    // </div>)
    // }
  }

  public render() {
    const {model, submitting, id} = this.props;

    return (
      <SidebarLayout
        title={id ? "Modification d'une intervention" : "Création d'une intervention"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.onSubmit()} >{id ? "Modifier l'intervention" : "Créer l'intervention"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="ref" label="Référence" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="instruction" label="Instruction" containerClassName="mb-3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="returnComment" label="Commentaire terrain" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="resultReturn" label="Retour terrain" containerClassName="mb-3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="comment" label="Commentaire" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="status" label="Statut" containerClassName="mb-3" />
            </div>
          </div>
        </div>
      </SidebarLayout>
    );
  }
  
  
}

export default formModel<IInterventionFormProps>(InterventionForm, {modelClass: Intervention})