import User from 'src/models/User';
import Contact from '@models/Contact';
import Model from 'src/models/Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';

export default class Comment extends Model {

  public content: string
  public contact: Contact
  public createdBy: User

  static modelUrl = "comments";
  public modelName = "comment";

  public attributes = new AttributesCollection({
    content: new Attribute("content"),
    contact: new ModelAttribute("contact", Contact),
    createdBy: new ModelAttribute("createdBy", User),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}