import classNames from 'classnames';
import * as React from 'react';
import Model from '../../models/Model';
import SmartCheckbox from '../input/SmartCheckbox';
import { DetailedLoading } from './DetailedLoading';

export interface IModelsTableProps<T> {
  models: T[]
  config: any
  rowActions?: any
  multipleActions?: (selected: T[]) => JSX.Element
  onRowClick?: (arg: T) => void
  onSetting?: (arg: T) => void
  onEdit?: (arg: T) => void
  onDelete?: (arg: T) => void
  onSelectedChange?: (models: T[]) => void
  selected?: T[]
}

export interface RowAction {
  action: (arg) => void,
  icon: string
}

export interface IModelsTableState {
}

class ModelsTable<T extends Model> extends React.PureComponent<IModelsTableProps<T>> {

  toggleSelected(model, checked) {
    if (!checked) this.props.onSelectedChange(this.props.selected.filter(s => s !== model));
    else this.props.onSelectedChange([...this.props.selected, model]);
  }

  static whyDidYouRender = true

  render() {
    const {models, config, multipleActions, onRowClick, onEdit, onDelete, onSelectedChange, selected, rowActions} = this.props
    
    if (!models) return <DetailedLoading/>
    return (<>
      <div className="position-relative d-none d-md-block fadeIn">
        {selected?.length > 0 && <div className="multiple-actions-bar">
          <div>
            {selected?.length} sélectionnés
          </div>
          <div>
            {multipleActions(selected)}
          </div>
        </div>}
        <table className={classNames({"table": true, "table-hover": !!onRowClick})}>
          <thead>
            <tr>
              {multipleActions && <th>
                <SmartCheckbox id="all_check" checked={selected?.length > 0} onChange={(arg) => onSelectedChange(selected?.length > 0 ? [] : models)} />
              </th>}
              {
                config.headers.map(row => (
                  <th key={row}>
                    {row}
                  </th>
                ))
              }
              {(onDelete || onEdit) && <th style={{width: "70px"}}></th>}
            </tr>
          </thead>
          <tbody>
            { models.map((model, index) => (
              <tr className={config.rowClassName ? config.rowClassName(model) : null} key={model.id + "_model"}>
                {multipleActions && <td style={{width: "60px"}}>
                  <SmartCheckbox id={"check" + model.id} checked={selected?.includes(model)} onChange={(arg) => this.toggleSelected(model, arg)} />
                </td>}
                {
                  config.body.map((row, index) => (
                    <td key={index + "body"} onClick={() => onRowClick && onRowClick(model)}>
                      {row(model)}
                    </td>
                  ))
                }
                {(onDelete || onEdit) && (
                  <td>
                    <div className="row-end">
                      {rowActions(model)}
                      {onEdit && <div className="small-btn-icon" onClick={() => onEdit(model)}>
                        <i className="fas fa-pencil"></i>
                      </div>}
                      {onDelete && <div className="small-btn-icon ms-2" onClick={() => onDelete(model)}>
                        <i className="fas fa-trash"></i>
                      </div>}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="position-relative d-block d-md-none fadeIn">
        { models.map(model => (
          <div className="card px-3 py-3 mb-3" key={model.id + "_model"}>
            {
              config.body.map((row, index) => (
                <div key={index + "body"} className="row-between mb-1" onClick={() => onRowClick && onRowClick(model)}>
                  <div className="text-secondary">{config.headers[index]}</div>
                  <div>
                    {row(model)}
                  </div>
                </div>
              ))
            }
            {(onDelete || onEdit) && (
              <div className="row-end">
                {rowActions(model)}
                {onEdit && <div className="small-btn-icon" onClick={() => onEdit(model)}>
                  <i className="fas fa-pencil"></i>
                </div>}
                {onDelete && <div className="small-btn-icon ms-2" onClick={() => onDelete(model)}>
                  <i className="fas fa-trash"></i>
                </div>}
              </div>
            )}
          </div>
        ))}
      </div>
      </>
    );
  }
}

export default ModelsTable

