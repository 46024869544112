import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import UserSelect from '@components/modelSelect/UserSelect';
import InterventionSlot from '@models/InterventionSlot';
import User from '@models/User';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface ICrmInterventionSlotPageProps {
}

export default class CrmInterventionSlotPage extends React.Component<ICrmInterventionSlotPageProps, {user: User}> {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
    }
  }

  calendarRef = React.createRef<ContactSlotCalendar>()

  onSlotClick(slot: InterventionSlot) {
    DOMService.openSidebar(SidebarRoute.WorkerContactShow, {slot: slot, onDelete: () => this.delete(slot), onUpdate: () => {
      DOMService.closeSidebar();
      this.calendarRef.current.loadInterventionSlots()
    }})
  }

  closeAndRefresh() {
    DOMService.closeModal();
    this.calendarRef.current.loadInterventionSlots()
  }

  delete = async (slot) => {
    DOMService.setToaster(<div className="p-3">
      <div>Etes vous sur  de vouloir supprimer ce rendez-vous ?</div>
      <div className="row-center mt-3">
        <div className="btn btn-light mx-2">Annuler</div>
        <div onClick={() => {
          EntityManager.delete(slot).then(() => {
            this.closeAndRefresh()
          });
        }} className="btn btn-danger mx-2">Supprimer</div>
      </div>
    </div>);
    
  }

  eventDetail(e) {
    let slot: InterventionSlot = e.event._def?.extendedProps?.slot;
    let text = slot?.contact?.counter?.connectionAddress?.fullAddress() || "intervention";
    return <div title={text} className="p-1 text-multi-2">
      {slot?.contact?.quartier && <span className="fw-bold bg-dark py-1 px-2 text-white rounded-2 me-2">{slot?.contact.quartier}</span>}
      <span className="">{text}</span>
    </div>;
  }

  selectUser(user: User) {
    this.setState({user})
  }

  public render() {
    const {user} = this.state;
    return (
      <div className="home">
        <div className="px-0 pt-4 px-md-5">
          <div className="app-content">
            <h4 className='fw-bold mb-3'>Calendrier</h4>
            <UserSelect value={user} onChange={(e) => this.selectUser(e)} name="user" placeholder="Choisir un agent" label="Agent" containerClassName="mb-3"  params={{scopes: [
                JSON.stringify({name: "onlyEmployee", param: true}),
                JSON.stringify({name: "withSlots", param: true})
              ]}}/>
            {user && <ContactSlotCalendar
              ref={this.calendarRef}
              onSlotClick={(_) => this.onSlotClick(_)}
              parentModel={user}
              with={["contact.counter.connectionAddress"]}
              canEdit
              withCount
              eventContent={this.eventDetail}
              activatedHandleDataClick={true}
            />}
            </div>
        </div>
      </div>
    );
  }
}
