import AdminModelsList from '@components/common/AdminModelsList';
import SelectableContactGrid from '@components/grid/SelectableContactGrid';
import SidebarLayout from '@components/layout/SidebarLayout';
import SidebarRouter from '@components/layout/SidebarRouter';
import Contact from '@models/Contact';
import * as React from 'react';

export interface IContactsGridSidebarProps {
  onSelect?
}

export default class ContactsGridSidebar extends React.Component<IContactsGridSidebarProps> {

  onRowClick(contact: Contact) {
    this.props.onSelect(contact);
    SidebarRouter.pop();
  }

  public render() {
    return (
      <SidebarLayout
        title={"Choisir un contact"}
      >
        <div>
          <AdminModelsList
            gridClass={SelectableContactGrid}
            onRowClick={(_) => this.onRowClick(_)}
            options={{ loadOnReady: true, paginate: true, modelClass: Contact, params: {_with: ["customer"]}}}
          />
        </div>
      </SidebarLayout>
    );
  }
}
