import { sessionBloc } from '@bloc/SessionBloc';
import ContactShow from '@components/crm/ContactShow';
import * as React from 'react';

export interface IContactShowPageProps {
  id
}

export default class ContactShowPage extends React.Component<IContactShowPageProps, any> {

  currentIndex() {
    return sessionBloc.routeState().ids.findIndex((i) => i.toString() === this.props.id)
  }

  prev() {
    let index = this.currentIndex();
    sessionBloc.push("/crm/contacts/"+sessionBloc.routeState().ids[index-1], sessionBloc.routeState())
  }

  next() {
    let index = this.currentIndex();
    sessionBloc.push("/crm/contacts/"+sessionBloc.routeState().ids[index+1], sessionBloc.routeState())
  }

  renderPaginate() {
    let state = sessionBloc.routeState();
    if (!state) return <></>
    let index = this.currentIndex();
    return <div className='row-flex mb-3'>
    <button className='btn btn-dark btn-sm rounded-2 me-3' onClick={() => sessionBloc.push(state.from)}>Retour</button>
    <button disabled={index === 0} className='btn btn-dark btn-sm rounded-2 me-3' onClick={() => this.prev()}>Précédent</button>
    <button disabled={index === state.ids.length-1 } className='btn btn-dark btn-sm rounded-2' onClick={() => this.next()}>Suivant</button>
  </div>
  }

  public render() {
    const {id} = this.props
    
    return (
      <div className="home">
        <div className="px-0 pt-3 px-md-5">
          <div className="app-content">
          {this.renderPaginate()}
            <ContactShow id={id} />
          </div>
        </div>
      </div>
    );
  }
}
