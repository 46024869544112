import React from 'react';
import MainLayout from '@components/layout/MainLayout';
import Vehicule from '@models/Vehicule';
import VehiculeGrid from '../components/grid/VehiculeGrid';
import DOMService from "@services/DOMService";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import { DetailedLoading } from "@components/common/DetailedLoading";
import VehiculeService from '@services/VehiculeService';
import UserSelect from "@components/modelSelect/UserSelect";
import User from "@models/User";

export interface IParkingPageProps {}

export interface IParkingPageState {
    vehicules: Vehicule[];
    loading: boolean;
    search: string;
    user: User | null;
}

export default class ParkingPage extends React.PureComponent<IParkingPageProps, IParkingPageState> {
    constructor(props: IParkingPageProps) {
        super(props);
        this.state = {
            vehicules: [],
            loading: true,
            search: "",
            user: null,
        };
    }

    componentDidMount() {
        this.loadVehicules();
    }

    loadVehicules = async () => {
        this.setState({ loading: true });
        const { search, user } = this.state;
        const userId = user ? user.id : null;
        const vehicules = await VehiculeService.loadVehicules(search, userId);
        this.setState({ vehicules, loading: false });
    };

    handleUserSelect = (selectedUser: User | null) => {
        this.setState({ user: selectedUser }, () => {
            this.loadVehicules();
        });
    };

    onClickNew = () => {
        DOMService.openSidebar(SidebarRoute.VehiculeForm, {
            onSubmit: () => {
                this.loadVehicules();
            }
        });
    };

    resetUserSelection = () => {
        this.setState({ user: null }, () => {
            this.loadVehicules();
        });
    };

    renderVehiculeGrid() {
        const { vehicules } = this.state;

        if (vehicules.length > 0) {
            return <VehiculeGrid vehicules={vehicules} reloadVehicules={this.loadVehicules} />;
        }

        return <p>Aucun véhicule à afficher</p>;
    }

    public render() {
        const { loading, user } = this.state;

        return (
            <MainLayout title="Gestion des véhicules - Park">
                <div className="app-content">
                    <div className="d-flex justify-content-between mb-4">
                        <button className="btn btn-success btn-sm rounded" onClick={this.onClickNew}>
                           + Ajouter un véhicule
                        </button>
                        <UserSelect
                            value={user}
                            onChange={this.handleUserSelect}
                            name="user_id"
                            placeholder="Choisir un utilisateur"
                            label="Utilisateur"
                            containerClassName="mb-3 ms-5"
                        />
                        {user && (
                            <button className="btn btn-danger btn-sm p-1 h-50 fa-solid fa-trash" onClick={this.resetUserSelection}>
                            </button>
                        )}
                    </div>
                    {loading ? (
                        <DetailedLoading />
                    ) : (
                        this.renderVehiculeGrid()
                    )}
                </div>
            </MainLayout>
        );
    }
}