import uniqueId from 'lodash/uniqueId';
import * as React from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import DOMService from '@services/DOMService';

export interface ICustomModalHeaderProps {
  title?: string;
  onClose?: () => void
  style?: React.CSSProperties
  actions?: JSX.Element[]
}

export default function CustomModalHeader(props: ICustomModalHeaderProps) {
  const { title, style, actions } = props;
  return (
    <div style={style} className="modal-header">
      <ModalTitle className="pl-2">{title}</ModalTitle>
      <div className="row-end">
        {actions?.map(a => a)}
        <div key={uniqueId()} onClick={() => DOMService.closeModal()} className="close-btn">
          <i className={"far fa-times"}></i>
        </div>
      </div>
    </div>
  );
}
