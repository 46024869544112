import React, { Component } from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import OptionService from '@services/OptionService';
import ApiService from '@services/ApiService';

interface SectorDeleteProps {
  onSubmit
  sectorId: string; // Identifiant du secteur actuel
}

class SectorDelete extends Component<SectorDeleteProps, any> {
  state = {
    quartiers: [],
    isLoading: true,
    error: null
  };

  componentDidMount() {
    this.loadQuartiers();
  }

  async loadQuartiers() {
    try {
      const { sectorId, onSubmit } = this.props;
      await OptionService.loadQuartier(sectorId)
      const quartiersData = OptionService.quartiers[sectorId];
    if (quartiersData) {
      this.setState({
        quartiers: quartiersData,
        isLoading: false
      });
    } else {
      // Gérez le cas où quartiersData est undefined
      console.error('Erreur : données de quartier non trouvées');
      this.setState({ error: new Error('Données de quartier non trouvées'), isLoading: false });
    }
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteQuartier(quartier) {
    if(window.confirm("Etes vous sur de vouloir supprimer le quartier ?")){
      try {
        this.setState({
          isLoading: true
        });
        // Assurez-vous que cette URL est correcte pour votre API de suppression de quartier
        await ApiService.delete(`deleteQuartier/${this.props.sectorId}`, {quartier: quartier});
        await this.loadQuartiers(); // Recharger la liste après la suppression
        this.props.onSubmit();
      } catch (error) {
        console.error('Erreur lors de la suppression du quartier', error);
        this.setState({ error });
      }
    }
  }

  render() {
    const { quartiers, isLoading, error } = this.state;
  
    if (isLoading) {
      return <div>Chargement en cours...</div>;
    }
  
    if (error) {
      return <div>Erreur : {error.message}</div>;
    }
  
    return (
      <SidebarLayout title="Supprimer un quartier">
        <div>
          {quartiers.length > 0 ? (
            quartiers.map((quartier, index) => (
              <div className='mt-3' key={index}>
                {quartier}
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => this.deleteQuartier(quartier)}
                >
                  Supprimer
                </button>
              </div>
            ))
          ) : (
            <div>Aucun quartier à afficher</div>
          )}
        </div>
      </SidebarLayout>
    );
  }
}

export default SectorDelete;
