import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Contact from '@models/Contact';
import Intervention from '@models/Intervention';
import * as React from 'react';
import InterventionItem from './InterventionItem';

export interface IInterventionsListProps {
  contact: Contact
}

export default function InterventionsList (props: IInterventionsListProps) {
  return (
    <RecordManagerV2<Intervention> options={{modelClass: Intervention, loadOnReady: true, paginate: true, parentModel: props.contact}}>
      {(manager, props) => <>
      <div>
        {props.models?.map((intervention) => <InterventionItem key={intervention.id} intervention={intervention} />)}
      </div>
      </>
      }
    </RecordManagerV2>
  );
}
