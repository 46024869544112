import AsyncButton from '@components/button/AsyncButton';
import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import UserSelect from '@components/modelSelect/UserSelect';
import Contact from '@models/Contact';
import InterventionSlot from '@models/InterventionSlot';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import * as React from 'react';
import Exchange from 'src/models/Exchange';
import SidebarLayout from '../layout/SidebarLayout';
import {sessionBloc} from "@bloc/SessionBloc";

export interface IExchangeFormProps extends IInjectedFormModelProps<Exchange> {
  status: string
}

export interface IExchangeFormState {
  status
  noAgentError
  noRdvError
  withRdv
}

class ExchangeForm extends React.Component<IExchangeFormProps & IFormModelProps<Exchange>, IExchangeFormState> {
  constructor(props: IExchangeFormProps & IFormModelProps<Exchange>) {
    super(props);

    this.state = {
      status: props.status,
      noAgentError: false,
      noRdvError: false,
      withRdv: false
    }
    if (props.status) props.model.interventionSlot = new InterventionSlot({contact: props.parentModel, createdBy: sessionBloc.user})
  }

  async submit() {
    const {submit, parentModel} = this.props;
    if (this.state.withRdv && !this.props.model.interventionSlot.user) {
      this.setState({noAgentError: true})
      return;
    }
    if (this.state.withRdv && !this.props.model.interventionSlot.startAt) {
      this.setState({noRdvError: true, noAgentError: false})
      return;
    }

    if(this.state.withRdv){
      this.props.model.interventionSlot.createdBy = sessionBloc.user;
    }

    await submit();
    let contact = parentModel as Contact;
    if (this.state.status) {
      contact.status = this.state.status;
      await EntityManager.update(contact, {only: ["status"]});
      GetX.get("contactShow")?.load()
    }
  }

  statusChange(status) {
    this.setState({status});
  }

  toggleRdv() {
    const {parentModel} = this.props;
    if (!this.state.withRdv) {
      this.props.model.interventionSlot = new InterventionSlot({contact: parentModel})
    } else {
      this.props.model.interventionSlot = null;
    }
    this.setState({withRdv: !this.state.withRdv});
  }

  eventDetail(e) {
    let slot: InterventionSlot = e.event._def?.extendedProps?.slot;
    let text = slot?.contact?.counter?.connectionAddress?.fullAddress() || "intervention";
    
    return <div title={text} className="p-1 text-multi-2">
      {slot?.contact?.quartier && <span className="fw-bold bg-dark py-1 px-2 text-white rounded-2 me-2">{slot?.contact.quartier}</span>}
      <span className="">{text}</span>
    </div>;
  }

  onSelect(start, end) {
    const {model} = this.props;
    model.interventionSlot.startAt = start
    model.interventionSlot.endAt = end
    this.setState({})
  }

  status = [
    "EXCLUS", "CHARGEE", "INJ", "REFUS", "IMP", "NE PAS RAPPELER", "A RAPPELER", "EXCLUS", "A CHARGER"
  ]

  public render() {
    const {model, submitting, id, parentModel,} = this.props;
    const {status,noAgentError, withRdv, noRdvError} = this.state;
    let contact = parentModel as Contact;
    return (
      <SidebarLayout
        title={id ? "Modification d'un échange" : "Création d'un échange"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.submit()} >{id ? "Modifier l'échange" : "Créer l'échange"}</AsyncButton>}
      >
        <div className="fadeInDown">
          {contact && <div>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Contact</div>
              <div>{contact.getFullName()}</div>
              <div>{contact.email}</div>
              <div>{contact.phone}</div>
              <div>{contact.portable}</div>
            </div>
          </div>}
          <SmartInputTextarea model={model} name="comment" label="Commentaire" containerClassName="mb-3 " />
          <SmartOptionSelect model={model} name="type" label="Type" containerClassName="mb-3" />
          {/* <SmartDateRangePicker leftIcon={<i className="fas fa-calendar-alt"></i>} model={model} names={["startAt", "endAt"]}  containerClassName="mb-3 "/> */}
          <hr />
          <div>
            <div className="mb-2">Changer de statut après la création de l'échange</div>
            <SmartOptionSelect options={OptionService.get("interventions_status").filter(a => this.status.includes(a.label))} value={ status ? {label: status, value: status} : null } onChange={ (e) => this.statusChange(e.label) }  containerClassName="mb-3" />
          </div>
          <hr />
          <div className='mb-3'>
            <button onClick={() => this.toggleRdv()} className="btn btn-dark">{withRdv ? "Supprimer le" : "Ajouter un"} rendez-vous</button>
          </div>
          {withRdv && <div>
            <UserSelect model={model.interventionSlot} name="user" onChange={() => this.setState({})} placeholder="Choisir un agent" label="Agent" containerClassName="mb-3"  params={{scope: "onlyEmployee"}}/>
            {noAgentError && <div className='text-danger fw-bold mb-3'>Veuillez choisir un agent</div>}
            {noRdvError && <div className='text-danger fw-bold mb-3'>Veuillez choisir un créneau</div>}
            {model.interventionSlot.user && <ContactSlotCalendar
              add={model.interventionSlot.startAt ? model.interventionSlot : null}
              parentModel={model.interventionSlot.user}
              eventContent={this.eventDetail}
              canEdit
              hideAmountByDay
              withCount
              params={{_with: ["contact.counter.connectionAddress"], scope: "inFuture"}}
              eventsInBackground
              onSelect={(_, __) => this.onSelect(_, __)}
            />}
          </div>}
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IExchangeFormProps>(ExchangeForm, {modelClass: Exchange})