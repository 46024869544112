import React from 'react';

interface FileUploadProps {
    model: any;
    fieldName: string;
    label: string;
    onUploadSuccess?: (files: File[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ model, fieldName, label, onUploadSuccess }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = event.target.files ? Array.from(event.target.files) : [];
        model[fieldName] = uploadedFiles;

        if (onUploadSuccess) {
            onUploadSuccess(uploadedFiles);
        }
    };

    return (
        <div className="form-group">
            <label>{label}</label>
            <input type="file" multiple className="form-control" onChange={handleFileChange} />
        </div>
    );
};

export default FileUpload;