import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartSelect from '@components/input/SmartSelect';
import Contact from '@models/Contact';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import OptionService from '@services/OptionService';
import * as React from 'react';
import CustomModalHeader from './CustomModalHeader';

export interface IChangeQuartierModalProps {
  selected
  onChange
  sectorId
}

export default class ChangeQuartierModal extends React.Component<IChangeQuartierModalProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      quartier: null,
      loading: false
    }
  }

  async validate() {
    const {selected, onChange} = this.props
    const {quartier} = this.state
    this.setState({loading: true})
    selected.forEach(element => {
      element.quartier = quartier
    });
    await EntityManager.updateMany(Contact, {params: selected, only: ["quartier"]});
    this.setState({loading: false})
    DOMService.closeModal()
    onChange()
  }

  public render() {
    const {quartier, loading} = this.state
    const {sectorId} = this.props
    
    let quartiersOption = OptionService.quartiers[sectorId].map((q) => ({label: q, id: q}))
    return (
      <div>
        <CustomModalHeader title='Assigner au quartier' />
        <div className="p-4">
          <div className='mb-3'>
            <SmartSelect options={quartiersOption} onChange={ (e) => this.setState({quartier: e.id})}  placeholder={"Quartier existant"} />
          </div>
          <div className="mb-3">
            <SmartInputText onChange={ (quartier) => this.setState({quartier})} placeholder="Quartier à assigner" value={quartier} />
          </div>
          <div>
            <AsyncButton loading={loading} disabled={!quartier} onClick={() => this.validate()} className="btn btn-primary w-100">Valider</AsyncButton>
          </div>
        </div>
      </div>
    );
  }
}
