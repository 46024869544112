import InterventionSlot from '@models/InterventionSlot';
import Sector from '@models/Sector';
import EntityManager from '@services/EntityManager';
import Call from '@models/Call';
import Intervention from '@models/Intervention';
import DOMService from "@services/DOMService";
import KeyyoService from "@services/KeyyoService";
import Address from "./Address";
import Comment from "./Comment";
import Contract from "./Contract";
import ArrayModelAttribute from "./core/ArrayModelAttribute";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from "./core/ModelAttribute";
import Counter from "./Counter";
import Customer from './Customer';
import Exchange from "./Exchange";
import Model from './Model';
import Option from './Option';
import { OptionAttribute } from './core/OptionAttribute';

export default class Contact extends Model {
  public phone: string
  public portable: string
  public payerName: string
  public reference: string
  public realStatus: Option
  public status: string
  public civ: string
  public firstname: string
  public lastname: string
  public email: string
  public customerId: number
  public addressId: number
  public exchanges: Exchange[]
  public comments: Comment[]
  public interventions: Intervention[]
  public rdvInAddress: InterventionSlot[]
  public rdvInQuartier: InterventionSlot[]
  public contract: Contract
  public counter: Counter
  public address: Address
  public customer: Customer
  public sector: Sector
  public quartier
  sectorId
  public import: any
  public codeRdv: string

  static modelUrl = "contacts";
  public modelName = "contact";

  public attributes = new AttributesCollection({
    phone: new Attribute("phone"),
    portable: new Attribute("portable"),
    payerName: new Attribute("payerName"),
    reference: new Attribute("reference"),
    civ: new Attribute("civ"),
    quartier: new Attribute("quartier"),
    firstname: new Attribute("firstname"),
    realStatus: new OptionAttribute("realStatus", {optionType: "interventions_status"}),
    status: new Attribute("status"),
    import: new Attribute("import"),
    lastname: new Attribute("lastname"),
    codeRdv: new Attribute("codeRdv"),
    email: new Attribute("email"),
    address: new ModelAttribute("address", Address, {submittable: false}),
    customer: new ModelAttribute("customer", Customer),
    sector: new ModelAttribute("sector", Sector),
    contract: new ModelAttribute("contract", Contract, {submitObject: true}),
    counter: new ModelAttribute("counter", Counter, {submitObject: true}),
    exchanges: new ArrayModelAttribute("exchanges", Exchange),
    comments: new ArrayModelAttribute("comments", Comment),
    interventions: new ArrayModelAttribute("interventions", Intervention, {submittable: false}),
    rdvInAddress: new ArrayModelAttribute("rdvInAddress", InterventionSlot, {submittable: false}),
    rdvInQuartier: new ArrayModelAttribute("rdvInQuartier", InterventionSlot, {submittable: false}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
    if (!this.counter) this.counter = new Counter({})
  }

  public getFullName(): string {
    let str = [this.civ, this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  public getLink() {
    return `/crm/contacts/${this.id}`;
  }

  public callOnFixe() {
    if (!this.phone) DOMService.setToaster("Aucun numéro enregistré pour ce contact")
    else KeyyoService.startCall(this.phone)
  }

  public callOnPortable() {
    if (!this.portable) DOMService.setToaster("Aucun numéro enregistré pour ce contact")
    else KeyyoService.startCall(this.portable)
  }


  public startCall() {
    let number = this.portable || this.phone
    if (!number) DOMService.setToaster("Aucun numéro enregistré pour ce contact");
    else KeyyoService.startCall(number);
    EntityManager.postDirect(Call, {path: "clear", params: {number}});
  }
  
  public getFrontUrl(): string {
    let classs: any = this.constructor;
    return `/crm/${classs.modelUrl}/${this.id}`;
  }
}