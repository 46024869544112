import Model from "./Model";
import AttributesCollection from "./core/AttributeCollection";
import Attribute from './core/Attribute';

export default class VehiculeFile extends Model {
    public vehiculeId: number;
    public filePath: string;
    public fileType: string;

    static modelUrl = "vehicule_files";
    public modelName = "vehiculeFile";

    public attributes = new AttributesCollection({
        vehiculeId: new Attribute("vehiculeId"),
        filePath: new Attribute("filePath"),
        fileType: new Attribute("fileType"),
    });

    constructor(json) {
        super(json);
        this.fillAttributes(json);
    }
}