import { sessionBloc } from '@bloc/SessionBloc';
import TextIcon from '@components/common/TextIcon';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import Alert from '@models/Alert';
import Contact from '@models/Contact';
import Intervention from '@models/Intervention';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export interface IContactActionsProps {
  contact: Contact
  onSlotCreated?
}

export default class ContactActions extends React.Component<IContactActionsProps> {

  options = [
    {
      label: "Envoyer un email",
      icon: "fa-envelope",
      disabled: false,
      action: () => DOMService.openSidebar(SidebarRoute.SendPage, {type: "email", contact: this.props.contact, customer: this.props.contact.customer})
    },
    {
      label: "Envoyer un sms",
      icon: "fa-send",
      disabled: !this.props.contact.portable,
      action: () => DOMService.openSidebar(SidebarRoute.SendPage, {type: "sms", contact: this.props.contact, customer: this.props.contact.customer})
    },
    {
      label: "Appeler",
      icon: "fa-phone",
      disabled: !this.props.contact.portable && !this.props.contact.phone,
      action: () => this.props.contact.startCall()
    },
    {
      label: "Créer une alerte",
      icon: "fa-alarm-exclamation",
      disabled: false,
      action: () => {
        let alert = new Alert({contact: this.props.contact, to: sessionBloc.user})
        DOMService.openSidebar(SidebarRoute.AlertForm, {newModel: alert, onSubmit: (newAlert) => {
          DOMService.closeSidebar();
        }})
      }
    },
    {
      label: "Changer le statut",
      icon: "fa-edit",
      action: () => DOMService.openSidebar(SidebarRoute.StatusDialog, {contact: this.props.contact, onChange: () => GetX.get("contactShow")?.load()})
    },
    {
      label: "Créer une intervention",
      icon: "fa-wrench",
      action: () => DOMService.openSidebar(SidebarRoute.InterventionForm, {newModel: new Intervention({contact: this.props.contact}), onSubmit: () => {
        DOMService.closeSidebar()
        this.props.onSlotCreated()
      }})
    },
    // {
    //   label: "Exporter",
    //   icon: "fa-file-export",
    //   disabled: false,
    //   action: () => EntityManager.post(Contact, {path: this.props.contact + "/export"})
    // },
    {
      label: "Créer un rendez-vous",
      icon: "fa-calendar-alt",
      disabled: false,
      action: () => DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {newModel: new InterventionSlot({contact: this.props.contact}), onSubmit: () => {
        DOMService.closeSidebar()
        this.props.onSlotCreated()
      }}, {style: {maxWidth: "1000px"}})
    },
    // {
    //   label: "Supprimer les interventions (MANQ)",
    //   icon: "fa-xmark",
    //   disabled: false,
    //   action: () => this.deleteInterventions()
    // },
    {
      label: "Supprimer",
      icon: "fa-xmark",
      disabled: false,
      action: () => this.delete()
    },
  ]

  async delete() {
    await EntityManager.delete(this.props.contact);
    sessionBloc.push(sessionBloc.routeState().from);
  }

  // deleteInterventions() {

  // }

  public render() {
    return (
      <Dropdown>
        <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLButtonElement>) => (
          <button ref={ref} onClick={onClick} className="btn btn-info">
            Actions
          </button>
        ))}/>
        <Dropdown.Menu align="end" className="fade" style={{marginTop: "1rem"}} >
          {this.options.map(opt => (
            <Dropdown.Item key={opt.label} onClick={opt.action} disabled={opt.disabled} >
              <TextIcon leftIcon={"fas " + opt.icon}>
                {opt.label}
              </TextIcon>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
