import React, { useState } from 'react';
import VehiculeMaintenance from '@models/VehiculeMaintenance';
import dayjs from 'dayjs';
import apiService from '@services/ApiService';

interface MaintenanceTabProps {
    vehicule: {
        id: number;
        maintenances: VehiculeMaintenance[];
    };
    onClose: () => void;
}

const MaintenanceTab: React.FC<MaintenanceTabProps> = ({ vehicule, onClose }) => {
    const [maintenances, setMaintenances] = useState(vehicule.maintenances);
    const [newMaintenance, setNewMaintenance] = useState({
        nextMaintenanceAt: '',
        lastMaintenanceAt: '',
        mileage: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleNewMaintenanceChange = (
        field: 'nextMaintenanceAt' | 'lastMaintenanceAt' | 'mileage',
        value: string
    ) => {
        setNewMaintenance({
            ...newMaintenance,
            [field]: value
        });
    };

    const validateForm = () => {
        if (!newMaintenance.nextMaintenanceAt || !newMaintenance.lastMaintenanceAt || !newMaintenance.mileage) {
            setError('Tous les champs sont obligatoires.');
            return false;
        }
        setError('');
        return true;
    };

    const handleAddNewMaintenance = async () => {
        if (!validateForm()) return;

        setLoading(true);
        try {
            const maintenanceToAdd = {
                nextMaintenanceAt: newMaintenance.nextMaintenanceAt,
                lastMaintenanceAt: newMaintenance.lastMaintenanceAt,
                mileage: newMaintenance.mileage,
                vehicule_id: vehicule.id
            };

            const response = await apiService.post(`vehicule_maintenances`, maintenanceToAdd);

            const addedMaintenance = new VehiculeMaintenance(response.data);

            setMaintenances([...maintenances, addedMaintenance]);

            setNewMaintenance({ nextMaintenanceAt: '', lastMaintenanceAt: '', mileage: '' });

            onClose();
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la maintenance :', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="table-responsive maintenance-tab">
            <h5>Ajouter une nouvelle maintenance</h5>
            {error && <div className="alert alert-danger">{error}</div>}

            <table className="table">
                <thead>
                <tr>
                    <th>Date de la prochaine maintenance</th>
                    <th>Date de la dernière maintenance</th>
                    <th>Kilométrage</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <input
                            type="date"
                            className="form-control"
                            value={newMaintenance.nextMaintenanceAt}
                            onChange={(e) =>
                                handleNewMaintenanceChange('nextMaintenanceAt', e.target.value)
                            }
                            placeholder="Date prochaine maintenance"
                        />
                    </td>
                    <td>
                        <input
                            type="date"
                            className="form-control"
                            value={newMaintenance.lastMaintenanceAt}
                            onChange={(e) =>
                                handleNewMaintenanceChange('lastMaintenanceAt', e.target.value)
                            }
                            placeholder="Date dernière maintenance"
                        />
                    </td>
                    <td>
                        <input
                            type="number"
                            className="form-control"
                            value={newMaintenance.mileage}
                            onChange={(e) =>
                                handleNewMaintenanceChange('mileage', e.target.value)
                            }
                            placeholder="Kilométrage"
                        />
                    </td>
                    <td>
                        <button
                            className="btn btn-primary"
                            onClick={handleAddNewMaintenance}
                            disabled={loading}
                        >
                            {loading ? 'Ajout en cours...' : 'Ajouter'}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <h5>Historique des maintenances</h5>
            {maintenances.length === 0 ? (
                <p>Aucune maintenance programmée.</p>
            ) : (
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Date de la prochaine maintenance</th>
                        <th>Date de la dernière maintenance</th>
                        <th>Kilométrage</th>
                    </tr>
                    </thead>
                    <tbody>
                    {maintenances.map((maintenance, index) => (
                        <tr key={index} className="fade-in">
                            <td>
                                {maintenance.nextMaintenanceAt
                                    ? dayjs(maintenance.nextMaintenanceAt).format('DD/MM/YYYY')
                                    : 'Date non disponible'}
                            </td>
                            <td>
                                {maintenance.lastMaintenanceAt
                                    ? dayjs(maintenance.lastMaintenanceAt).format('DD/MM/YYYY')
                                    : 'Date non disponible'}
                            </td>
                            <td>
                                {maintenance.mileage
                                    ? maintenance.mileage.toLocaleString() + ' km'
                                    : 'Kilométrage non disponible'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default MaintenanceTab;