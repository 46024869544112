import Contact from '@models/Contact';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import OptionService from '@services/OptionService';
import * as React from 'react';
import CustomModalHeader from './CustomModalHeader';

export interface IChangeStatusModalProps {
  selected: any[]
  onChange
}

export default class ChangeStatusModal extends React.Component<IChangeStatusModalProps, any> {
  
    constructor(props) {
      super(props);
      this.state = {
        status: null
      }
    }
  
    status = [
      "MODULE", "EXCLUS", "CHARGEE", "INJ", "REFUS", "IMP", "NE PAS RAPPELER"
    ]
  
    submit = async () => {
      const {selected, onChange} = this.props
      const {status} = this.state
      selected.forEach((s, i) => {
        selected[i].status = status.label;
      });
      await EntityManager.updateMany(Contact, {params: selected, only: ["status"]});
      DOMService.closeModal();
      onChange()
    }
  
    public render() {
      const {status} = this.state
      return (
          <div className='p-4'>
            <CustomModalHeader title='Assigner au quartier' />
            {OptionService.get("interventions_status").map((s) => (
              <div onClick={() => this.setState({status: s})} style={status===s ? {backgroundColor: s.color, color: "white"} : {backgroundColor: "#f1f2f3", color: s.color}} className='mb-3 py-2 px-4 rounded-2 pointer fw-bold'>
                {s.label}
              </div>
            ))}
            <div>
              <button  disabled={!status} onClick={() => this.submit()} className="btn btn-primary">Valider</button>
            </div>
          </div>
      );
    }
  }
  
