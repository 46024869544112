import SidebarLayout from '@components/layout/SidebarLayout';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Contact';
import * as React from 'react';
import ContactInfo from './ContactInfo';

export interface IContactShowSidebarProps extends IInjectedShowModelProps<Contact> {
}

class ContactShowSidebar extends React.Component<IContactShowSidebarProps> {
  public render() {
    const { model } = this.props;
    return (
      <SidebarLayout
        title="Detail de l'abonné"
      >
        <div className="fadeInDown">
            <ContactInfo oneCol={true} contact={model} />
          {/* <div className="row-end mb-3">
            <ContactActions contact={contact} />
          </div>
          <ContactInfo contact={contact} /> */}
        </div>
      </SidebarLayout>
    );
  }
}

export default showModel(ContactShowSidebar, {modelClass: Contact}) as any